import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CropTypeWrapper } from "./curatorApprovalStyle";
import { Dropdown } from "pepsico-ds";
import { getPepsicoDropdownOptions, getDisplayTextForDropDown } from "../../../utils/function";
import commonStyles from "../../PotatoProtection/LargeScreen/Landing/addVariteyDrawerStyle";
import { darkTheme, lightTheme } from "../../../theme/Theme";
import { useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useThemeRender from "../../../theme/useThemeRender";
import "../CuratorCommon.css";

const CropType = ({
  cropTypeOptions,
  cropTypeSectionData,
  setCropTypeData,
}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isDarkTheme = useSelector((state) => state.theme);
  const [cropType, setCropType] = useState(null);
  console.log('cropTypeSectionData---->', cropTypeSectionData);
  useEffect(() => {
    setCropType(cropTypeSectionData?.CropType);
  }, [cropTypeSectionData?.CropType]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: isMobile ? "16px" : "32px",
        padding: "16px",
        background: useThemeRender("cardBackgroundColorLayer2"),
      }}
    >
      <CropTypeWrapper
        sx={{
          "& .label-element": {
            color: useThemeRender("textColorLayer2"),
          },
        }}
      >
        <Dropdown
          childList={getPepsicoDropdownOptions(cropTypeOptions)}
          className="curator-single-dropdown"
          selectedValue={getDisplayTextForDropDown(cropTypeSectionData?.cropType, getPepsicoDropdownOptions(cropTypeOptions))}
          label="Crop Type"
          placeholder="Select"
          selection="single"
          setSelectedValue={(e) => {
            if (e !== undefined && e.length > 0) {
              setCropTypeData((stateData) => {
                return {
                  ...stateData,
                  cropType: e[0]?.id,
                };
              });
            }
          }}
          size="medium"
          style={{
            width: "360px !important"
          }}
        />
      </CropTypeWrapper>

      <Box>
        <Typography
          sx={{
            ...commonStyles.typographyLabel,
            color: useThemeRender("textColorLayer2"),
            mb: 1,
          }}
        >
          Keywords
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={6}
          sx={{
            width: { xs: "100%", md: "360px", sm: "100%" },
            background: "var(--Tokens-Background-Layer-01, #FBFCFE)",
          }}
          placeholder="type here"
          variant="outlined"
          value={cropTypeSectionData?.keyWordsText}
          onChange={(e) => {
            setCropTypeData((stateData) => {
              return {
                ...stateData,
                keyWordsText: e.target.value,
              };
            });
          }}
          FormHelperTextProps={{
            sx: {
              position: "absolute",
              bottom: "8px",
              right: "8px",
              color: useThemeRender("textColorLayer1"),
              fontSize: "12px",
            },
          }}
        />

        <Box display="flex" alignItems="center" mt={1}>
          <Tooltip title="Note: Separate each keyword by a comma.">
            <IconButton>
              <InfoOutlinedIcon
                sx={{
                  color: isDarkTheme
                    ? darkTheme.iconColor2
                    : lightTheme.iconColor2,
                }}
              />
            </IconButton>
          </Tooltip>
          <Typography
            variant="caption"
            sx={{
              color: useThemeRender("textColorLayer4"),
            }}
          >
            Note: Separate each keyword by a comma.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CropType;
