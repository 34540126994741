// src/components/SubjectSection/SubjectSection.js
import React from "react";
import { Box, Typography } from "@mui/material";
import {
  boxStyles,
  chipSection,
  titleStyles,
  subjectBoxStyles,
  subjectChipStyles,
  subjectTypographyStyles,
} from "./subjectStyle"; // Import styles
import { useSelector } from "react-redux";
import { darkTheme } from "../../../theme/Theme";

export default function SubjectSection({ sectionData }) {
  const isDarkTheme = useSelector((state) => state.theme);
  const renderChipSection = () => {
    let separatedArray = [];
    if (sectionData?.label === "Keywords") {
      separatedArray = sectionData?.content
        ?.map((item) => item.split(/,?\s+/))
        .flat();
    } else {
      separatedArray = [...sectionData?.content].flat();
    }
    if (
      separatedArray !== null &&
      separatedArray !== undefined &&
      separatedArray?.length > 0
    ) {
      return separatedArray?.map((i) => (
        <Box sx={subjectBoxStyles}>
          <Box sx={subjectChipStyles}>
            <Typography sx={subjectTypographyStyles}>{i}</Typography>
          </Box>
        </Box>
      ));
    } else {
      return null;
    }
  };
  return (
    <>
      <Box
        sx={{
          ...boxStyles,
          background: isDarkTheme
            ? darkTheme.insideCardBackgroundColorLayer3
            : "var(--Tokens-Background-Layer-02, #EFF5FC)",
        }}
      >
        <Typography
          sx={{
            ...titleStyles,
            color: isDarkTheme
              ? "white"
              : "var(--Tokens-Text-Secondary, #616161)",
          }}
        >
          {sectionData?.label}
        </Typography>
      </Box>
      <Box
        sx={{
          ...chipSection,
          background: isDarkTheme
            ? darkTheme.insideCardBackgroundColorLayer3
            : "var(--Tokens-Background-Layer-02, #EFF5FC)",
        }}
      >
        {renderChipSection()}
      </Box>
    </>
  );
}
