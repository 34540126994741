import React from "react";
import SustainabilitySubpage from "./SustainabilitySubpage";
import { setPesticides } from "../slices/sustainabilityPageSlice";
import { useSelector } from "react-redux";
function PepticidesAndOtherAgrochemicals() {
  const { pesticides } = useSelector((store) => store.sustainable);
  return (
    <SustainabilitySubpage
      urlName={
        "PepsiCo%20statement%20on%20pesticides%20%26%20other%20agrochemicals"
      }
      header={"PepsiCo statement on pesticides & other agrochemicals"}
      action={setPesticides}
      globalState={pesticides}
    />
  );
}

export default PepticidesAndOtherAgrochemicals;
