import React, { useState } from "react";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import { commonTextStyle } from "../InternalStyle";
import {
  linkContainer,
  linkTypography,
  buttonStyle,
} from "../Carousal-ExpandedView/carousalStyle";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import image from "../../../assets/potato.png";
import useThemeRender from "../../../theme/useThemeRender";
import { formatDate, increaseViewCount } from "../../../utils/function";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { patchAPI, postAPI } from "../../../API/ApiService";
import { BASE_URL } from "../../../constant/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { setFavourite } from "../../../slices/internalPageSlice";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  setSummaryDetails,
  setViewPageName,
  setViewSummaryDrawer,
} from "../../../slices/viewSummarySlice";
import { showErrorToast } from "../../../utils/Toast/toast";
import Loading from "../../../utils/Loader";
export default function ListViewDesign({
  data,
  content,
  pageType,
  subPageId,
  viewMode,
}) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [isExpanded, setIsExpanded] = useState(false);
  const insideCardBackgroundColorLayer1 = useThemeRender(
    "insideCardBackgroundColorLayer1"
  );
  console.log("viewMode", viewMode);
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const textColorLayer4 = useThemeRender("textColorLayer4");
  const boxShadowlayerColor1 = useThemeRender("boxShadowlayerColor1");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { favourite, selectedGlobalState, selectedAction } = useSelector(
    (store) => store.internalPage
  );
  // Function to toggle expand/collapse state
  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };
  const navigate = useNavigate();
  const addViewCount = async (contentId, goto, card) => {
    setLoading(true);
    try {
      const response = await patchAPI(`${BASE_URL}/content/${contentId}`, {});
      if (response?.status === 200) {
        navigate(goto, { state: card });
      }
    } catch (err) {
      showErrorToast(err.message);
    } finally {
      setLoading(false);
      const updated = increaseViewCount(
        selectedGlobalState,
        contentId,
        content
      );

      dispatch(selectedAction(updated));
    }
  };
  const handleNavigate = (card, pageName) => {
    const contentId = card?.contentId ? card?.contentId : card?.id;
    if (pageName == "Link") {
      addViewCount(contentId, "/link", card);
    } else if (pageName == "Document") {
      addViewCount(contentId, "/document", card);
    } else if (pageName == "Video") {
      console.log(pageName);
    }
  };

  const handleOpenSummary = (details) => {
    dispatch(setViewSummaryDrawer(true));
    dispatch(setViewPageName(content));
    dispatch(setSummaryDetails(details));
  };
  const handleAddFavourite = (card) => {
    const fetchData = async () => {
      try {
        const response = await postAPI(
          `${BASE_URL}/favorites/save?contentId=${
            card.id
          }&userId=1&favorite=${!card.favorite}`
        );
        dispatch(setFavourite(favourite + 1));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  };
  // Limit the visible data based on the expand/collapse state

  const visibleData = isExpanded ? data : data.slice(0, 3);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
        padding: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            ...commonTextStyle,
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "28px",
            color: useThemeRender("textColorLayer2"),
          }}
        >
          {content}
        </Typography>
        {pageType !== "LibraryDetails" && (
          <Button
            onClick={handleExpandClick}
            sx={{
              display: "flex",
              flexDirection: "row",
              textTransform: "none",
              gap: "2px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "22px",
              }}
            >
              {isExpanded ? "Collapse" : "Expand all"}
            </Typography>
            {isExpanded ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 12 13"
                fill="none"
              >
                <path
                  d="M3.705 8.07121L6 5.78121L8.295 8.07121L9 7.36621L6 4.36621L3 7.36621L3.705 8.07121Z"
                  fill="#005CBC"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M4.94 5.72656L8 8.7799L11.06 5.72656L12 6.66656L8 10.6666L4 6.66656L4.94 5.72656Z"
                  fill="#005CBC"
                />
              </svg>
            )}
          </Button>
        )}
      </Box>

      {(viewMode !== "list" ? visibleData : data)?.map((item) => (
        <React.Fragment key={item.id}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              padding: "24px",
              background: insideCardBackgroundColorLayer1,
              borderRadius: "8px",
              boxShadow: `0px 0px 24px 0px ${boxShadowlayerColor1}`,
              width: "100%", // Ensures the list is 100% width of the container
            }}
          >
            <div className="flex flex-row gap-3">
              {item?.thumbnailUrl ? (
                <img
                  src={item?.thumbnailUrl}
                  alt="Card-specific "
                  className="h-auto w-[90px]"
                />
              ) : (
                <img src={image} alt="Fallback " className="h-auto w-[90px]" />
              )}
              <div className="flex flex-col" style={{ flex: 1 }}>
                <Typography
                  sx={{
                    color: textColorLayer2,
                    fontWeight: 700,
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  {item?.title}
                </Typography>
                <Typography
                  sx={{
                    color: textColorLayer2,
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  {item?.description}
                </Typography>

                <div className="flex flex-row gap-2">
                  <Typography sx={{ color: textColorLayer4, fontWeight: 700 }}>
                    Date:
                  </Typography>
                  <Typography sx={{ color: textColorLayer4, fontWeight: 400 }}>
                    {item?.uploadedOn
                      ? formatDate(item.uploadedOn)
                      : formatDate(item?.date)}
                  </Typography>
                </div>
                {content === "Documents" && (
                  <>
                    <div className="flex flex-row gap-2">
                      <Typography
                        sx={{ color: textColorLayer4, fontWeight: 700 }}
                      >
                        View Count:
                      </Typography>
                      <Typography
                        sx={{ color: textColorLayer4, fontWeight: 400 }}
                      >
                        {item?.counts ? item?.counts || 0 : item?.views || 0}
                      </Typography>
                    </div>
                  </>
                )}
                <div className="flex flex-row gap-2">
                  <Typography sx={{ color: textColorLayer4, fontWeight: 700 }}>
                    Likes:
                  </Typography>
                  <Typography sx={{ color: textColorLayer4, fontWeight: 400 }}>
                    {item?.likes ? item?.likes : "0"}
                  </Typography>
                </div>
              </div>
            </div>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%", // Ensure the buttons and icons are within bounds
              }}
            >
              <div className="flex flex-row gap-3">
                <Box
                  sx={linkContainer}
                  onClick={() => handleAddFavourite(item)}
                >
                  {item.favorite ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 18 17"
                      fill="none"
                    >
                      <path
                        d="M11.3417 6.18366L17.3334 6.70033L12.7917 10.642L14.15 16.5003L9.00002 13.392L3.85002 16.5003L5.21669 10.642L0.666687 6.70033L6.65835 6.19199L9.00002 0.666992L11.3417 6.18366Z"
                        fill="#005CBC"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M18.3334 7.70033L12.3417 7.18366L10 1.66699L7.65835 7.19199L1.66669 7.70033L6.21669 11.642L4.85002 17.5003L10 14.392L15.15 17.5003L13.7917 11.642L18.3334 7.70033ZM10 12.8337L6.86669 14.7253L7.70002 11.1587L4.93335 8.75866L8.58335 8.44199L10 5.08366L11.425 8.45033L15.075 8.76699L12.3084 11.167L13.1417 14.7337L10 12.8337Z"
                        fill="#005CBC"
                      />
                    </svg>
                  )}
                  <Typography
                    sx={isMobile ? { display: "none" } : linkTypography}
                  >
                    Favorite
                  </Typography>
                </Box>

                <Box sx={linkContainer} onClick={() => handleOpenSummary(item)}>
                  <RemoveRedEyeOutlinedIcon
                    sx={{ fill: "#005CBC", width: "20px", height: "20px" }}
                  />
                  <Typography
                    sx={isMobile ? { display: "none" } : linkTypography}
                  >
                    View Summary
                  </Typography>
                </Box>
              </div>

              <Button
                sx={buttonStyle}
                onClick={() => handleNavigate(item, content.replace(/s$/, ""))}
              >
                <Typography
                  sx={{
                    color: "#005CBC",
                    fontWeight: 700,
                    fontSize: "14px",
                    textTransform: "none",
                  }}
                >
                  View {content.replace(/s$/, "")}
                </Typography>
                {content === "Links" && (
                  <LaunchOutlinedIcon
                    sx={{
                      fill: "#005CBC",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                )}
                {content !== "Links" && (
                  <ArrowForwardIcon
                    sx={{
                      fill: "#005CBC",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                )}
              </Button>
            </Box>
          </Box>
        </React.Fragment>
      ))}
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            color: "#005CBC",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          {isExpanded
            ? `${data?.length} of ${data?.length}`
            : `${(viewMode !== "list" ? visibleData : data)?.length} of ${
                data?.length
              }`}
        </Typography>
        {pageType === "LibraryDetails" && viewMode !== "list" && (
          <Link to={`/library-details/${subPageId}`}>
            <Typography
              sx={{
                color: "#005CBC",
                textAlign: "right",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              See all {content}
            </Typography>
          </Link>
        )}
      </div>
      {loading && <Loading />}
    </Box>
  );
}
