import axios from "axios";
import { FILE_SERVICE_BASE_URL } from "../constant/apiUrl";
import { showErrorToast } from "./Toast/toast";

const fetchAndCacheImageName = async (id, action, dispatch) => {
  const token = localStorage.getItem("auth_token");

  try {
    const response = await axios.get(
      `${FILE_SERVICE_BASE_URL}/fritolay/file-manager/download-by-id?fileId=${id}&user=ADMIN`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/octet-stream",
        },
        responseType: "arraybuffer",
      }
    );

    if (response?.data) {
      // Detect MIME type from response headers
      const contentType = response.headers["content-type"];
      let extension = "bin"; // Default if type is unknown

      if (contentType) {
        if (contentType.includes("jpeg")) extension = "jpg";
        else if (contentType.includes("png")) extension = "png";
        else if (contentType.includes("svg")) extension = "svg";
        else if (contentType.includes("pdf")) extension = "pdf";
      }

      const blob = new Blob([response.data], { type: contentType });
      const url = URL.createObjectURL(blob);

      // Extract filename from headers if available
      const contentDisposition = response.headers["content-disposition"];
      let fileName = `file_${id}.${extension}`;

      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match && match[1]) {
          fileName = match[1];
        }
      }

      const fileObject = { id, name: fileName, url, type: extension };

      if (action) {
        dispatch(action(fileObject));
      }

      return fileObject;
    }
  } catch (error) {
    console.error(`Error fetching file for ID ${id}:`, error);
    return null;
  }
};

export default fetchAndCacheImageName;
