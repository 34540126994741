import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  TextField,
  Autocomplete,
} from "@mui/material";
import MobileNotifications from "../Notifications/MobileNotifications";
import commonprivacy from "./../../assets/tandc.pdf";
import axios from "axios";
import { FILE_SERVICE_BASE_URL } from "../../constant/apiUrl";
import { Button } from "pepsico-ds";
const countries = [
  {
    id: 1,
    name: "USA",
    description: "USA",
    active: true,
  },
  {
    id: 61,
    name: "Egypt",
    description: "Egypt",
    active: true,
  },
  {
    id: 62,
    name: "Pakistan",
    description: "Pakistan",
    active: true,
  },
  {
    id: 63,
    name: "Saudi",
    description: "Saudi",
    active: true,
  },
  {
    id: 66,
    name: "China",
    description: "China",
    active: true,
  },
];
const AccessRequestForm = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    company: "",
    country: "",
    contactNumber: "",
    sponsorEmail: "",
    acceptTerms: false,
  });

  const [notification, setNotification] = useState({
    open: false,
    type: "", // success, information, warning, error
    message: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "contactNumber") {
      const sanitizedValue = value.replace(/[^0-9]/g, "").slice(0, 10);
      setFormData((prevState) => ({
        ...prevState,
        [name]: sanitizedValue,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const handleRequestAccess = async () => {
    const {
      firstName,
      lastName,
      email,
      confirmEmail,
      company,
      country,
      contactNumber,
      sponsorEmail,
      acceptTerms,
    } = formData;

    if (
      !firstName ||
      !lastName ||
      !email ||
      !confirmEmail ||
      !company ||
      !country ||
      !sponsorEmail ||
      !acceptTerms
    ) {
      setNotification({
        open: true,
        type: "error",
        message:
          "All required fields must be filled out, including accepting the Terms and Conditions.",
      });
      return;
    }

    if (
      !isValidEmail(email) ||
      !isValidEmail(confirmEmail) ||
      !isValidEmail(sponsorEmail)
    ) {
      setNotification({
        open: true,
        type: "error",
        message: "Please provide valid email addresses.",
      });
      return;
    }

    if (email !== confirmEmail) {
      setNotification({
        open: true,
        type: "error",
        message: "Email and Confirm Email must match.",
      });
      return;
    }

    const requestData = {
      firstName: firstName,
      lastName: lastName,
      company: company,
      email: email,
      sponsorEmail: sponsorEmail,
      countryId: country,
      contactNo: contactNumber, // Optional field
      termsCondition: acceptTerms,
    };

    console.log("Sending request data:", requestData);
    try {
      const response = await axios.post(
        `${FILE_SERVICE_BASE_URL}/fritolay/admin/access-request-form/save`,
        // `https://flag-dev-c5gkcedqchceesa8.eastus-01.azurewebsites.net/fritolay/admin/access-request-form/save`,
        requestData
      );

      if (response.status === 200 || response.status === 201) {
        setNotification({
          open: true,
          type: "success",
          message: "Access request submitted successfully!",
        });

        handleClose();
      }
    } catch (error) {
      // Handle error response
      setNotification({
        open: true,
        type: "error",
        message:
          error.response?.data?.message ||
          "An error occurred while submitting the access request. Please try again.",
      });
    } finally {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        confirmEmail: "",
        company: "",
        country: "",
        contactNumber: "",
        sponsorEmail: "",
        acceptTerms: false,
      });
      handleClose();
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Typography
            variant="h6"
            sx={{ marginBottom: 2, textAlign: "center", fontWeight: 700 }}
          >
            Access Request Form
          </Typography>

          {/* Input Fields */}
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
            {/* Left Column */}
            <Box>
              <Box sx={{ position: "relative", marginBottom: 3 }}>
                <Typography
                  variant="body1"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    fontSize: "10px",
                    color: "#616161",
                  }}
                >
                  *Required
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginBottom: 1, fontSize: "12px" }}
                >
                  First Name
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="firstName"
                  placeholder="Type here"
                  value={formData.firstName}
                  onChange={handleChange}
                  sx={{ maxWidth: "400px" }}
                  required
                />
              </Box>

              <Box sx={{ position: "relative", marginBottom: 3 }}>
                <Typography
                  variant="body1"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    fontSize: "10px",
                    color: "#616161",
                  }}
                >
                  *Required
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginBottom: 1, fontSize: "12px" }}
                >
                  Email Address
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="email"
                  placeholder="Type here"
                  value={formData.email}
                  onChange={handleChange}
                  type="email"
                  sx={{ maxWidth: "400px" }}
                  required
                />
              </Box>

              <Box sx={{ position: "relative", marginBottom: 3 }}>
                <Typography
                  variant="body1"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    fontSize: "10px",
                    color: "#616161",
                  }}
                >
                  *Required
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginBottom: 1, fontSize: "12px" }}
                >
                  Company/Organization
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="company"
                  placeholder="Type here"
                  value={formData.company}
                  onChange={handleChange}
                  sx={{ maxWidth: "400px" }}
                  required
                />
              </Box>

              <Box sx={{ position: "relative", marginBottom: 3 }}>
                <Typography
                  variant="body1"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    fontSize: "10px",
                    color: "#616161",
                  }}
                >
                  *Required
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginBottom: 1, fontSize: "12px" }}
                >
                  Country
                </Typography>

                {/* <FormControl fullWidth size="small" sx={{ maxWidth: "400px" }}>
                  <Select
                    labelId="country-select-label"
                    id="country-select"
                    value={formData.country}
                    name="country"
                    placeholder="Type here"
                    onChange={handleChange}
                    variant="outlined"
                    required
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                <Autocomplete
                  id="country-autocomplete"
                  options={countries}
                  getOptionLabel={(option) => option.name} // Display country name
                  value={
                    countries.find(
                      (country) => country.id === formData.country
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      country: newValue ? newValue.id : "",
                    }));
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  sx={{
                    "& .MuiAutocomplete-input": {
                      height: "9px",
                    },
                    "& .MuiOutlinedInput-root": {
                      minHeight: "40px",
                    },
                    "& .MuiAutocomplete-listbox": {
                      maxHeight: "200px",
                      overflow: "auto",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Type here"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </Box>
            </Box>

            {/* Right Column */}
            <Box>
              <Box sx={{ position: "relative", marginBottom: 3 }}>
                <Typography
                  variant="body1"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    fontSize: "10px",
                    color: "#616161",
                  }}
                >
                  *Required
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginBottom: 1, fontSize: "12px" }}
                >
                  Last Name
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="lastName"
                  placeholder="Type here"
                  value={formData.lastName}
                  onChange={handleChange}
                  sx={{ maxWidth: "400px" }}
                  required
                />
              </Box>

              <Box sx={{ position: "relative", marginBottom: 3 }}>
                <Typography
                  variant="body1"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    fontSize: "10px",
                    color: "#616161",
                  }}
                >
                  *Required
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginBottom: 1, fontSize: "12px" }}
                >
                  Confirm Email
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="confirmEmail"
                  placeholder="Type here"
                  value={formData.confirmEmail}
                  onChange={handleChange}
                  type="email"
                  sx={{ maxWidth: "400px" }}
                  required
                />
              </Box>

              <Box sx={{ position: "relative", marginBottom: 3 }}>
                <Typography
                  variant="body1"
                  sx={{ marginBottom: 1, fontSize: "12px" }}
                >
                  Contact Number
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="contactNumber"
                  placeholder="Type here"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  type="tel"
                  sx={{ maxWidth: "400px" }}
                />
              </Box>

              <Box sx={{ position: "relative", marginBottom: 3 }}>
                <Typography
                  variant="body1"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    fontSize: "10px",
                    color: "#616161",
                  }}
                >
                  *Required
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginBottom: 1, fontSize: "12px" }}
                >
                  PepsiCo Sponsor’s Email
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="sponsorEmail"
                  placeholder="Type here"
                  value={formData.sponsorEmail}
                  onChange={handleChange}
                  type="email"
                  sx={{ maxWidth: "400px" }}
                  required
                />
              </Box>
            </Box>
          </Box>

          <Divider sx={{ marginY: 2 }} />

          {/* Terms and Conditions Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                name="acceptTerms"
                checked={formData.acceptTerms}
                onChange={handleChange}
              />
            }
            label={
              <Typography variant="body1">
                Accept{" "}
                <Typography
                  component="span"
                  sx={{
                    color: "#005CBC",
                    fontWeight: 700,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(commonprivacy, "_blank", "noopener noreferrer")
                  }
                >
                  Terms and Conditions{" "}
                </Typography>{" "}
              </Typography>
            }
            sx={{ marginLeft: 0 }}
          />

          <Divider sx={{ marginY: 2 }} />

          {/* Action Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flex: "1 0 0",
              gap: 4,
            }}
          >
            <Button
              variant="secondary"
              onClick={handleClose}
              text="Cancel"
              />
            <Button
              variant="primary"
              onClick={handleRequestAccess}
              text="Request Access"
            />
          </Box>
        </Box>
      </Modal>

      {/* Notification */}
      <MobileNotifications
        type={notification.type}
        message={notification.message}
        open={notification.open}
        handleClose={handleCloseNotification}
      />
    </>
  );
};
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: 4,
  borderRadius: 2,
  boxShadow: 24,
  width: "80%",
  maxWidth: "800px",
};
export default AccessRequestForm;
