import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";
import Loading from "../utils/Loader";
import {externalConfig} from "../oktaConfig";

const Logout = () => {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    logout();
  }, []);

  const config = externalConfig;

  const logout = async () => {
    try {
      const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
      const idToken = oktaTokenStorage?.idToken?.idToken; // Get ID token if available

      await oktaAuth.revokeAccessToken();
      await oktaAuth.revokeRefreshToken();

      oktaAuth.tokenManager.clear();

      await oktaAuth.closeSession();

      // Clear all possible session cookies
      document.cookie = "okta-oauth-nonce=; Max-Age=0; path=/";
      document.cookie = "okta-oauth-state=; Max-Age=0; path=/";
      document.cookie = "idx=; Max-Age=0; path=/";
      document.cookie = "sid=; Max-Age=0; path=/"; // Clears Okta session ID
      document.cookie = "JSESSIONID=; Max-Age=0; path=/"; // Clears session ID

      // Step 5: Clear local and session storage
      localStorage.clear();
      sessionStorage.clear();

      const idpLogoutUrl = config.issuer+`/v1/logout?id_token_hint=${idToken}&client_id=`+config.clientId+`&post_logout_redirect_uri=`+window.location.origin + `/login`;

      window.location.href = idpLogoutUrl; // Redirect to Okta logout URL

    } catch (error) {
      console.error("Error during logout:", error);
    }
  };


  return <Loading />;
};

export default Logout;
