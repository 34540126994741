import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import { artifactManagementData } from "../constants";
import {
  AddMoreButton,
  Body,
  CardWrapper,
  Container,
  HeaderBox,
  HeaderTitle,
} from "../Components/curatorApprovalStyle";
import BackToFunction from "../Components/BackToFunction";
import ContentDetails from "../Components/ContentDetails";
import CropType from "../Components/CropType";
import Approval from "../Components/Approval";
import SummarySection from "../Components/SummarySection";
import PlusIcon from "../Components/PlusIcon";
import ArtifactManagement from "../Components/ArtifactManagement";
import { setSelectedCuratorPage } from "../../../slices/curatorPageSlice";
import YearlyArtifactDocumentComponent from "../Components/YearlyArtifactDocumentComponent";
import Footer from "../Components/Footer";
import ApproverHistoryTable from "../YealryReview/ApproverHistoryTable";
import useThemeRender from "../../../theme/useThemeRender";
import { useOktaAuth } from "@okta/okta-react";
import { showErrorToast, showSuccessToast } from "../../../utils/Toast/toast";
import {
  getSelectedArtificatsHitoryData,
  submitYearyPendingApprovalRequest,
  deleteCuratorFile,
  downloadFileToLocal,
  uploadYearlyReviewArtificatsFiles,
  getSMELookupData
} from "../../../API/curatorApi";
import {getDropDownOptionValues, getFormattedDate} from "../../../utils/function";
import CuratorYearlyApprovalModal from "../CuratorYearlyApprovalModal";
import { setUser } from "../../../slices/userSlice";
import Loading from "../../../utils/Loader";

const ArtifactHistoryReview = () => {
  const { register, formState } = useForm({
    defaultValues: {
      contentTitle: "",
      specialProject: "",
      versionNumber: "3",
    },
  });
  const { errors } = formState;
  const navigate = useNavigate();
  const queryParams = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isDarkTheme = useSelector((state) => state.theme);
  const [showAddMoreArtificats, setShowAddMoreArtificats] = useState(false);
  const editorRef = useRef(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contentType, setContentType] = useState(null);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState(null);
  const [isArchiveType, setIsArchiveType] = useState(false);
   const [subjectMatterExpert, setSubjectMatterExpert] = useState([])
  const [contentId, setContentId] = useState(null);
  const { oktaAuth } = useOktaAuth();
  const curatorPageLookUpData = useSelector(
      (store) => store?.internalFilter
  );
  const [lookUpOptions, setLookupOptions] = useState({
    specialProjects: [],
    subjects: [],
    sectors: [],
    countries: [],
    language: [],
    subjectMatterExpert: [],
    disclaimerOptions: [],
    disclaimer: [],
    cropType: [],
    visbility: [],
  });
  const [contentDetailsSectionData, setContentDetailsSectionData] = useState({
    contentTitle: "",
    visibility: "",
    contentLevel: "",
    specialProjects: [],
    subjects: [],
    sectors: [],
    countries: [],
    language: "",
    subjectMatterExpert: "",
  });
  const [cropTypeSectionData, setCropTypeData] = useState({
    keyWordsText: "",
    cropType: "",
  });
  const [approvalSection, setApprovalSection] = useState({
    legalApproval: null,
    dropImage: null,
    uploadedImageId: null,
  });
  const [summarySectionData, setSummarySectionData] = useState({
    documentNotes: "",
    summary: "",
    disclaimerDetails: "",
    isTranslation: false,
    translationLanguage: "",
    translatedText: "",
    disclaimer: "",
  });
  const [artifactManagementArray, setArtifactManagementArray] = useState([
    artifactManagementData,
  ]);
  const [approvarHistory, setApprovarHistory] = useState([]);

  useEffect(() => {
      getSMELookupData().then(response => {
           const smeData = getDropDownOptionValues(response, true);
              console.log('smeData---->', smeData);
              setSubjectMatterExpert(smeData);
              getContentData(smeData);
      }).catch(error => {
        console.log('failed to fetch sme data', error?.message);
        getContentData([]);
      })
  },[curatorPageLookUpData?.sectorFilter]);

  const getContentData = (smeData = []) => {
    getSelectedArtificatsHitoryData(queryParams?.id)
    .then((response) => {
      setContentType(response?.type);
      setContentDetailsSectionData({
        contentTitle: response?.title,
        visibility: response?.visibility,
        contentLevel: response?.level,
        specialProjects: response?.specialProjects,
        subjects: response?.subject,
        sectors: response?.sector,
        countries: response?.country,
        language: response?.language === null ? "" : response?.language,
        subjectMatterExpert: response?.sme === null ? "" : response?.sme,
      });
      setCropTypeData({
        keyWordsText: response?.keywords,
        cropType: response?.cropType,
      });
      setApprovalSection({
        legalApproval: response?.isLegalReview,
        dropImage: response?.document,
      });
      setSummarySectionData({
        documentNotes: response?.documentNotes,
        summary: response?.summary,
        disclaimerDetails: response?.disclaimerDetails,
        translatedText: response?.translatedText,
        translationLanguage: response?.translationLanguage,
        isTranslation: response?.isTranslation,
        disclaimer: response?.disclaimer,
      });
      setArtifactManagementArray(response?.artifactList);
      setContentId(response?.contentId);
      setApprovarHistory(response?.approverHistoryList);
      setLookupOptions({
          sectors: getDropDownOptionValues(curatorPageLookUpData?.sectorFilter),
          specialProjects: getDropDownOptionValues(curatorPageLookUpData?.specialProjects),
          subjectMatterExpert: smeData,
          subjects: getDropDownOptionValues(curatorPageLookUpData?.subjectFilter),
          countries: getDropDownOptionValues(curatorPageLookUpData?.countryFilter),
          language: getDropDownOptionValues(curatorPageLookUpData?.laguageFilter),
          cropType: getDropDownOptionValues(curatorPageLookUpData?.cropTypeFilter),
          disclaimer: getDropDownOptionValues(curatorPageLookUpData?.disclaimers),
          visibility:getDropDownOptionValues( curatorPageLookUpData?.visibility),
          contentLevel: getDropDownOptionValues(curatorPageLookUpData?.contentLevel),
      });
      setIsPageLoading(false);
    })
    .catch((error) => {
      console.log("Get Approval API Error:", error?.message);
      showErrorToast("Get Approval API Error:", error?.message);
      setIsPageLoading(false);
    });
  }

 const uploadArtificatsFiles = (files = [], newVersionNumber) => {
    setIsPageLoading(true);
    files?.forEach((file, index) => {
      uploadYearlyReviewArtificatsFiles(file)
        .then((data) => {
          if (data?.error) {
            setIsPageLoading(false);
            showErrorToast(data?.message);
            return;
          }
          showSuccessToast("File Upload Successfully");
          if (index + 1 === files.length) {
            const updateTheArtificats = [...artifactManagementArray];
            updateTheArtificats.unshift({
              versionNumber: `${newVersionNumber}`,
              uploadedBy: `${loggedInUserDetail?.FirstName} ${loggedInUserDetail?.LastName}`,
              uploadedDate: getFormattedDate(new Date()),
              imageInfo: "",
            });
            setArtifactManagementArray([...updateTheArtificats]);
            setShowAddMoreArtificats(false);
          }
          setIsPageLoading(false);
        })   
        .catch((error) => {
          console.log("error", error);
          showErrorToast("Something went wrong in file upload");
          setIsPageLoading(false);
        });
    });
  };
  const deleteArtifcateFile = (file) => {
    setIsPageLoading(true);
    deleteCuratorFile(file)
      .then((data) => {
        showSuccessToast("File deleted Successfully");
        setIsPageLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        showErrorToast("Something went wrong in file upload");
        setIsPageLoading(false);
      });
  };

  const downloadFile = (fileId, fileName) => {
    setIsPageLoading(true);
     if(fileId === null || fileId === undefined) {
          if (contentType === 'LINK') {
            showErrorToast("Unable to open the file link");
          } else {
            showErrorToast(`fileId is not present: ${fileId}`);
          }
          setIsPageLoading(false);
          return;
        }
    downloadFileToLocal(fileId, fileName)
      .then((response) => {
        if (response?.status === 200) {
          showSuccessToast("File downloaded successfully");
        } else {
          showErrorToast("Something went wrong while downloading file");
        }
        setIsPageLoading(false);
      })
      .catch((error) => {
        console.log("error in file download", error);
        setIsPageLoading(false);
        showErrorToast("Something went wrong while downloading file");
      });
  };
  const onConfirm = {
    label: "Save",
    handConfirmEvent: () => {
      setIsArchiveType(false);
      setIsModalVisible(true);
    },
  };

  const onCancel = {
    label: "Cancel",
    handleCancelEvent: () => {
      /// setIsModalVisible(true);
      // setIsArchiveType(true);
    },
  };

  useEffect(() => {
    oktaAuth.getUser().then((info) => {
      setLoggedInUserDetail(info);
      dispatch(setUser(info));
    });
  }, [oktaAuth]);

  const makeAnnualPendingApprovalApiCall = async (
    opetationType,
    explanation = ""
  ) => {
    const apiInputPayLoad = {
      contentId: contentId,
      contentGuid: queryParams?.id,
      title: contentDetailsSectionData?.contentTitle,
      visibility: contentDetailsSectionData?.visibility,
      specialProjects: contentDetailsSectionData?.specialProjects,
      subjects: contentDetailsSectionData?.subjects,
      sectors: contentDetailsSectionData?.sectors,
      countries: contentDetailsSectionData?.countries,
      language: contentDetailsSectionData?.language,
      subjectMatterExpert: contentDetailsSectionData?.subjectMatterExpert,
      contentLevel: contentDetailsSectionData?.contentLevel,
      cropType: cropTypeSectionData?.cropType,
      keywords: cropTypeSectionData?.keyWordsText,
      legalReview: approvalSection?.legalApproval,
      document: "",
      documentNotes: summarySectionData?.documentNotes,
      originalFileId: approvalSection?.uploadedImageId,
      summary: summarySectionData?.summary,
      disclaimer: summarySectionData?.disclaimer,
      disclaimerDetail: summarySectionData?.disclaimerDetails,
      isTranslation:
        summarySectionData?.isTranslation === undefined
          ? false
          : summarySectionData?.isTranslation == "Yes"
          ? true
          : false,
      translationLanguage: summarySectionData?.translationLanguage
        ? summarySectionData?.translationLanguage
        : "",
      rejectedExplanation: " ",
      translatedText: summarySectionData?.translatedText,
      reviewedBy: `${loggedInUserDetail?.FirstName} ${loggedInUserDetail?.LastName}`,
    };

    console.log("Making the form data", {
      contentDetailsSectionData,
      cropTypeSectionData,
      approvalSection,
      summarySectionData,
      artifactManagementArray,
      opetationType,
      apiInputPayLoad,
    });
    const notificationTitle =  apiInputPayLoad?.title === null ||  apiInputPayLoad?.title === undefined ? 'Content' : apiInputPayLoad?.title;
    if (opetationType === "deny") {
      dispatch(setSelectedCuratorPage("Artifact history"));
      navigate("/curator");
    } else {
      submitYearyPendingApprovalRequest(apiInputPayLoad)
        .then((response) => {
          if (response?.status !== 200) {
            showErrorToast(`Opps! Something went wrong:${response?.message}`);
            setIsPageLoading(false);
          } else {
            setIsModalVisible(false);
            setIsPageLoading(false);
            showSuccessToast(
              `${notificationTitle}  published successfully!`
            );
            dispatch(setSelectedCuratorPage("Artifact history"));
            navigate("/curator");
          }
        })
        .catch((error) => {
          setIsPageLoading(false);
          showErrorToast(`Opps! Something went wrong:${error?.message}`);
          setIsModalVisible(false);
        });
    }
  };

  const handleNavigation = () => navigate("/curator");

  const handleArtifactManagement = () => {
    setShowAddMoreArtificats(true);
  };

  return (
    <Container>
      <Body>
        <BackToFunction
          label={"Back to artifact history"}
          handleBack={handleNavigation}
        />
        {isPageLoading && <Loading />}
        <CardWrapper
          sx={{ background: useThemeRender("cardBackgroundColorLayer1") }}
        >
          <ContentDetails
            errors={errors}
            register={register}
            setContentDetailsSectionData={setContentDetailsSectionData}
            contentDetailsSectionData={contentDetailsSectionData}
            lookUpOptions={lookUpOptions}
          />

          <CropType
            cropTypeSectionData={cropTypeSectionData}
            setCropTypeData={setCropTypeData}
            cropTypeOptions={lookUpOptions?.cropType}
          />

          <Approval
            isDarkTheme={isDarkTheme}
            isDisabled={approvalSection?.legalApproval ? false : true}
            approvalSection={approvalSection}
            setApprovalSection={setApprovalSection}
            uploadArtificatsFiles={uploadArtificatsFiles}
            deleteArtifcateFile={deleteArtifcateFile}
          />

          <SummarySection
            editorRef={editorRef}
            summarySectionData={summarySectionData}
            setSummarySectionData={setSummarySectionData}
            lookUpOptions={lookUpOptions}
          />

          <HeaderBox isMobile={isMobile}>
            <HeaderTitle sx={{ color: useThemeRender("textColorLayer2") }}>
              Artifact management
            </HeaderTitle>
            <AddMoreButton
              variant="contained"
              isMobile={isMobile}
              onClick={handleArtifactManagement}
              disabled={showAddMoreArtificats}
            >
              Add more
              <PlusIcon />
            </AddMoreButton>
          </HeaderBox>

          {showAddMoreArtificats && (
            <YearlyArtifactDocumentComponent
              register={register}
              onSave={uploadArtificatsFiles}
              showAddMoreArtificats={setShowAddMoreArtificats}
              artifactManagementArray={artifactManagementArray}
            />
          )}

          <ArtifactManagement
            register={register}
            artifactManagementArray={artifactManagementArray}
            setArtifactManagementArray={setArtifactManagementArray}
            downloadFile={downloadFile}
            contentType={contentType}
          />
          <HeaderBox isMobile={isMobile}>
            <HeaderTitle sx={{ color: useThemeRender("textColorLayer2") }}>
              Approver history
            </HeaderTitle>
          </HeaderBox>
          <ApproverHistoryTable apprverHistory={approvarHistory}/>
        </CardWrapper>
      </Body>

      <Footer
        onCancel={onCancel}
        onConfirm={onConfirm}
        btnStyle={{
          color: "#005CBC",
          border: "1px solid var(--Tokens-Button-Outlined, #005CBC)",
          flex: isMobile && 1,
        }}
      />

      {isModalVisible && (
        <CuratorYearlyApprovalModal
          user={loggedInUserDetail}
          approveModal={isModalVisible}
          isArchiveType={isArchiveType}
          setIsModalVisible={setIsModalVisible}
          makePendingApprovalApiCall={makeAnnualPendingApprovalApiCall}
        />
      )}
    </Container>
  );
};

export default ArtifactHistoryReview;
