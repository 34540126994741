import React from "react";
import { Filter, Search, Button } from "pepsico-ds";
import GridOnIcon from "@mui/icons-material/GridOn";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import { Box, Autocomplete, TextField, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { buttonStyle, boxStyle, filterStyle } from "./InternalStyle";
import {
  setCountry,
  setFlashColor,
  setIpOwnerShip,
  setSearch,
  setSector,
  setVariety,
} from "../../slices/internalPageSlice";
import useThemeRender from "../../theme/useThemeRender";
import PropTypes from "prop-types";
import { CustomPopper } from "../../utils/CustomPopper/CustomPopper";

export default function InternalFilter({
  setGrid,
  pageData,
  header,
  setSelection,
  grid,
}) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();
  const backgroundColorLayer3 = useThemeRender("backgroundColorLayer3");
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const iconColor1 = useThemeRender("iconColor1");
  const borderColorLayer2 = useThemeRender("borderColorLayer2");
  const isDarkTheme = useSelector((state) => state.theme);

  const { variety } = useSelector((state) => state.internalPage);
  const handleSelectedSector = (sectorName) => {
    dispatch(setSector(sectorName));
  };

  const handleSelectedCountry = (countryName) => {
    dispatch(setCountry(countryName));
  };
  const handleSearch = (value) => {
    dispatch(setSearch(value));
  };

  const handleSelect = (view) => {
    setGrid(view);
  };
  const {
    sectorFilter,
    countryFilter,
    ipOwnerShipFilter,
    varietyFilter,
    flashColorFilter,
  } = useSelector((store) => store.internalFilter);
  const dropDown = pageData?.internalSubPages?.map((item) => {
    return {
      id: item?.subPageId,
      label: item?.subPageName,
    };
  });

  const handleScrollToSection = (event, value) => {
    const label = value?.label;

    if (label) {
      setSelection(label);
    }
  };

  const handleVarietyChange = (value) => {
    dispatch(setVariety(value));
  };
  const handleSelectedFleshColor = (value) => {
    console.log(value, "valuevalue");
    dispatch(setFlashColor(value));
  };
  const handleIpOwnerShip = (value) => {
    dispatch(setIpOwnerShip(value));
  };

  const RenderGridButtons = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: "2.5px" }}>
        <Button
          onClick={() => handleSelect("tile")}
          style={{
            ...buttonStyle,
            backgroundColor: grid === "tile" ? "#005CBC" : "#fff",
            color: grid === "tile" ? "#fff" : "#005CBC",
            border: grid === "tile" ? "none" : "1px solid #D7E5F8",
          }}
        >
          <GridOnIcon sx={{ color: grid === "tile" ? "#fff" : "#005CBC" }} />
        </Button>

        <Button
          onClick={() => handleSelect("list")}
          style={{
            ...buttonStyle,
            backgroundColor: grid !== "tile" ? "#005CBC" : "#fff",
            color: grid !== "tile" ? "#fff" : "#005CBC",
            border: grid !== "tile" ? "none" : "1px solid #D7E5F8",
          }}
        >
          <ViewListOutlinedIcon
            sx={{ color: grid !== "tile" ? "#fff" : "#005CBC" }}
          />
        </Button>
      </Box>
    );
  };

  return (
    <Box
      sx={
        isMobile
          ? {
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: "100%",
            }
          : { display: "flex", justifyContent: "space-between", width: "100%" }
      }
    >
      <Box sx={boxStyle}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Autocomplete
            options={dropDown || []}
            getOptionLabel={(option) => option.label}
            sx={{
              ...filterStyle,
              background: backgroundColorLayer3,
              "& .MuiInputBase-root": {
                height: "36px",
                color: textColorLayer2,
              },
              "& .MuiAutocomplete-popupIndicator": {
                color: iconColor1,
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColorLayer2,
                },
                "&:hover fieldset": {
                  borderColor: borderColorLayer2,
                },
                "& .MuiAutocomplete-clearIndicator": {
                  color: iconColor1,
                },
              },
            }}
            onChange={handleScrollToSection}
            PopperComponent={(props) => (
              <CustomPopper isDarkTheme={isDarkTheme} {...props} />
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="Go to Section" />
            )}
          />
        </Box>

        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          <Filter
            label="Sector"
            onChange={handleSelectedSector}
            options={(sectorFilter || []).map((item) => ({
              id: item.id,
              label: item.name,
            }))}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />
          {header === "Potato variety" && (
            <>
              <Filter
                label="IP Ownership"
                onChange={handleIpOwnerShip}
                options={(ipOwnerShipFilter || []).map((item) => ({
                  id: item.id,
                  label: item.name,
                }))}
                selection="single"
                size="medium"
                isDark={isDarkTheme}
              />

              <Filter
                label="Flesh Color"
                onChange={handleSelectedFleshColor}
                options={(flashColorFilter || []).map((item) => ({
                  id: item.id,
                  label: item.name,
                }))}
                selection="single"
                size="medium"
                isDark={isDarkTheme}
              />
            </>
          )}
          {header !== "Potato variety" && (
            <Filter
              label="Country"
              onChange={handleSelectedCountry}
              options={(countryFilter || []).map((item) => ({
                id: item.id,
                label: item.name,
              }))}
              selection="single"
              size="medium"
              isDark={isDarkTheme}
            />
          )}
          {(header === "Potato Quality" || header === "Potato Storage") && (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Filter
                label="Variety"
                onChange={handleVarietyChange}
                options={(varietyFilter || []).map((item) => ({
                  id: item.id,
                  label: item.name,
                }))}
                selection="single"
                size="medium"
                isDark={isDarkTheme}
                value={variety}
                dropdownPosition="bottom"
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={
          isMobile
            ? { display: "flex", justifyContent: "space-between" }
            : { display: "flex", flexDirection: "row", gap: "16px" }
        }
      >
        <Search onUpdate={handleSearch} isDark={isDarkTheme} />
        {header !== "Library" && <RenderGridButtons />}
      </Box>
    </Box>
  );
}
InternalFilter.propTypes = {
  setGrid: PropTypes.func.isRequired,
  pageData: PropTypes.shape({
    internalSubPages: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string,
        content: PropTypes.string,
      })
    ),
  }).isRequired,
  header: PropTypes.string.isRequired,
  setSelection: PropTypes.func.isRequired,
  grid: PropTypes.string.isRequired, // Assuming grid is a string, adjust if necessary
};
