import { Box, Button, Typography, Divider, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import TextInputField from "../../../utils/Fields/TextInputField";
import noPreviewImage from "../../../assets/no-preview.png";
// import DropZone from "../../../utils/Upload/Dropzone";
import Dropzone from "react-dropzone";
import { FooterButton } from "../Components/curatorApprovalStyle";
import ImageList from "../../Administration/Screens/LandingPageHero.js/ImageList";
import { useDropzone } from "react-dropzone";
import {
  UploadInfo,
  UploadInfoDescription,
  UploadInfoLabel,
  ViewDocumentButton,
} from "./curatorApprovalStyle";

import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import {
  IsRequiredLabel,
  Label,
  UploadImageLabel,
  UploadImageWrapper,
} from "./Upload/dropzoneStyle";
import FileFormat from "./Upload/FileFormat";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getFormattedDate } from "../../../utils/function";
import SelectedImage from "./SelectedImage";
import useThemeRender from "../../../theme/useThemeRender";

const YearlyArtifactDocumentComponent = ({
  register,
  artifactManagementArray,
  setArtifactManagementArray,
  onSave,
  showAddMoreArtificats,
}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [isDocumentDropped, setIsDocumentDropped] = useState(false);
  const [isThumbnailDropped, setIsThumbnailDropped] = useState(false);
  const [selectedDocumentImage, setSelectedDocumentImage] = useState(null);
  const [selectedThumbnailImage, setSelectedThumbnailImage] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const onDragEnd = (result, index) => {
    if (!result.destination) return;

    const reorderedImages = Array.from(selectedDocumentImage);
    const [removed] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, removed);
    selectedDocumentImage(reorderedImages);

    const currentStateData = {
      ...artifactManagementArray[index],
    };
    currentStateData.imageInfo = reorderedImages;
    const newArtifactManagementData = [...artifactManagementArray];
    newArtifactManagementData[index] = currentStateData;
    setArtifactManagementArray(newArtifactManagementData);
  };

  const getArticatesVersionNumber = () => {
    return Math.round(artifactManagementArray?.[0].versionNumber) + 1;
  };

  const onReplace = async (acceptedFiles, docType) => {
    const newImage = acceptedFiles[0]?.file;
    let imageWithPreview = null;
    if (acceptedFiles?.[0]?.path?.indexOf('.pdf') > -1 ) {
      imageWithPreview = Object.assign(noPreviewImage, {
        preview: noPreviewImage,
      });
    } else {
       imageWithPreview = Object.assign(newImage, {
        preview: URL.createObjectURL(newImage),
      });
    }
    const replacedImage = [imageWithPreview];
    if (docType === "doc") {
      setSelectedDocumentImage(replacedImage);
    } else {
      setSelectedThumbnailImage(replacedImage);
    }
  };

  const handleDeleteClick = async (docType) => {
    if (docType === "doc") {
      setIsDocumentDropped(false);
      setSelectedDocumentImage(null);
    } else {
      setIsThumbnailDropped(false);
      setSelectedThumbnailImage(null);
    }
  };

  return (
    <Box
      sx={{
        background: useThemeRender("cardBackgroundColorLayer2"),
      }}
    >
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: "16px",
            padding: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: { sm: "100%", md: "360px" },
            }}
          >
            <Box
              sx={{
                width: "408px",
                height: "186px",
                top: "16px",
                left: "16px",
                gap: "18px",
                borderRadius: " var(--Cornerradiuseffect-corner-2)",
              }}
            >
              {isDocumentDropped ? (
                <ImageList
                  images={[...selectedDocumentImage]}
                  onDelete={() => handleDeleteClick("doc")}
                  onReplace={(file) => onReplace(file, "doc")}
                />
              ) : (
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    const newImage = acceptedFiles[0];
                    let imageWithPreview = null;
                    if (acceptedFiles?.[0]?.path?.indexOf('.pdf') > -1 ) {
                      imageWithPreview = Object.assign(noPreviewImage, {
                        preview: noPreviewImage,
                      });
                    } else {
                       imageWithPreview = Object.assign(newImage, {
                        preview: URL.createObjectURL(newImage),
                      });
                    }
                    setDocumentFile(acceptedFiles[0]);
                    const updatedImage = [imageWithPreview];
                    setSelectedDocumentImage(updatedImage);
                    setIsDocumentDropped(true);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Label>Document</Label>
                        <IsRequiredLabel>Required *</IsRequiredLabel>
                      </Box>
                      <UploadImageWrapper {...getRootProps()}>
                        <input {...getInputProps()} />
                        <FileFormat />

                        <Typography
                          variant="body1"
                          sx={{
                            textAlign: { xs: "center" },
                            width: { xs: "80%" },
                            // color: isDisabled
                            //   ? "#A0A0A0"
                            //   : isDarkTheme && darkTheme.textColorLayer1,
                          }}
                        >
                          Drag and drop your file here (only
                          .jpg,.png,.svg,.pdf)
                        </Typography>
                        <Button
                          variant="text"
                          sx={{
                            mt: 1,
                            borderRadius: "24px",
                            border: "1px solid #005CBC",
                            px: 2,
                            color: "#A0A0A0",
                          }}
                          startIcon={
                            <UploadOutlinedIcon
                              sx={{
                                color: "#005CBC",
                              }}
                            />
                          }
                        >
                          <UploadImageLabel variant="body2">
                            Upload image
                          </UploadImageLabel>
                        </Button>
                      </UploadImageWrapper>
                    </Box>
                  )}
                </Dropzone>
              )}
            </Box>
            <Box
              sx={{
                width: "408px",
                height: "186px",
                top: "16px",
                left: "16px",
                paddingTop: "42px",
                borderRadius: " var(--Cornerradiuseffect-corner-2)",
              }}
            >
              {isThumbnailDropped ? (
                <ImageList
                  images={[...selectedThumbnailImage]}
                  onDelete={() => handleDeleteClick("thumb")}
                  onReplace={(file) => onReplace(file, "thumb")}
                />
              ) : (
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    const newImage = acceptedFiles[0];
                    const imageWithPreview = Object.assign(newImage, {
                      preview: URL.createObjectURL(newImage),
                    });
                    const updatedImage = [imageWithPreview];
                    setImageFile(acceptedFiles[0]);
                    setSelectedThumbnailImage(updatedImage);
                    setIsThumbnailDropped(true);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Label>Thumbnail</Label>
                        <IsRequiredLabel>Required *</IsRequiredLabel>
                      </Box>
                      <UploadImageWrapper {...getRootProps()}>
                        <input {...getInputProps()} />
                        <FileFormat />

                        <Typography
                          variant="body1"
                          sx={{
                            textAlign: { xs: "center" },
                            width: { xs: "80%" },
                            // color: isDisabled
                            //   ? "#A0A0A0"
                            //   : isDarkTheme && darkTheme.textColorLayer1,
                          }}
                        >
                          Drag and drop your file here (only .jpg,.png)
                        </Typography>
                        <Button
                          variant="text"
                          sx={{
                            mt: 1,
                            borderRadius: "24px",
                            border: "1px solid #005CBC",
                            px: 2,
                            color: "#A0A0A0",
                          }}
                          startIcon={
                            <UploadOutlinedIcon
                              sx={{
                                color: "#005CBC",
                              }}
                            />
                          }
                        >
                          <UploadImageLabel variant="body2">
                            Upload image
                          </UploadImageLabel>
                        </Button>
                      </UploadImageWrapper>
                    </Box>
                  )}
                </Dropzone>
              )}
            </Box>
            <Box
              sx={{
                paddingTop: "42px",
              }}
            >
              <TextInputField
                label="Version Number"
                type="number"
                errors={false}
                register={register}
                name="newVersionNumber"
                value={getArticatesVersionNumber()}
                width={!isMobile ? 250 : "100%"}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "32px",
            padding: "0px 16px 0px 16px",
            gap: "12px",
            marginBottom: "12px",
            justifyContent: isMobile ? "space-between" : "end",
          }}
        >
          <FooterButton
            sx={{
              background: "",
              color: "#005CBC",
              border: "1px solid var(--Tokens-Button-Outlined, #005CBC)",
              flex: isMobile && 1,
            }}
            onClick={() => showAddMoreArtificats(false)}
          >
            Cancel
          </FooterButton>
          <FooterButton
            sx={{
              background:
                selectedDocumentImage === null ||
                selectedThumbnailImage === null
                  ? "#A0A0A0"
                  : "#005CBC",
              flex: isMobile && 1,
            }}
            disabled={
              selectedDocumentImage === null || selectedThumbnailImage === null
                ? true
                : false
            }
            onClick={() =>
              onSave(
                [documentFile, imageFile],
                getArticatesVersionNumber()
              )
            }
          >
            Save
          </FooterButton>
        </Box>
      </>
    </Box>
  );
};

export default YearlyArtifactDocumentComponent;
