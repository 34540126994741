import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
const Header = ({ isDarkTheme, darkTheme, lightTheme, slotNumber }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        alignSelf: "stretch",
      }}
    >
      <Typography
        sx={{
          color: isDarkTheme
            ? darkTheme.textColorLayer2
            : lightTheme.textColorLayer2,
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
          fontSize: "var(--Font-Size-H5, 24px)",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "var(--Line-Height-H5, 32px)",
        }}
      >
        Hero Slot {slotNumber}
      </Typography>
    </Box>
  );
};

export default Header;
Header.propTypes = {
  isDarkTheme: PropTypes.bool.isRequired, // Boolean to indicate if dark theme is active
  darkTheme: PropTypes.object.isRequired, // Object containing dark theme styles
  lightTheme: PropTypes.object.isRequired, // Object containing light theme styles
  slotNumber: PropTypes.number.isRequired, // Number for the slot number to be displayed
};
