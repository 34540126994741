import React from "react";
import { Box, Typography, Avatar as MuiAvatar } from "@mui/material";
import AccessRequestFormModal from "./AccessRequestForm";
import image1 from "../../assets/egypt.jpg";
import image2 from "../../assets/elma.jpg";
import image3 from "../../assets/Frenchitas.jpg";
import image4 from "../../assets/Lays.jpg";
import image5 from "../../assets/Leishi.jpg";
import image6 from "../../assets/Margarita.jpg";
import image7 from "../../assets/Sabritas.jpg";
import image8 from "../../assets/SaudiArabia.jpg";
import image9 from "../../assets/Smiths.jpg";
import image10 from "../../assets/Taiwan.jpg";
import image11 from "../../assets/Tapuchips.jpg";
import image12 from "../../assets/Thailand.jpg";
import { Avatar, Button, Card, Font, Header, Logo } from "pepsico-ds";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector } from "react-redux";
import { setConfig } from "../../slices/configSlice";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useEffect } from "react";
import LoginFooter from "./LoginFooter";
import LoginPepsiSvg from "./LoginPepsiSvg";
import "./MobileSign.css";
const imageArray = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
];

// Common styles for the overlay container
const overlayStyles = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 2,
  display: "flex",
  flexDirection: "column",
  width: "90%",
  padding: "16px",
  alignItems: "center",
  gap: "24px",
  borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
  background: "var(--Tokens-Background-Default, #F3F7FD)",
  boxShadow:
    "var(--Elevation-X-Elevation-03, 0px) var(--Elevation-Y-Elevation-03, 2px) var(--Elevation-Blur-Elevation-03, 24px) var(--Elevation-Spread-Elevation-03, 8px) var(--Elevation-Colors-Elevation-03, rgba(0, 92, 188, 0.12))",
  height: "auto", // Set height to auto to fit both cards
  maxHeight: "80vh", // Optional: limit max height to avoid overflow
  overflow: "auto", // Allow scrolling if content exceeds height
  scrollbarWidth: "none", // Firefox: Hide scrollbar
  "&::-webkit-scrollbar": {
    display: "none", // Webkit: Hide scrollbar
  },
};

// Common styles for the card components
const cardStyles = {
  display: "flex",
  padding: "16px",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
  borderRadius: "8px",
  backgroundColor: "#FBFCFE",
  boxShadow: "0px 0px 24px rgba(0, 92, 188, 0.08)",
  flexShrink: 0,
  width: "100%", // Ensure card fills the width
};

export default function MobileSign() {
  const { authState, oktaAuth } = useOktaAuth();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { External } = useSelector((store) => store.config);

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const switchToExternal = () => {
    dispatch(setConfig(false));
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (authState && External !== null) {
      login();
    }
  }, [External, authState]);
  return (
    <Box
      sx={{
        minHeight: "100vh",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#EFF5FC",
        flexShrink: 0,
        justifyContent: "center",
        gap: "24px",
      }}
    >
      <Header
        style={{ justifyContent: "space-between", height: "64px" }}
        leftIcon={<LoginPepsiSvg />}
        middleIcon={
          <Font size="body" variant="bold" isDark>
            Frito-Lay Agriculture
          </Font>
        }
        className="mobile-app-header"
      />
      {/* <LoginHeader /> */}

      {/* Background Grid */}
      <main
        style={{
          flex: 1,
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)", // 3 images per row
          gap: "16px", // Spacing between each image box
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {imageArray.map((image, index) => (
          <Box
            key={index}
            sx={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "8px",
              width: "100%",
              height: "150px", // Adjust height for each image box
              opacity: 0.3, // Adjust opacity as needed
            }}
          />
        ))}
      </main>

      {/* Overlay Component */}
      <Box sx={overlayStyles}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Font headingLevel={3} style={{ textAlign: "center" }}>
            Welcome to the PepsiCo’s Agriculture Experience
          </Font>
        </Box>

        <div className="login-card-item" style={{ width: "100%" }}>
          <Card
            border
            ellipsis={false}
            icon={null}
            title={null}
            primaryButtonProps={{
              size: "large",
              text: "Sign in",
              variant: "primary",
              onClick: switchToExternal,
            }}
            style={{ textAlign: "center", justifyContent: "center" }}
          >
            <Logo
              colorStyle="color"
              logoType="pepsicoSymbol"
              style={{ width: 64, height: 64 }}
            />
            <Font headingLevel={6} style={{ textAlign: "center" }}>
              Sign-in using PepsiCo OKTA credentials
            </Font>
          </Card>
        </div>
        <div className="login-card-item" style={{ width: "100%" }}>
          <Card
            border
            ellipsis={false}
            icon={null}
            title={null}
            primaryButtonProps={{
              size: "large",
              text: "Request access",
              variant: "primary",
              onClick: handleOpen,
            }}
            style={{
              textAlign: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Avatar size="medium" type="icon" />
            <Font headingLevel={6} style={{ textAlign: "center" }}>
              New to the site?
            </Font>
            <Font>Click below to request access to site</Font>
          </Card>
        </div>

        {/* <Box sx={cardStyles}>
          <Logo
            colorStyle="color"
            logoType="pepsicoSymbol"
            style={{ width: 64, height: 64 }}
          />
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                color: "#3A3A3A",
                fontFamily: "Inter",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "28px",
              }}
            >
              Sign-in using PepsiCo OKTA credentials
            </Typography>
            {/* <Typography
              sx={{
                color: "#3A3A3A",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Sign In Using Your PepsiCo External Partners Credentials
            </Typography> 
          </Box>
          <Button
            size="large"
            text="Sign in"
            variant="primary"
            onClick={switchToExternal}
          />
        </Box> */}
        {/* <Box sx={cardStyles}>
          <MuiAvatar sx={{ bgcolor: "#B2CAEA", width: 40, height: 40 }}>
            <PersonOutlinedIcon color="primary" />
          </MuiAvatar>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                color: "#3A3A3A",
                fontFamily: "Inter",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "28px",
              }}
            >
              New to the site ?
            </Typography>
            <Typography
              sx={{
                color: "#3A3A3A",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              click below to request access to site
            </Typography>
          </Box>
          <Button
            size="large"
            text="Request access"
            variant="primary"
            onClick={handleOpen}
          />
        </Box> */}
        <AccessRequestFormModal open={open} handleClose={handleClose} />
      </Box>

      <LoginFooter />
    </Box>
  );
}
