import React, { useEffect, useState } from "react";
import InternalPageLayout from "../components/InternalPages/InternalPageLayout";
import { BASE_URL } from "../constant/apiUrl";
import { getAPI } from "../API/ApiService";
import Loading from "../utils/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setFavourites } from "../slices/internalPageSlice";

export default function Favorites() {
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState(null);
  const [error, setError] = useState(null);
  const { favourites } = useSelector((store) => store.internalPage);

  console.log(favourites, "------dddddddd-------");

  const dispatch = useDispatch();
  // TODO : WE HAVE USE CONSTANT API URL, WILL IMPLEMENT ENV CONFIGURATION ONCE IT WILL CONFIGURE
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await getAPI(`${BASE_URL}/favorites/find-all-by-userid`)
          .then((response) => {
            console.log("response favourtieesss", response);
            dispatch(
              setFavourites({
                internalSubPages: [
                  response?.data?.response !== undefined
                    ? response?.data?.response
                    : [],
                ],
              })
            );
          })
          .catch((error) => {
            setError(error.message);
          });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <InternalPageLayout pageData={favourites || []} header={"My favorites"} />
  );
}
