import CarouselImage from "./CarouseImage"; // Adjust the path as necessary
import OverlayContent from "./OverlayContent"; // Adjust the path as necessary
import { Carousel, Font } from "pepsico-ds";
import "./HomePageCarousal.css";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import { setImage } from "../../slices/imageSlice";
import Loading from "../../utils/Loader";
import fetchAndCacheImage from "../../utils/fetchandCatchImages";
const HomePageCarousal = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  const [carouselData, setCarouselData] = useState([]);
  const token = localStorage.getItem("auth_token");
  const [loading, setLoading] = useState(false);
  const images = useSelector((state) => state.images.images);
  const dispatch = useDispatch();
  console.log(images, "----images------");

  const fetchImages = useCallback(
    async (id) => {
      if (images[id]) return images[id];
      return await fetchAndCacheImage(id, setImage, dispatch);
    },
    [dispatch]
  );

  const processSlotData = useCallback(
    async (fetched) => {
      const slotNumberMap = await Promise.all(
        fetched
          ?.filter((hero) => hero.slotNumber >= 1 && hero.slotNumber <= 5)
          ?.map(async (hero) => {
            const thumbnailIds = Array.isArray(hero?.thumbnailFileId)
              ? hero.thumbnailFileId
              : [hero?.thumbnailFileId].filter(Boolean);

            const images = await Promise.all(
              thumbnailIds.map(async (id) => {
                const url = await fetchImages(id);
                return url ? { preview: url } : null;
              })
            );

            return {
              ...hero,
              images: images.filter(Boolean),
            };
          })
      );

      const updatedSlotMap = slotNumberMap
        .filter(Boolean)
        .reduce((acc, hero) => {
          acc[hero.slotNumber] = hero;
          return acc;
        }, {});

      return Object.values(updatedSlotMap).filter(
        (item) => item.isEnabled === true
      );
    },
    [fetchImages]
  );

  useEffect(() => {
    if (!token) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getAPI(
          `${BASE_URL}/administrative_landing/getAllSlots`
        );
        const fetched = response.data;

        const filtered = await processSlotData(fetched);
        console.log(filtered, "----filtered----------");
        setCarouselData(filtered);
      } catch (error) {
        console.error("Failed to Load Carousal", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token, processSlotData]);

  console.log(carouselData, "-----carousalData---------");

  return (
    <div className="relative w-full  h-[420px]">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 w-full">
          <Loading />
        </div>
      )}

      {carouselData?.length > 0 ? (
        <Carousel
          style={{
            padding: 0,
            height: "100%",
            width: "100%",
          }}
          itemData={carouselData?.map((item) => (
            <div key={item?.id} className="relative">
              <CarouselImage src={item?.images} />
              <div className="z-10 w-full max-w-[493px] absolute inset-0 flex items-center justify-center px-6 md:px-0 md:left-[151px] md:items-start md:justify-start md:top-[75px]">
                <OverlayContent style={{ pointerEvents: "auto" }} data={item} />
              </div>
            </div>
          ))}
          isDark={isDarkTheme}
        />
      ) : (
        <div className="flex justify-center items-center m-auto  h-full">
          <Font
            size="small"
            variant="body"
            style={{ color: "var(--color-link-default)" }}
          >
            No Records Found
          </Font>
        </div>
      )}
    </div>
  );
};

export default HomePageCarousal;
