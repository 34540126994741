import React from "react";
import SustainabilitySubpage from "./SustainabilitySubpage";
import { useSelector } from "react-redux";
import { setCropDesignTools } from "../slices/sustainabilityPageSlice";
function CoverCropDesignTool() {
  const { cropDesignTools } = useSelector((store) => store.sustainable);
  return (
    <SustainabilitySubpage
      urlName={"Cover%20crop%20design%20tool"}
      header={"Cover crop design toolkit"}
      action={setCropDesignTools}
      globalState={cropDesignTools}
    />
  );
}

export default CoverCropDesignTool;
