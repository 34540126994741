import { createSlice } from "@reduxjs/toolkit";

const darkThemeSlice = createSlice({
  name: "darkTheme",
  initialState: localStorage.getItem("userToggledTheme") === "DARK", // Load persisted theme
  reducers: {
    toggleDarkTheme: (state) => {
      const newTheme = !state;
      localStorage.setItem("userToggledTheme", newTheme ? "DARK" : "LIGHT"); // Store toggle
      return newTheme;
    },
    setPreferenceTheme: (state, action) => {
      const userToggledTheme = localStorage.getItem("userToggledTheme");
      return userToggledTheme ? state : action.payload; // Respect user toggle
    },
  },
});

export const { toggleDarkTheme, setPreferenceTheme } = darkThemeSlice.actions;
export default darkThemeSlice.reducer;
