import React, { useEffect, useState } from "react";
import { Drawer, Typography, Box, Button, IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MessageBox from "./MessageBox";
import { darkTheme, lightTheme } from "./../../../theme/Theme";
import { useSelector } from "react-redux";
import useThemeRender from "./../../../theme/useThemeRender";
import TextInputField from "../../../utils/Fields/TextInputField";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import commonStyles from "../../PotatoProtection/LargeScreen/Landing/addVariteyDrawerStyle";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import adminValidations from "./adminValidations";
const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),

  email: yup
    .string()
    .email("Invalid email format")
    .required("Email address is required"),
  organization: yup.string().required("Organization is required"),

  role: yup.string().required("Role is required"),
});
const AdminDrawer = ({
  isDrawerOpen,
  closeDrawer,
  drawerMode,
  currentSME,
  onAddSME,
  onEditSME,
  onRemoveSME,
}) => {
  AdminDrawer.propTypes = {
    isDrawerOpen: PropTypes.bool.isRequired, // Boolean to indicate if the drawer is open
    closeDrawer: PropTypes.func.isRequired, // Function to close the drawer
    drawerMode: PropTypes.string, // String indicating the mode of the drawer (optional)
    currentSME: PropTypes.object, // Object for current SME details (optional)
    onAddSME: PropTypes.func.isRequired, // Function to handle adding an SME
    onEditSME: PropTypes.func.isRequired, // Function to handle editing an SME
    onRemoveSME: PropTypes.func.isRequired, // Function to handle removing an SME
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = useState(false);

  const isDarkTheme = useSelector((state) => state.theme);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    getValues,
    clearErrors,
    setError,
  } = useForm({ resolver: yupResolver(validationSchema) });
  const { errors } = formState;

  const onFieldChange = (field, value) => {
    setValue(field, value); // Update the field value
    const validationErrors = adminValidations(
      getValues("name"),
      getValues("organization"),
      getValues("role"),
      getValues("email")
    );

    if (validationErrors[field]) {
      setError(field, { type: "manual", message: validationErrors[field] });
    } else {
      clearErrors(field); // Clear the error if validation passes
    }
  };
  const isEditMode = drawerMode === "edit";
  useEffect(() => {
    if (drawerMode === "edit" && currentSME) {
      reset({
        name: currentSME.name || "",
        role: currentSME.role || "",
        email: currentSME.email || "",
        organization: currentSME.organization || "",
        phone: currentSME.phone || "",
        linkedinLink: currentSME.linkedinLink || "",
      });
    } else {
      reset({
        name: "",
        role: "",
        organization: "",
        phone: "",
        email: "",
        linkedinLink: "",
      });
    }
  }, [drawerMode, currentSME, reset]);

  const handleCancel = () => {
    reset();
    closeDrawer();
  };
  const handleRemove = () => {
    if (currentSME?.id) {
      onRemoveSME(currentSME.id);
    }
    setOpenDialog(false);
    closeDrawer();
    reset();
  };
  const onSumbit = (data) => {
    if (drawerMode === "add") {
      onAddSME(data);
    } else if (drawerMode === "edit") {
      onEditSME({ ...data, id: currentSME?.id });
    }
  };

  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={isDrawerOpen}
      onClose={closeDrawer}
      PaperProps={{
        sx: {
          height: isMobile ? "80%" : "100%",
          width: isMobile ? "100%" : 436,
          padding: "16px 24px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer3
            : lightTheme.backgroundColorLayer3,
        },
      }}
      ModalProps={{
        BackdropProps: {
          sx: {
            backgroundColor: isDarkTheme
              ? darkTheme.drawerOverlayColorLayer1
              : lightTheme.drawerOverlayColorLayer1,
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          fontWeight: 700,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            lineHeight: "20px",
            color: isDarkTheme && darkTheme.textColorLayer1,
          }}
        >
          {isEditMode
            ? "Edit subject matter expert"
            : "Add subject matter expert"}
        </Typography>
        <IconButton
          onClick={handleCancel}
          sx={{
            fontWeight: 700,
            lineHeight: "20px",
            color: useThemeRender("iconColor1"),
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <form
        className="w-full flex flex-col gap-3 h-full"
        onSubmit={handleSubmit(onSumbit)}
      >
        <Box
          sx={{
            ...commonStyles.container,
            flex: 1,
            padding: "var(--Spacing-spacing-16, 16px)",
            backgroundColor: isDarkTheme
              ? darkTheme.backgroundColorLayer1
              : lightTheme.backgroundColorLayer1,
            overflowY: "auto",
          }}
        >
          <TextInputField
            label="Name"
            name="name"
            errors={errors}
            width1="100%"
            register={register}
            width="100%"
            required={true}
            type="text"
            onChange={(e) => onFieldChange("name", e.target.value)}
          />
          <TextInputField
            label="Organisation"
            name="organization"
            errors={errors}
            width1="100%"
            register={register}
            width="100%"
            required={true}
            type="text"
            onChange={(e) => onFieldChange("organization", e.target.value)}
          />
          <TextInputField
            label="Role"
            name="role"
            errors={errors}
            width1="100%"
            register={register}
            width="100%"
            required={true}
            type="text"
            onChange={(e) => onFieldChange("role", e.target.value)}
          />
          <TextInputField
            label="Email"
            name="email"
            errors={errors}
            width1="100%"
            register={register}
            width="100%"
            required={true}
            type="email"
            onChange={(e) => onFieldChange("email", e.target.value)}
          />
          <TextInputField
            label="Phone"
            name="phone"
            errors={errors}
            width1="100%"
            register={register}
            width="100%"
            type="text"
          />
          <TextInputField
            label="Linkedin link"
            name="linkedinLink"
            errors={errors}
            width1="100%"
            register={register}
            width="100%"
            type="text"
          />
          {isEditMode && (
            <Box sx={{ display: "flex" }}>
              <Button
                variant="outlined"
                sx={{
                  display: "flex",
                  borderRadius: "24px",
                  justifyContent: "flex-start",
                  mb: 2,
                  fontWeight: 700,
                  lineHeight: "20px",
                  textTransform: "none",
                }}
                endIcon={<DeleteOutlineOutlinedIcon />}
                onClick={handleOpenDialog}
              >
                Remove SME
              </Button>
              <MessageBox
                open={openDialog}
                message="Are you sure you want to remove this SME?"
                buttonOneText="Cancel"
                buttonTwoText="Remove"
                handleButtonOneClick={handleCloseDialog}
                handleButtonTwoClick={handleRemove}
              />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            height: "64px",
            padding: "16px 24px",
            justifyContent: "space-between",
            alignItems: "center",
            flexShrink: 0,
            alignSelf: "stretch",
            backgroundColor: useThemeRender("backgroundColorLayer3"),
            boxShadow: "0px 1px 0px 0px #DFDFDF inset",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flex: "1 0 0",
              gap: 4,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: "#005CBC",
                color: "#005CBC",
                fontWeight: 700,
                borderRadius: "24px",
                textTransform: "none",
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: "#005CBC",
                fontWeight: 700,
                borderRadius: "24px",
                textTransform: "none",
              }}
              type="submit"
            >
              {isEditMode ? "Save SME" : "Add SME"}
            </Button>
          </Box>
        </Box>
      </form>
    </Drawer>
  );
};

export default AdminDrawer;
