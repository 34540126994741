import React from "react";
import InternalPageLayout from "../components/InternalPages/InternalPageLayout";
import { BASE_URL } from "../constant/apiUrl";

import useFetchInternal from "../hooks/useFetchInternal";
import Loading from "../utils/Loader";
import { useSelector } from "react-redux";
import { setGlobalTrials } from "../slices/internalPageSlice";
export default function GlobalTrials() {
  const { favoriate, globalTrials } = useSelector(
    (store) => store.internalPage
  );
  const { loading, pageData } = useFetchInternal(
    `${BASE_URL}/internal-pages/page-info/find-by-page-name?pageName=Global%20Trials&userId=1`,
    setGlobalTrials,
    globalTrials
  );

  return loading ? (
    <Loading />
  ) : (
    <InternalPageLayout pageData={pageData} header={"Global trial"} />
  );
}
