import React, { useEffect, useState } from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Button,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import commonStyles from "../../../PotatoProtection/LargeScreen/Landing/addVariteyDrawerStyle";
import {
  setEditSlider,
  setFetchPending,
} from "../../../../slices/invitationSlice";
import useLookupData from "../../../../hooks/useLookupData";
import AutoCompleteField from "../../../../utils/Fields/AutoComplete";
import TextInputField from "../../../../utils/Fields/TextInputField";
import Loading from "../../../../utils/Loader";
import { BASE_URL } from "../../../../constant/apiUrl";
import axios from "axios";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../utils/Toast/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import editrequestValidations from "./editrequestValidations";
const validationSchema = yup.object().shape({
  firstName: yup.string().required("Name is required"),

  email: yup
    .string()
    .email("Invalid email format")
    .required("Email address is required"),
  company: yup.string().required("Organization is required"),

  lastName: yup.string().required("Role is required"),
});
function EditRequestSlider({ actionData }) {
  const isDarkTheme = useSelector((state) => state.theme);
  const { editSlider } = useSelector((store) => store.invitation);
  const dispatch = useDispatch();
  const { fetch } = useSelector((state) => state.invitation);
  const [autoComplete, setAutoComplete] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    getValues,
    clearErrors,
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { errors } = formState;
  const { countryFilter } = useSelector((store) => store.internalFilter);
  const onFieldChange = (field, value) => {
    setValue(field, value); // Update the field value
    const validationErrors = editrequestValidations(
      getValues("firstName"),
      getValues("lastName"),
      getValues("email"),
      getValues("company")
    );

    if (validationErrors[field]) {
      setError(field, { type: "manual", message: validationErrors[field] });
    } else {
      clearErrors(field); // Clear the error if validation passes
    }
  };

  // Auto-populate form with actionData values
  useEffect(() => {
    if (actionData) {
      reset({
        firstName: actionData.firstName || "",
        lastName: actionData.lastName || "",
        email: actionData.email || "",
        company: actionData.company || "",
        country: actionData.country || "",
        sponsorEmail: actionData.sponsorEmail || "",
      });

      // Set the country auto-complete field (if countryData is available)
      console.log(countryFilter, actionData, "-------itemsss---------");

      const country = countryFilter?.find(
        (item) => item.id === actionData.countryId
      );
      console.log(country, "-----autoComplete-----");

      if (country) {
        setAutoComplete(country.id);
        setValue("country", country); // Populate country in the form
      }
    } else {
      reset({
        firstName: "",
        lastName: "",
        company: "",
        country: "",
        email: "",
        sponsorEmail: "",
      });
    }
  }, [actionData, countryFilter, reset, setValue, editSlider]);

  const token = localStorage.getItem("auth_token");
  const onSubmit = async (data) => {
    try {
      const newData = {
        ...data,
        countryId: autoComplete === null ? actionData.countryId : autoComplete,
      };
      console.log(newData, autoComplete, "-datat----");
      const response = await axios.put(
        `${BASE_URL}/admin/access-request-form/update/${actionData?.id}`,
        newData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response, "---error---");

      if (response?.status === 200) {
        showSuccessToast(
          "You have successfully updated the request information "
        );
        dispatch(setFetchPending(!fetch));
        dispatch(setEditSlider(false));
      }
    } catch (e) {
      console.log(e, "error");

      showErrorToast("An error ocurred please try again ");
    }
  };

  const handleClose = () => {
    dispatch(setEditSlider(false));
  };

  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={editSlider}
      PaperProps={{
        sx: {
          height: isMobile ? "90%" : "100%",
          width: isMobile ? "100%" : 436,
          padding: "16px 24px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer3
            : lightTheme.backgroundColorLayer3,
        },
      }}
      ModalProps={{
        BackdropProps: {
          sx: {
            backgroundColor: isDarkTheme
              ? darkTheme.drawerOverlayColorLayer1
              : lightTheme.drawerOverlayColorLayer1,
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "var(--Spacing-spacing-8, 8px)",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: isDarkTheme && darkTheme.textColorLayer1,
            fontSize: "var(--Font-Size-H6, 18px)",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-H6, 28px)",
          }}
        >
          Edit request information
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon
            style={{
              color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
            }}
          />
        </IconButton>
      </Box>

      <form
        className="w-full flex flex-col gap-3 h-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            ...commonStyles.container,
            flex: 1,
            padding: "var(--Spacing-spacing-16, 16px)",
            backgroundColor: isDarkTheme
              ? darkTheme.backgroundColorLayer1
              : lightTheme.backgroundColorLayer1,
            overflowY: "auto",
          }}
        >
          {/* Variety Input */}
          <TextInputField
            label="First name"
            name="firstName"
            required={true}
            errors={errors}
            width1="100%"
            register={register}
            width="100%"
            onChange={(e) => onFieldChange("firstName", e.target.value)}
          />

          <TextInputField
            label="Last name"
            name="lastName"
            required={true}
            errors={errors}
            width1="100%"
            register={register}
            width="100%"
            onChange={(e) => onFieldChange("lastName", e.target.value)}
          />
          <TextInputField
            label="Email"
            name="email"
            errors={errors}
            required={true}
            width1="100%"
            register={register}
            width="100%"
            type="email"
            onChange={(e) => onFieldChange("email", e.target.value)}
          />
          <TextInputField
            label="company/organistation"
            name="company"
            errors={errors}
            required={true}
            width1="100%"
            register={register}
            width="100%"
            onChange={(e) => onFieldChange("company", e.target.value)}
          />

          <AutoCompleteField
            key={autoComplete}
            label="Country"
            options={countryFilter?.map((item) => ({
              label: item.name,
              id: item.id,
            }))} // Ensure `options` contain both `label` and `id`.
            getOptionLabel={(option) => option.label || ""} // Ensure the displayed label is correct.
            name="country"
            placeholder="Select"
            isMobile={false}
            errors={errors}
            register={register}
            onChange={(newValue) => {
              console.log(newValue, "------item----");

              setAutoComplete(newValue.id); // Set the country ID
            }}
            value={
              autoComplete
                ? {
                    id: autoComplete,
                    label: countryFilter.find(
                      (item) => item.id === autoComplete
                    )?.name,
                  }
                : null
            } // Auto-select the country based on the state
            width1={"338px"}
            width="100%"
          />

          <TextInputField
            label="sponsor's email"
            name="sponsorEmail"
            errors={errors}
            width1="100%"
            register={register}
            width="100%"
            type="email"
          />
        </Box>

        {/* Button Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "16px",
            backgroundColor: isDarkTheme
              ? darkTheme.backgroundColorLayer3
              : lightTheme.backgroundColorLayer3,
            boxShadow: "0px 1px 0px 0px #DFDFDF inset",
            p: "24px",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              ...commonStyles.buttonBase,
              ...commonStyles.outlinedButton,
            }}
            type="button"
            onClick={handleClose}
          >
            <Typography sx={commonStyles.typographyButton}>Cancel</Typography>
          </Button>

          <Button
            variant="contained"
            sx={{
              ...commonStyles.buttonBase,
              ...commonStyles.containedButton,
            }}
            type="submit"
          >
            <Typography sx={commonStyles.typographyButton}>Save</Typography>
          </Button>
        </Box>
      </form>
    </Drawer>
  );
}

export default React.memo(EditRequestSlider);
