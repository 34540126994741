import React from "react";
import SustainabilitySubpage from "./SustainabilitySubpage";
import { useSelector } from "react-redux";
import { setRegenerative } from "../slices/sustainabilityPageSlice";
function RegnerativeAgricultureProgram() {
  const { regenerative } = useSelector((store) => store.sustainable);
  return (
    <SustainabilitySubpage
      urlName={"Regenerative%20Agriculture"}
      header={"Regenerative agriculture"}
      action={setRegenerative}
      globalState={regenerative}
    />
  );
}

export default RegnerativeAgricultureProgram;
