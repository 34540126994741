import React, { useState } from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import { Icon } from "pepsico-ds";
import { useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import PreferencesDrawer from "./PreferencesDrawer";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import { useOktaAuth } from "@okta/okta-react";

const DropdownMenu = () => {
  const [dropdownAnchor, setDropdownAnchor] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);
  const isDarkTheme = useSelector((state) => state.theme);
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();

  const handleClick = (event) => {
    setDropdownAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedMenuItem(0);
    setDropdownAnchor(null);
  };

  const handlePreferencesClick = () => {
    setSelectedMenuItem(1);
    setDrawerOpen(true);
    handleClose();
  };

  const handleFavoriteRedirect = () => {
    handleClose();
    setSelectedMenuItem(2);
    navigate("/favorites");
  };
  const clearOktaCookies = () => {
    const cookies = document.cookie.split(";");

    cookies.forEach((cookie) => {
      const cookieName = cookie.split("=")[0].trim();
      if (cookieName.startsWith("okta-oauth")) {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
    });
  };

  const logout = async () => {
    navigate("/logout");
  };

  return (
    <>
      <Icon
        alt="Dropdown"
        icon="arrow_drop_down"
        size="medium"
        onClick={handleClick}
        style={{
          color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
        }}
      />

      <Menu
        id="dropdown-menu"
        anchorEl={dropdownAnchor}
        open={Boolean(dropdownAnchor)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          marginTop: "20px",
          borderRadius: 4,
          "& .MuiPaper-root": {
            backgroundColor: isDarkTheme
              ? darkTheme.backgroundColorLayer3
              : lightTheme.backgroundColorLayer3,
            padding: "10px",
          },
        }}
      >
        <MenuItem
          onClick={handlePreferencesClick}
          selected={selectedMenuItem === 1 ? true : false}
          sx={{
            color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
          }}
          key={1}
        >
          Preferences
        </MenuItem>
        <MenuItem
          onClick={handleFavoriteRedirect}
          selected={selectedMenuItem === 2 ? true : false}
          sx={{
            color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
          }}
          key={2}
        >
          My Favorites
        </MenuItem>
        <MenuItem
          onClick={logout}
          sx={{
            color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
          }}
          key={3}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            Signout
            <LoginIcon fontSize="small" />
          </Box>
        </MenuItem>
      </Menu>

      <PreferencesDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        setDrawerOpen={setDrawerOpen}
      />
    </>
  );
};

export default DropdownMenu;
