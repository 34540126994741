// redux/imageSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  images: {},
};

const imageSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    setImage(state, action) {
      const { id, url } = action.payload;
      state.images[id] = url;
    },
    deleteImage(state, action) {
      const { id } = action.payload;
      delete state.images[id]; // Remove the image with the specified ID
    },
    resetImage(state, action) {
      state.images = {};
    }
  },
});

export const { setImage, deleteImage, resetImage } = imageSlice.actions;
export default imageSlice.reducer;
