import React from "react";
import { Box, Button, Typography } from "@mui/material";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { darkTheme } from "../../../../theme/Theme";
import PropTypes from "prop-types";
const DropZone = ({ getRootProps, getInputProps, isDarkTheme }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            color: isDarkTheme && darkTheme.textColorLayer1,
            fontFeatureSettings: "'liga' off, 'clig' off", // Disable ligatures and contextual ligatures
            fontFamily: "var(--Typography-Font-Family-Body, Inter)", // Font family with fallback
            fontSize: "var(--Font-Size-XSmall, 12px)", // Font size with fallback
            fontStyle: "normal", // Normal font style
            fontWeight: 400, // Font weight
            lineHeight: "var(--Line-Height-XSmall, 18px)", // Line height with fallback
          }}
        >
          Upload Image
        </Typography>
        <Typography
          sx={{
            color: isDarkTheme && darkTheme.textColorLayer1,
            fontFeatureSettings: "'liga' off, 'clig' off", // Disable ligatures and contextual ligatures
            fontFamily: "var(--Typography-Font-Family-Body, Inter)", // Font family with fallback
            fontSize: "var(--Font-Size-XSmall, 12px)", // Font size with fallback
            fontStyle: "normal", // Normal font style
            fontWeight: 400, // Font weight
            lineHeight: "var(--Line-Height-XSmall, 18px)", // Line height with fallback
          }}
        >
          Required *
        </Typography>
      </Box>
      <Box
        {...getRootProps()}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
          border: "1px dashed #D7E5F8",
          width: "100%",
          height: "200px",
          gap: "8px",
          cursor: "pointer",
          backgroundColor: isDarkTheme
            ? darkTheme.insideCardBackgroundColorLayer4
            : "#EFF5FC",
        }}
      >
        <input {...getInputProps()} />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <path
            d="M25.3333 6.66667V25.3333H6.66667V6.66667H25.3333ZM25.3333 4H6.66667C5.2 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333V6.66667C28 5.2 26.8 4 25.3333 4ZM18.8533 15.8133L14.8533 20.9733L12 17.52L8 22.6667H24L18.8533 15.8133Z"
            fill={isDarkTheme ? "white" : "#3A3A3A"}
          />
        </svg>

        <Typography
          variant="body1"
          sx={{
            textAlign: { xs: "center" },
            width: { xs: "80%" },
            color: isDarkTheme && darkTheme.textColorLayer1,
          }}
        >
          Drag and drop your image here. Make sure size is: 1600 pixels X 400
          pixels (only .jpg or .png files)
        </Typography>
        <Button
          variant="text"
          sx={{
            mt: 2,
            borderRadius: "24px",
            border: "1px solid #005CBC",
            px: 2,
          }}
          startIcon={<UploadOutlinedIcon />}
        >
          <Typography
            variant="body2"
            sx={{
              color: "#005CBC",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 700,
              lineHeight: "18px",
              textTransform: "none",
            }}
          >
            Upload image
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default DropZone;
DropZone.propTypes = {
  getRootProps: PropTypes.func, // Required function for root props
  getInputProps: PropTypes.func, // Required function for input props
  isDarkTheme: PropTypes.bool, // Required boolean for dark theme
};
