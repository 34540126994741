import React, { useMemo } from "react";
import { styled, Box, Typography, Button, useMediaQuery } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  commonButtonStyle,
  commonTextStyle,
} from "../../Landing/potatoprotectstyle";
import styles from "../Style/varietyDetailStyle";
import {
  cardContainerStyles,
  headerBoxStyles,
  headerTextStyles,
  fieldContainerStyles,
} from "./varietyCardStyle";

import CountryItem from "./CountryItem";
import { useDispatch, useSelector } from "react-redux";
import {
  openCountryDrawer,
  setCountryEdit,
} from "../../../../../slices/potatoProtectionSlice";
import FilesList from "./FilesList";
import { darkTheme } from "../../../../../theme/Theme";
import DateFormat from "../../../../../utils/DateFormat";
import { formatDate } from "../../../../../utils/function";
export default function CountryDetails({ data }) {
  const isDarkTheme = useSelector((state) => state.theme);
  const StyledBox = styled(Box)({
    ...cardContainerStyles,
    backgroundColor: isDarkTheme
      ? darkTheme.backgroundColorLayer3
      : "var(--Solid-White, #FFF)",
    boxShadow: isDarkTheme ? "none" : cardContainerStyles.boxShadow,
  });
  const isMobile = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const HandleOpen = () => {
    dispatch(openCountryDrawer(true));
  };
  const HandleEdit = () => {
    dispatch(setCountryEdit(true));
    dispatch(openCountryDrawer(true));
  };

  console.log(data, "-------CountryFile----");

  const combinedData = useMemo(() => {
    if (!data) return [];
    const dateKeys = [
      "pbrRequestDate",
      "pbrGrantDate",
      "registrationDate",
      "pvpRequestDate",
      "patentFileDate",
      "patentGrantDate",
      "pvpGrantDate",
    ];
    return data.map((item) => {
      const formattedItem = { ...item };
      Object.keys(item).forEach((key) => {
        if (dateKeys.includes(key)) {
          formattedItem[key] = formatDate(item[key]); // Format the date
        }
      });
      return formattedItem; // Return the modified object
    });
  }, [data]);

  return (
    <StyledBox>
      <Box sx={headerBoxStyles}>
        <Typography
          sx={
            isMobile
              ? {
                  color: isDarkTheme
                    ? darkTheme.textColorLayer2
                    : "var(--Tokens-Text-Primary, #3A3A3A)", // Default text color
                  fontFamily: "var(--Typography-Font-Family-Body, Inter)", // Font family, default to Inter
                  fontSize: "var(--Font-Size-Body, 16px)", // Font size, default to 16px
                  fontStyle: "normal", // Font style (normal)
                  fontWeight: 700, // Font weight (bold)
                  lineHeight: "var(--Line-Height-Body, 22px)",
                }
              : {
                  ...headerTextStyles,
                  color: isDarkTheme
                    ? darkTheme.textColorLayer2
                    : headerTextStyles.color,
                }
          }
        >
          Country Details
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: {
              xs: "4px", // Gap for small screens (mobile)
              sm: "12px", // Gap for larger screens
            },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            sx={
              isMobile
                ? {
                    display: "flex",
                    height: "24px",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "24px", // Assuming the custom value for corner radius
                    backgroundColor: "#005CBC", // Primary button color
                    padding: 1,
                    gap: "2px",
                  }
                : commonButtonStyle
            }
            onClick={HandleOpen}
          >
            <Typography
              sx={
                isMobile
                  ? {
                      color: "#FFF", // Equivalent to var(--Colors-White, #FFF)
                      fontFamily: "Inter", // Setting the font to Inter
                      fontSize: "12px", // Font size 12px
                      fontStyle: "normal", // Normal font style
                      fontWeight: 700, // Bold font weight
                      lineHeight: "133%",
                      textTransform: "none",
                    }
                  : commonTextStyle
              }
            >
              Add country
            </Typography>
            <AddCircleOutlineIcon
              sx={
                isMobile
                  ? { height: "16px", width: "16px", fill: "white" }
                  : { fill: "white" }
              }
            />
          </Button>
          <Button
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
              textTransform: "none",
            }}
            onClick={HandleEdit}
          >
            <EditOutlinedIcon sx={{ fill: "#005CBC" }} />
            <Typography sx={styles.backText}>Edit</Typography>
          </Button>
        </Box>
      </Box>

      {(combinedData || [])?.map((item) => {
        return (
          <Box
            sx={
              isDarkTheme
                ? {
                    ...fieldContainerStyles,
                    backgroundColor: darkTheme.backgroundColorLayer1,
                  }
                : fieldContainerStyles
            }
            key={item.id}
          >
            <div className="flex flex-col gap-3 w-full p-3">
              <Box sx={{ paddingX: "16px" }}>
                <Typography
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.textColorLayer2
                      : "var(--Tokens-Text-Primary, #3A3A3A)",
                    fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
                    fontSize: "var(--Font-Size-H6, 18px)",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "var(--Line-Height-H6, 28px)",
                  }}
                >
                  {item.country}
                </Typography>
              </Box>

              <CountryItem dataLookup={item} />
              <FilesList files={item?.newFiles} />
            </div>
          </Box>
        );
      })}
    </StyledBox>
  );
}
