import React from "react";
import { Box, useMediaQuery, Typography } from "@mui/material";
// import LoginBanner from "../components/SignIn/LoginBanner";
//import Footer from "../components/Footer/Footer";
import LoginCards from "../components/LoginComponent/LoginCards";
import MobileSign from "../components/LoginComponent/MobileSign";
import LoginHeader from "../components/LoginComponent/LoginHeader";
import Footer from "../components/Footer";
import LoginFooter from "../components/LoginComponent/LoginFooter";
import LoginBanner from "../components/LoginComponent/LoginBanner";


//import MobileView from "../components/SignIn/MobileView";

const Login = () => {
  const isSmallScreen = useMediaQuery("(max-width:899px)");
  return (
    <>
      {isSmallScreen ? (
        <MobileSign /> // <MobileView />
      ) : (
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#EFF5FC",
            flexShrink: 0,
            justifyContent: "center",
            gap: "24px",
          }}
        >
          {/* Header */}
          <LoginHeader />

          <main
            style={{ flex: 1 }}
            className="flex flex-row justify-center items-center gap-4"
          >
            <LoginBanner />
            <LoginCards />
          </main>
          <LoginFooter />
        </Box>
      )}
    </>
  );
};

export default Login;
