import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Box,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { darkTheme, lightTheme } from "../../theme/Theme";
import useThemeRender from "../../theme/useThemeRender";
import { ToastContainer } from "react-toastify";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import { setDisableNextButton } from "../../slices/adminPageSlice";
import { useDispatch } from "react-redux";
import { getActiveItems } from "../../utils/function";

const quillModules = {
  // toolbar: [
  //   ["bold", "italic", "underline"],
  //   [{ align: "" }, { align: "center" }, { align: "right" }],
  //   [{ list: "ordered" }, { list: "bullet" }],
  // ],
  toolbar: false
};

const optimizedStyles = (isDarkTheme) => `
 .editor-container {
    position: relative;
    width:100%;
  }

  .editor-container .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    border: none;
    box-shadow: none; 
  }

  .editor-container .ql-container {
    height: 200px; 
    width: 100%;
    border: 1px solid #C0C0C0; 
    border-radius:4px;
    box-shadow: none;
    outline: none; 
    color: ${
      isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1
    };
  }
.editor-container .ql-container .ql-editor.ql-blank::before {
    color: ${
      isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4
    };
  }
  .editor-container .ql-container:before {
  content: '';
  position: absolute;
  top: -1px; 
  left: 0;
  right: 0;
  height: 1px;
  background-color: #C0C0C0;
  border-radius: 4px;
}
`;

const StepFourForm = ({ internalData, setInternalData }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [disclaimerData, setDisclaimerData] = useState();
  const [languageData, setLanguageData] = useState(null);
  const dispatch = useDispatch();
  const handleChange = (field) => (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
        let fieldValue = null;
        if (field === 'disclaimerId') {
          fieldValue = value;
        }  else {
          fieldValue = value === "true" ? true : false;
        }
    setInternalData((prev) => ({
      ...prev,
      [field]: fieldValue,
    }));
  };

  const handleLanguageChange = (field) => (event) => {
    const value = event.target.value.id
    setInternalData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const [selectedLanguageData , setSelectedLanguageData] = useState()
  useEffect(()=>{
      const selectedData = languageData?.find(item => item?.id === internalData.languageId) 
      setSelectedLanguageData(selectedData)
  },[internalData.languageId,languageData])
  const handleQuillChange = (field) => (value) => {
    setInternalData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const isDarkTheme = useSelector((state) => state.theme);
  if (typeof window !== "undefined") {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = optimizedStyles(isDarkTheme);
    document.head.appendChild(styleSheet);
  }
  const baseBoxStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: "8px",
    background: isDarkTheme
      ? darkTheme.cardBackgroundColorLayer1
      : lightTheme.cardBackgroundColorLayer1,

    width: "100%",
  };

  const renderEditor = (value, onChange, placeholder) => (
    <Box className="editor-container">
      <ReactQuill
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        modules={quillModules}
        style={{ height: "100%", width: "100%" }}
      />
    </Box>
  );

  useEffect(() => {
    const fetchDisclaimerData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=DISCLAIMER`
        );
        if (response?.status == 200) {
          const activeItems = getActiveItems(response?.data);
          setDisclaimerData(activeItems);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchLanguageData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=LANGUAGE`
        );
        if (response?.status == 200) {
          const activeItems = getActiveItems(response?.data);
          setLanguageData(activeItems);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchLanguageData();
    fetchDisclaimerData();
  }, []);
  useEffect(() => {
    dispatch(setDisableNextButton(true));
  }, []);
  return (
    <Box
      sx={{
        ...baseBoxStyle,
        boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
      }}
    >
      <Typography
        sx={{
          color: useThemeRender("textColorLayer1"),
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "32px",
          mb: 4,
        }}
      >
        Step 4: Summary
      </Typography>
      {/* Document section */}
      <Box sx={baseBoxStyle}>
        <Typography
          sx={{
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : lightTheme.textColorLayer1,
            mb: 1,
          }}
        >
          Document Notes
        </Typography>
        {renderEditor(
          internalData.notes,
          handleQuillChange("notes"),
          "Type here"
        )}
      </Box>

      {/* Summary section */}
      <Box sx={baseBoxStyle}>
        <Typography
          sx={{
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : lightTheme.textColorLayer1,
            mb: 1,
          }}
        >
          Summary
        </Typography>
        {renderEditor(
          internalData.summary,
          handleQuillChange("summary"),
          "Type here"
        )}
      </Box>

      <Box sx={baseBoxStyle}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: { xs: "100%", md: "40%" },
          }}
        >
          <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
            Disclaimer
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ marginBottom: 1, fontSize: "14px", color: "#616161" }}
          >
            *Required
          </Typography>
        </Box>

        <FormControl sx={{ width: { xs: "100%", md: "40%" } }}>
          <Select
            id="select-option"
            value={internalData.disclaimerId}
            onChange={handleChange("disclaimerId")}
            MenuProps={{
              PaperProps: {
                sx: {
                  background: isDarkTheme
                    ? darkTheme.backgroundColorLayer3
                    : lightTheme.backgroundColorLayer3,
                  "& .MuiMenuItem-root": {
                    color: isDarkTheme
                      ? darkTheme.textColorLayer1
                      : lightTheme.textColorLayer1,
                    "&:hover": {
                      backgroundColor: isDarkTheme
                        ? darkTheme.dropdownHoverColorLayer3
                        : lightTheme.dropdownHoverColorLayer3,
                    },
                  },
                },
              },
            }}
            sx={{
              backgroundColor: isDarkTheme
                ? darkTheme.backgroundColorLayer2
                : lightTheme.backgroundColorLayer2,
              height: 40,
              "& .MuiSelect-icon": {
                color: isDarkTheme
                  ? darkTheme.iconColor1
                  : lightTheme.iconColor1,
              },
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
              border: "1px solid",
              borderColor: isDarkTheme
                ? darkTheme.borderColorLayer2
                : lightTheme.borderColorLayer2,
              "&:hover": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
            }}
            displayEmpty
          >
            <MenuItem value="">Select one</MenuItem>
            {disclaimerData?.map((item) => (
              <MenuItem value={item?.id}>{item?.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box display="flex" alignItems="center" mt={1}>
          <Tooltip title="Note: Select an option to display the disclaimer details">
            <IconButton>
              <InfoOutlinedIcon
                sx={{
                  color: isDarkTheme
                    ? darkTheme.iconColor2
                    : lightTheme.iconColor2,
                }}
              />
            </IconButton>
          </Tooltip>
          <Typography
            variant="caption"
            sx={{
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            }}
          >
            Note: Select an option to display the disclaimer details
          </Typography>
        </Box>
      </Box>

      {/* Translation section */}
      <Box sx={baseBoxStyle}>
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <Typography
            sx={{
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
              mb: 1,
            }}
          >
            Language Translation
          </Typography>
          <RadioGroup
            column
            value={internalData.isTranslated}
            onChange={handleChange("isTranslated")}
          >
            <FormControlLabel
              value={true}
              control={
                <Radio
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.radioButtonLayer1
                      : lightTheme.radioButtonLayer1,
                  }}
                />
              }
              label="Yes"
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
              }}
            />
            <FormControlLabel
              value={false}
              control={
                <Radio
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.radioButtonLayer1
                      : lightTheme.radioButtonLayer1,
                  }}
                />
              }
              label="No"
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
              }}
            />
          </RadioGroup>
        </FormControl>

        {internalData?.isTranslated && (
          <>
            <FormControl sx={{ mb: 2, width: { xs: "100%", md: "40%" } }}>
              <Typography
                sx={{
                  color: isDarkTheme
                    ? darkTheme.textColorLayer1
                    : lightTheme.textColorLayer1,
                  mb: 1,
                }}
              >
                Select Language
              </Typography>
              <Select
                id="select-language"
                value={selectedLanguageData || ''}
                onChange={handleLanguageChange("languageId")}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      background: isDarkTheme
                        ? darkTheme.backgroundColorLayer3
                        : lightTheme.backgroundColorLayer3,
                      "& .MuiMenuItem-root": {
                        color: isDarkTheme
                          ? darkTheme.textColorLayer1
                          : lightTheme.textColorLayer1,
                        "&:hover": {
                          backgroundColor: isDarkTheme
                            ? darkTheme.dropdownHoverColorLayer3
                            : lightTheme.dropdownHoverColorLayer3,
                        },
                      },
                    },
                  },
                }}
                sx={{
                  backgroundColor: isDarkTheme
                    ? darkTheme.backgroundColorLayer2
                    : lightTheme.backgroundColorLayer2,
                  height: 40,
                  "& .MuiSelect-icon": {
                    color: isDarkTheme
                      ? darkTheme.iconColor1
                      : lightTheme.iconColor1,
                  },
                  color: isDarkTheme
                    ? darkTheme.textColorLayer1
                    : lightTheme.textColorLayer1,
                  border: "1px solid",
                  borderColor: isDarkTheme
                    ? darkTheme.borderColorLayer2
                    : lightTheme.borderColorLayer2,
                  "&:hover": {
                    borderColor: isDarkTheme
                      ? darkTheme.borderColorLayer2
                      : lightTheme.borderColorLayer2,
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: isDarkTheme
                      ? darkTheme.borderColorLayer2
                      : lightTheme.borderColorLayer2,
                  },
                }}
                displayEmpty
              >
                <MenuItem value="">Select</MenuItem>
                {languageData?.map((item) => (
                  <MenuItem value={item}>{item?.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
                mb: 1,
              }}
            >
              Translation
            </Typography>
            <Box className="editor-container">
              {renderEditor(
                internalData.translatedText,
                handleQuillChange("translatedText"),
                "Type here"
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default StepFourForm;
