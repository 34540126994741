import React, { useEffect } from "react";
import { Box, Typography, Avatar as MuiAvatar } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Avatar, Button, Card, Font, Logo } from "pepsico-ds";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector } from "react-redux";
import { setConfig } from "../../slices/configSlice";
import AccessRequestFormModal from "./AccessRequestForm";

export default function LoginCards() {
  const { authState, oktaAuth } = useOktaAuth();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { External } = useSelector((store) => store.config);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const switchToExternal = () => {
    dispatch(setConfig(false));
  };

  useEffect(() => {
    if (authState && External !== null) {
      login();
    }
  }, [External]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 591,
        height: "auto",
        gap: "24px", // using CSS variable if declared globally
        padding: "24px", // using CSS variable if declared globally
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Invitation Text Section */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Font headingLevel={3} style={{ textAlign: "center" }}>
          Welcome to the PepsiCo’s Agriculture Experience
        </Font>
      </Box>

      <div className="login-card-item" style={{ width: "100%" }}>
        <Card
          border
          ellipsis={false}
          icon={null}
          title={null}
          primaryButtonProps={{
            size: "large",
            text: "Sign in",
            variant: "primary",
            onClick: switchToExternal,
          }}
          style={{ textAlign: "center", justifyContent: "center" }}
        >
          <Logo
            colorStyle="color"
            logoType="pepsicoSymbol"
            style={{ width: 64, height: 64 }}
          />
          <Font headingLevel={6} style={{ textAlign: "center" }}>
            Sign-in using PepsiCo OKTA credentials
          </Font>
        </Card>
      </div>
      <div className="login-card-item" style={{ width: "100%" }}>
        <Card
          border
          ellipsis={false}
          icon={null}
          title={null}
          primaryButtonProps={{
            size: "large",
            text: "Request access",
            variant: "primary",
            onClick: handleOpen,
          }}
          style={{
            textAlign: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Avatar size="medium" type="icon" />
          <Font headingLevel={6} style={{ textAlign: "center" }}>
            New to the site?
          </Font>
          <Font>Click below to request access to site</Font>
        </Card>
      </div>

      {/* <Box
        sx={{
          display: "flex",
          padding: "24px",
          flexDirection: "column",
          alignItems: "center",
          gap: "16px",
          borderRadius: "8px",
          backgroundColor: "#FBFCFE",
          boxShadow: "0px 0px 24px rgba(0, 92, 188, 0.08)",
          flexShrink: 0,
          height: "auto",
        }}
      >
        <Logo
          colorStyle="color"
          logoType="pepsicoSymbol"
          style={{ width: 64, height: 64 }}
        />
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h6"
            sx={{
              color: "#3A3A3A",
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: 700,
              lineHeight: "28px",
            }}
          >
            Sign-in using PepsiCo OKTA credentials
          </Typography>
        </Box>
        <Button
          size="large"
          text="Sign in"
          variant="primary"
          onClick={switchToExternal}
        />
      </Box> */}

      {/* New User Access Request */}
      {/* <Box
        sx={{
          display: "flex",
          padding: "24px",
          flexDirection: "column",
          alignItems: "center",
          gap: "16px",
          borderRadius: "8px",
          backgroundColor: "#FBFCFE",
          boxShadow: "0px 0px 24px rgba(0, 92, 188, 0.08)",
          flexShrink: 0,
          height: "auto",
        }}
      >
        <MuiAvatar sx={{ bgcolor: "#B2CAEA", width: 40, height: 40 }}>
          <PersonOutlinedIcon color="primary" />
        </MuiAvatar>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h6"
            sx={{
              color: "#3A3A3A",
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: 700,
              lineHeight: "28px",
            }}
          >
            New to the site ?
          </Typography>
          <Typography
            sx={{
              color: "#3A3A3A",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            click below to request access to site
          </Typography>
        </Box>
        <Button
          size="large"
          text="Request access"
          variant="primary"
          onClick={handleOpen}
        />
      </Box> */}
      <AccessRequestFormModal open={open} handleClose={handleClose} />
    </Box>
  );
}
