import React, { useEffect, useState, useRef, useCallback } from "react";
import { Outlet, useSearchParams, useNavigate } from "react-router-dom";
import Loader from "../utils/Loader";
import { setFlagUser, setUser } from "../slices/userSlice";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../constant/apiUrl";
import { getAPI } from "../API/ApiService";
import { setUserNavigations } from "../slices/navSlice";

export const PrivateRoute = () => {
  const [searchParams] = useSearchParams();
  const tokenFromQuery = searchParams.get("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { oktaAuth, authState } = useOktaAuth();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const token = localStorage.getItem("auth_token");
  const [newAuthState, setNewAuthState] = useState(authState);
  const [nav, setNav] = useState([]);

  const inactivityTimerRef = useRef(null);
  const effectiveAuthState = newAuthState !== null ? newAuthState : authState;

  const resetInactivityTimer = useCallback(() => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }

    inactivityTimerRef.current = setTimeout(() => {
      localStorage.removeItem("auth_token");
      navigate("/login");
    }, 900000);
  }, [navigate]);

  useEffect(() => {
    const handleUserActivity = () => {
      resetInactivityTimer();
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    resetInactivityTimer();

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
    };
  }, [resetInactivityTimer]);

  useEffect(() => {
    const checkAndRefreshToken = async () => {
      try {
        if (authState?.isAuthenticated) {
          const accessToken = authState.accessToken;
          const currentTime = Math.floor(Date.now() / 1000);

          if (accessToken.expiresAt - currentTime < 60 && !isRefreshing) {
            setIsRefreshing(true);
            const { refreshToken, idToken } = JSON.parse(
              localStorage.getItem("okta-token-storage")
            );

            const tokenUrls = `${refreshToken.tokenUrl}`;
            const clientId = `${idToken.clientId}`;

            const body = new URLSearchParams();
            body.append("grant_type", "refresh_token");
            body.append("client_id", clientId);
            body.append(
              "refresh_token",
              refreshToken.refreshToken
                ? refreshToken.refreshToken
                : refreshToken
            );
            body.append("scope", "openid offline_access email profile");

            const response = await fetch(tokenUrls, {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: body.toString(),
            });

            if (!response.ok) {
              throw new Error("Failed to refresh the token");
            }

            const data = await response.json();
            const { access_token, refresh_token, expires_in } = data;

            const newData = await oktaAuth.authStateManager.updateAuthState();

            newData.refreshToken.refreshToken = refresh_token;
            newData.refreshToken.expiresAt =
              Math.floor(Date.now() / 1000) + expires_in;

            localStorage.setItem("auth_token", access_token);
            localStorage.setItem("okta-token-storage", JSON.stringify(newData));
            localStorage.setItem("auth_state", JSON.stringify(newData));

            setNewAuthState(newData);
          }
        }
      } catch (error) {
        console.error("Error refreshing access token:", error);
        navigate("/login");
      } finally {
        setIsRefreshing(false);
      }
    };

    const interval = setInterval(() => {
      checkAndRefreshToken();
    }, 600000);

    return () => clearInterval(interval);
  }, [oktaAuth, authState, isRefreshing, navigate]);

  useEffect(() => {
    if (!effectiveAuthState) {
      // navigate("/login");
      return;
    }

    if (!effectiveAuthState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      if (effectiveAuthState?.isAuthenticated === false || token) {
        navigate("/login");
      } else {
        oktaAuth.signInWithRedirect();
      }
    } else if (authState?.isAuthenticated) {
      if (
        tokenFromQuery !== undefined &&
        tokenFromQuery !== "" &&
        tokenFromQuery !== null
      ) {
        localStorage.setItem("auth_token", tokenFromQuery);
        oktaAuth.getUser().then((info) => {
          dispatch(setUser(info));
        });
      } else {
        localStorage.setItem("auth_token", authState?.accessToken?.accessToken);
        oktaAuth.getUser().then((info) => {
          dispatch(setUser(info));
        });
      }
    }
  }, [
    oktaAuth,
    authState,
    tokenFromQuery,
    navigate,
    dispatch,
    effectiveAuthState,
    token,
  ]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await getAPI(`${BASE_URL}/navigations/navigations`);
        if (response?.status === 200) {
          dispatch(setUserNavigations(response?.data));
          setNav(response?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, [dispatch]);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await getAPI(`${BASE_URL}/admin/users/getUserDetails`);
        if (response?.data?.isGrantedAccess === false) {
          navigate("/login");
          //await oktaAuth.signOut();
        }
        dispatch(setFlagUser(response?.data));
      } catch (e) {
        console.log(e);
      }
    };
    fetchRole();
  }, [dispatch]);

  if (
    !effectiveAuthState ||
    !effectiveAuthState?.isAuthenticated ||
    nav?.length === 0
  ) {
    return <Loader />;
  }

  return <Outlet />;
};

export default PrivateRoute;
