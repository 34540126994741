import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PendingApproval from "./PendingApproval";
import YearlyReview from "./YealryReview";
import { getDropDownOptionValues } from "../../utils/function";
import DeniedArtifactHistory from "./DeniedHistory/DeniedArtifactHistory";
import ApprovalHistroy from "./ArtifactHistory/ApprovalHistroy";
import {
  setSpecialProject,
  setSectors,
  setSubjects,
  setCoutries,
  setLanguage,
  setPeriods,
  setSubjectMatterExpert,
  setContentLevel,
  setCropType,
  setDisclaimers,
  setVisibility
} from "../../slices/curatorPageLookupSlice";
import Loading from "../../utils/Loader";
import {
  curatorPageLookUpFirstApiCall,
  curatorPageLookUpSecondApiCall,
} from "../../API/curatorApi";
import { useDispatch } from "react-redux";

export default function SelectCurrentCurator(props) {
  const dispatch = useDispatch();
  const [isLookupLoaded, setIsLookupLoaded] = useState(false);
  const currentCurator = useSelector(
    (store) => store?.curatorpage?.selectedCuratorPage
  );

  useEffect(() => {
    // curatorPageLookUpFirstApiCall()
    //   .then((response) => {
    //     dispatch(setSectors(getDropDownOptionValues(response?.sectors)));

    //     dispatch(setLanguage(getDropDownOptionValues(response?.language)));
    //     dispatch(
    //       setSpecialProject(getDropDownOptionValues(response?.specialProjects))
    //     );
    //     dispatch(setSubjects(getDropDownOptionValues(response?.subjects)));
    //     dispatch(setCoutries(getDropDownOptionValues(response?.countries)));
    //     setTimeout(() => {
    //       makeCuratorLookupSecondApiCall();
    //     }, 1000);
    //   })
    //   .catch((error) => {
    //     console.log("curatorPageLookUpFirstApiCall Error", error);
    //     makeCuratorLookupSecondApiCall();
    //     setIsLookupLoaded(false);
    //   });
           setTimeout(() => {
          setIsLookupLoaded(true);
        }, 250);
  }, []);

  // const makeCuratorLookupSecondApiCall = () => {
  //   curatorPageLookUpSecondApiCall()
  //     .then((response) => {
  //       dispatch(setPeriods(getDropDownOptionValues(response?.perods)));

  //       dispatch(
  //         setSubjectMatterExpert(
  //           getDropDownOptionValues(response?.subjectMatterExpert, true)
  //         )
  //       );
  //       dispatch(
  //         setContentLevel(getDropDownOptionValues(response?.contentLevel))
  //       );
  //       dispatch(setCropType(getDropDownOptionValues(response?.cropType)));
  //       dispatch(setDisclaimers(getDropDownOptionValues(response?.disclaimer)));
  //       dispatch(setVisibility(getDropDownOptionValues(response?.visibility)));
  //       setTimeout(() => {
  //         setIsLookupLoaded(true);
  //       }, 1000);
  //     })
  //     .catch((error) => {
  //       console.log("curatorPageLookUpApiCall Error", error);
  //       setIsLookupLoaded(true);
  //     });
  // };

  const renderPage = () => {
    switch (currentCurator) {
      case "Pending approvals":
        return <PendingApproval />;
      case "Yearly review":
        return <YearlyReview />;
      case "Artifact history":
        return <ApprovalHistroy />;
      case "Denied history":
        return <DeniedArtifactHistory />;
      default:
        return null;
    }
  };

  return (
    <div className="w-full">{isLookupLoaded ? renderPage() : <Loading />}</div>
  );
}
