import React from "react";
import SustainabilitySubpage from "./SustainabilitySubpage";
import { setFarmingProgram } from "../slices/sustainabilityPageSlice";
import { useSelector } from "react-redux";
function SustainableFarmingProgram() {
  const { farmingprogram } = useSelector((store) => store.sustainable);
  return (
    <SustainabilitySubpage
      urlName={"Sustainable%20Farming%20Program"}
      header={"Sustainable farming program"}
      action={setFarmingProgram}
      globalState={farmingprogram}
    />
  );
}

export default SustainableFarmingProgram;
