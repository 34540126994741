import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  Box,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import useThemeRender from "../../theme/useThemeRender";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import { getActiveItems } from "../../utils/function";
const CROP_TYPE_OPTIONS = [
  { value: "type1", label: "Type 1" },
  { value: "type2", label: "Type 2" },
  { value: "type3", label: "Type 3" },
];

const StepThreeForm = ({ internalData, setInternalData }) => {
  const isDarkTheme = useSelector((state) => state.theme);
  const [cropData,setCropData] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
useEffect(()=>{
  const fetchCropData = async () => {
    try {
      const response = await getAPI(
        `${BASE_URL}/lookup/all?lookUpType=CROP_TYPE`
      );
      if(response?.status === 200){
        const activeItems = getActiveItems(response?.data);
        setCropData(activeItems);
        console.log("crop type",activeItems)
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  fetchCropData()
},[])
  const handleChange = (e) => {
    const value = e.target.value;
    setInternalData((prevData) => ({
      ...prevData,
      cropTypeId: value,
    }));
  };
  const renderSelectOptions = (options) =>
    options.map((option) => (
      <MenuItem
        key={option.name}
        value={option.id}
        sx={{
          color: isDarkTheme
            ? darkTheme.textColorLayer1
            : lightTheme.textColorLayer1,
        }}
      >
        {option.name}
      </MenuItem>
    ));

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 2,
        borderRadius: 1,
        background: isDarkTheme
          ? darkTheme.cardBackgroundColorLayer1
          : lightTheme.cardBackgroundColorLayer1,
        boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
      }}
    >
      <Typography
        sx={{
          color: useThemeRender("textColorLayer1"),
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "32px",
          mb: 4,
        }}
      >
        Step 3: Keywords
      </Typography>

      <FormControl sx={{ mb: 2, width: { xs: "300px", md: "400px" }, }}>
        <Typography
          sx={{
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : lightTheme.textColorLayer1,
            mb: 1,
          }}
        >
          Crop Type
        </Typography>
        <Select
          labelId="select-crop-type-label"
          id="select-crop-type"
          value={internalData.cropTypeId}
          onChange={handleChange}
          MenuProps={{
            PaperProps: {
              sx: {
                background: isDarkTheme
                  ? darkTheme.backgroundColorLayer3
                  : lightTheme.backgroundColorLayer3,
                "& .MuiMenuItem-root": {
                  color: isDarkTheme
                    ? darkTheme.textColorLayer1
                    : lightTheme.textColorLayer1,
                  "&:hover": {
                    backgroundColor: isDarkTheme
                      ? darkTheme.dropdownHoverColorLayer3
                      : lightTheme.dropdownHoverColorLayer3,
                  },
                },
              },
            },
          }}
          sx={{
            backgroundColor: isDarkTheme
              ? darkTheme.backgroundColorLayer2
              : lightTheme.backgroundColorLayer2,
            height: 40,
            "& .MuiSelect-icon": {
              color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
            },
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : lightTheme.textColorLayer1,
            border: "1px solid",
            borderColor: isDarkTheme
              ? darkTheme.borderColorLayer2
              : lightTheme.borderColorLayer2,
            "&:hover": {
              borderColor: isDarkTheme
                ? darkTheme.borderColorLayer2
                : lightTheme.borderColorLayer2,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: isDarkTheme
                ? darkTheme.borderColorLayer2
                : lightTheme.borderColorLayer2,
            },
          }}
          displayEmpty
        >
          <MenuItem
            value=""
            sx={{
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            }}
          >
            Select Crop
          </MenuItem>
          {renderSelectOptions(cropData)}
        </Select>
      </FormControl>

      <Box>
        <Typography sx={{ color: useThemeRender("textColorLayer1") }}>
          Keywords
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={6}
          sx={{
            width: { xs: "300px", md: "405px" },
            padding: "8px 0px 8px 8px",
            borderRadius: "4px",
            ml:-1,
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : lightTheme.textColorLayer1,
            borderColor: isDarkTheme
              ? darkTheme.borderColorLayer2
              : lightTheme.borderColorLayer2,
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px",
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
              "& fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
              "&:hover fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorHover
                  : lightTheme.borderColorHover,
              },
              "&.Mui-focused fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorFocus
                  : lightTheme.borderColorFocus,
              },
            },
          }}
          value={internalData.keywords || ""}
          onChange={(e) => {
            const newValue = e.target.value;
            if (newValue.length <= 600) {
              setInternalData((prevData) => ({
                ...prevData,
                keywords: newValue,
              }));
            }
          }}
          placeholder="type here"
          variant="outlined"
          helperText={`${internalData.keywords?.length}/600`}
          FormHelperTextProps={{
            sx: {
              position: "absolute",
              bottom: "8px",
              right: "8px",
              color: isDarkTheme
                ? darkTheme.textColorLayer4
                : lightTheme.textColorLayer4,
              fontSize: "12px",
            },
          }}
        />

        <Box display="flex" alignItems="center" mt={1}>
          <Tooltip title="Note: Separate each keyword by a comma.">
            <IconButton>
              <InfoOutlinedIcon
                sx={{
                  color: isDarkTheme
                    ? darkTheme.iconColor2
                    : lightTheme.iconColor2,
                }}
              />
            </IconButton>
          </Tooltip>
          <Typography
            variant="caption"
            sx={{
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            }}
          >
            Note: Separate each keyword by a comma.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default StepThreeForm;
