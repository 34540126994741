import React, { useState } from "react";
import PropTypes from "prop-types"; // Import prop-types
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { postAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import { Box } from "@mui/material";

export default function LikeButton({
  cardId,
  Liked,
  onLikesUpdated,
  setLoading,
  payloadLike,
  setFetch,
  fetch,
}) {
  console.log(Liked, "----likedddd------------");

  const [hasLiked, setHasLiked] = useState(Liked);
  const [updatedLikes, setUpdatedLikes] = useState(0);

  console.log(updatedLikes);
  console.log(hasLiked, "--------hasLiked--------");

  const handleLike = async () => {
    const data = {
      id: cardId,
      likes: payloadLike,
    };

    console.log(data, "-----likedData--------");

    try {
      const response = await postAPI(`${BASE_URL}/rfi/save-rfi-metrics`, data);
      console.log(response, "----------rfiResponse-----");

      if (response?.status === 200) {
        const newLikes = response?.data?.response;
        setUpdatedLikes(newLikes.likes);
        onLikesUpdated(newLikes.likes); // Call the function passed from the parent to update likes
        setHasLiked(payloadLike);
        setFetch(!fetch);
      } else {
        console.error("Failed to update like status:", response);
      }
    } catch (error) {
      console.error("Error while liking the post:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      onClick={handleLike}
      style={{ cursor: "pointer" }}
      data-testid="button"
    >
      {hasLiked ? (
        <ThumbUpIcon sx={{ height: "32px", width: "20px", fill: "#005CBC" }} />
      ) : (
        <ThumbUpOutlinedIcon
          sx={{ height: "32px", width: "20px", fill: "#005CBC" }}
        />
      )}
    </Box>
  );
}

// Prop Types validation
LikeButton.propTypes = {
  cardId: PropTypes.string.isRequired, // cardId must be a string and is required
  initialLiked: PropTypes.bool.isRequired, // initialLiked must be a boolean and is required
  onLikesUpdated: PropTypes.func.isRequired, // onLikesUpdated must be a function and is required
};
