// InternalPageLayout.js
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import InternalHeader from "../InternalPages/InternalHeader";

import { useDispatch, useSelector } from "react-redux";
import TabContentView from "./TabContentView";
import LibraryFilter from "./LibraryFilter";

import ViewSummary from "../ViewSummary/ViewSummary";

export default function LibraryPageLayout({ pageData, header }) {
  const selectedView = useSelector((state) => state.view);

  const [grid, setGrid] = useState(selectedView);
  const [pageHeader, setPageHeader] = useState(header);
  const [selectedSection, setSelection] = useState(null); // Use useState to create setSelection

  useEffect(() => {
    setGrid(selectedView);
  }, [selectedView]);

  return (
    <Box
      sx={{
        mt: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingX: { xs: "16px", md: "53px" },
      }}
    >
      <InternalHeader header={pageHeader} /> {/* Pass setSelection */}
      <Box sx={{ zIndex: 1000 }}>
        {/* Pass setSelection to InternalFilter */}
        <LibraryFilter
          setGrid={setGrid}
          pageData={pageData}
          header={header}
          grid={grid}
          setSelection={setSelection}
        />
      </Box>
      <TabContentView tabData={pageData} pageName="Library" />
      <ViewSummary />
    </Box>
  );
}
