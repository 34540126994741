import React from "react";
import SustainabilitySubpage from "./SustainabilitySubpage";
import { useSelector } from "react-redux";
import { setGghToolKit } from "../slices/sustainabilityPageSlice";
function GHCToolkit() {
  const { ghgToolkit } = useSelector((store) => store.sustainable);
  return (
    <SustainabilitySubpage
      urlName={"GHG%20Toolkit"}
      header={"GHG toolkit"}
      action={setGghToolKit}
      globalState={ghgToolkit}
    />
  );
}

export default GHCToolkit;
