const adminValidations = (name, organization, role, email) => {
  const errors = {};

  if (!name || name.trim() === "") {
    errors.name = "Name cannot be empty.";
  }

  if (!role || role.trim() === "") {
    errors.role = "Role  cannot be empty.";
  }
  if (!email || email.trim() === "") {
    errors.emailaddress = "Email Address cannot be empty.";
  }
  if (!organization || organization.trim() === "") {
    errors.company = "Company cannot be empty.";
  }

  return errors;
};

export default adminValidations;
