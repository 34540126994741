import React from "react";

import { Box, Typography } from "@mui/material";
import useThemeRender from "../../../../theme/useThemeRender";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsOpenListOfvaluePopup,
  setListOfValueSearch,
  setlistOfValueSideTrayName,
  setSpecialProjectDrawer,
} from "../../../../slices/adminPageSlice";

import { Search } from "pepsico-ds";
import ListOfValuesTable from "./ListOfValuesTable";
import AddSpecialProject from "../SpecialProject/AddSpecialProject";
import ListOfDropDown from "./ListOfDropDown";
import Notification from "../SpecialProject/Notification";

function ListOfValues() {
  const dispatch = useDispatch();

  const {
    selectedListOfValues,
    isOpenListOfvaluePopup,
    listOfValuePopupMessage,
    listOfValuePopupStatus,
  } = useSelector((store) => store.adminpage);
  const handleOpenSideTray = () => {
    dispatch(setSpecialProjectDrawer(true));
    dispatch(setlistOfValueSideTrayName("Add value"));
  };

  const isDarkTheme = useSelector((state) => state.theme);
  const handleSearch = (value) => {
    dispatch(setListOfValueSearch(value));
  };
  const options = [
    "Content level",
    "Sector",
    "Country",
    "Region/Country",
    "Crop Type",
    "Disclaimer",
    "Language",
    "IP Ownership",
    "Flesh Color",
    "Organization",
    "Bussiness Unit",
    "Company",
    "Department",
    "Partner Type",
    "Period",
    "Reason For Access",
    "Region",
    "Special Projects",
    "Variety",
    "Visibility",
  ].sort((a, b) => a.localeCompare(b));
  const textColorLayer2 = useThemeRender("textColorLayer2");
  return (
    <Box
      sx={{
        display: "flex",
        padding: "24px 16px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        borderRadius: "var(--Corner-radius-corner-radius-medium, 16px)",
        background: useThemeRender("backgroundColorLayer3"),
        boxShadow:
          "var(--Elevation-X-Elevation-02, 0px) var(--Elevation-Y-Elevation-02, 4px) var(--Elevation-Blur-Elevation-02, 24px) var(--Elevation-Spread-Elevation-02, 0px) var(--Elevation-Colors-Elevation-02, rgba(58, 58, 58, 0.08))",
      }}
    >
      <Typography
        sx={{
          color: useThemeRender("textColorLayer1"),
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
          fontSize: "var(--Font-Size-H5, 24px)",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "var(--Line-Height-H5, 32px)",
        }}
      >
        List of values
      </Typography>
      <ListOfDropDown
        options={options}
        label={"Value type"}
        value={"valueType"}
      />
      {selectedListOfValues && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: textColorLayer2,
                fontFeatureSettings: "'liga' off, 'clig' off",
                fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
                fontSize: "var(--Font-Size-H4, 28px)",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "var(--Line-Height-H4, 40px)",
              }}
            >
              {selectedListOfValues}
            </Typography>
            <Box sx={{ mt: "5px" }}>
              <Search onUpdate={handleSearch} isDark={isDarkTheme} />
            </Box>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
          >
            <Box
              sx={{
                borderRadius: "var(--Corner-radius-corner-radius-large, 24px)",
                background: "var(--Tokens-Button-Primary, #005CBC)",
                display: "flex",
                height: "32px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleOpenSideTray}
            >
              <Box
                sx={{
                  color: "var(--Colors-White, #FFF)",
                  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                  fontSize: "var(--Font-Size-Small, 14px)",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "var(--Line-Height-Small, 20px)",
                  padding: "14px",
                }}
              >
                Add Value
              </Box>
            </Box>
          </Box>
          <ListOfValuesTable />
        </>
      )}
      <AddSpecialProject screen={"addListOfValues"} />
      <Notification
        open={isOpenListOfvaluePopup}
        setOpen={setIsOpenListOfvaluePopup}
        type={listOfValuePopupStatus}
        message={listOfValuePopupMessage}
      />
    </Box>
  );
}

export default ListOfValues;
