import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SearchHeader from "./SearchHeader";
import SearchFilter from "./SearchFilter/SearchFilter";
import { Tab, TabsNavigation } from "pepsico-ds";
import SearchItems from "./SearchItems";
import InternalCommonSection from "../InternalPages/CommonSection/InternalCommonSection";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import Loading from "../../utils/Loader";
import PropTypes from "prop-types";
import ViewSummary from "../ViewSummary/ViewSummary";
import {
  setHasMoreDocuments,
  setHasMoreLinks,
  setHasMoreVideos,
  updateDocumentItems,
  updateLinkItems,
  updateVideoItems,
  clearState,
} from "../../slices/searchResultSlice";

Search.propTypes = {
  search: PropTypes.string.isRequired,
};
export default function Search({ search }) {
  const selectedView = useSelector((state) => state.view);
  const dispatch = useDispatch();
  const { viewSummaryDrawer } = useSelector((store) => store.viewSummary);
  const [grid, setGrid] = useState(selectedView);
  const [data, setData] = useState([]);
  const [tab, setTab] = useState(0);

  const [favorite, setFavorite] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [reload, setReload] = useState(false);
  const [variety, setVariety] = useState(null);
  const [sector, setSector] = useState(null);
  const [flesh, setFlesh] = useState(null);
  const [selectedValue, setSelectedValue] = useState("exactMatch");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [videoSession, setVideoSession] = useState(null);
  const [linkSession, setLinkSession] = useState(null);
  const [documentSession, setDocumentSession] = useState(null);
  const token = localStorage.getItem("auth_token");

  const isDarktheme = useSelector((state) => state.theme);
  useEffect(() => {
    setGrid(selectedView);
  }, [selectedView]);

  function buildSearchUrl({ search, sector, variety, flesh, selectedValue }) {
    // Initialize an array to hold the query parameters
    console.log(flesh, "----fleshColor------");

    let params = [];

    // Conditionally add parameters based on availability

    params.push(`phrase=${search}`);

    if (sector) {
      params.push(`sector=${sector}`);
    } else {
      params.push("sector=");
    }

    if (variety) {
      params.push(`variety=${variety}`);
    } else {
      params.push("variety=");
    }

    if (flesh) {
      params.push(`fleshColor=${flesh}`);
    } else {
      params.push("fleshColor=");
    }

    // Add the search type (always included)
    params.push(`searchType=${selectedValue}`);

    // Construct the final URL by joining the parameters with '&'
    return `${BASE_URL}/smart-search/initial?${params.join("&")}`;
  }

  // Example usage

  useEffect(() => {
    setLoading(true);

    const fetchAllData = async () => {
      dispatch(clearState());
      console.log(flesh, "---fleshj---------");

      const url = buildSearchUrl({
        search,
        sector,
        variety,
        flesh,
        selectedValue,
      });
      try {
        const response = await getAPI(url);
        if (response?.status === 200) {
          console.log(response?.data, "--searched---");
          setData(response?.data);
          dispatch(updateDocumentItems(response?.data?.documents));

          dispatch(updateLinkItems(response?.data?.links));

          dispatch(updateVideoItems(response?.data?.videos));

          setFavorite(response?.data?.favourites);
          dispatch(setHasMoreVideos(response?.data?.isNextPageAvailableVideos));
          dispatch(
            setHasMoreDocuments(response?.data?.isNextPageAvailableDocuments)
          );
          dispatch(setHasMoreLinks(response?.data?.isNextPageAvailableLinks));

          setVideoSession(response?.data?.videoSessionId);
          setLinkSession(response?.data?.linkSessionId);

          setDocumentSession(response?.data?.documentSessionId);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
        setLoad(false);
      }
    };
    fetchAllData();
  }, [search, sector, variety, flesh, selectedValue, token, reload, dispatch]);

  const handleClick = (index) => setTab(index);
  if (load) return <Loading />;
  return (
    <Box
      sx={{
        paddingTop: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingX:
          grid === "tile"
            ? { xs: "0px", md: "53px" }
            : { xs: "0px", md: "25px" },
        backgroundColor:
          grid !== "tile" && !isDarktheme
            ? { xs: "none", md: "white" }
            : "none",
        paddingBottom: "24px",
      }}
    >
      <Box
        sx={{
          padding: grid !== "tile" ? { xs: "0px", md: "32px" } : undefined,
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          boxShadow:
            grid !== "tile"
              ? `var(--Elevation-X-Elevation-03, 0px) var(--Elevation-Y-Elevation-03, 2px) var(--Elevation-Blur-Elevation-03, 24px) var(--Elevation-Spread-Elevation-03, 8px) var(--Elevation-Colors-Elevation-03, rgba(0, 92, 188, 0.12))`
              : undefined,
        }}
      >
        <Box
          sx={{
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <SearchHeader search={search} />
          <Box sx={{ zIndex: 1000 }}>
            <SearchFilter
              grid={grid}
              setGrid={setGrid}
              setSearch={setSearchText}
              setVariety={setVariety}
              searchText={searchText}
              setSector={setSector}
              setFlesh={setFlesh}
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
              setReload={setReload}
              reload={reload}
              setLoad={setLoad}
            />
          </Box>
          <TabsNavigation selectedIndex={tab}>
            <Tab
              iconTrailing={false}
              label={`Documents (${
                data?.totalDocuments ? data?.totalDocuments : 0
              })`}
              onClick={() => handleClick(0)}
              orientation="horizontal"
            />
            <Tab
              label={`Videos (${data?.totalVideos ? data?.totalVideos : 0})`}
              iconTrailing={false}
              onClick={() => handleClick(1)}
            />
            <Tab
              label={`Links (${data?.totalLinks ? data?.totalLinks : 0})`}
              iconTrailing={false}
              onClick={() => handleClick(2)}
            />
            <Tab
              label={`Favourites (${
                data?.totalFavourites ? data?.totalFavourites : 0
              })`}
              iconTrailing={false}
              onClick={() => handleClick(3)}
            />
          </TabsNavigation>
        </Box>

        {tab === 0 && (
          <SearchItems
            grid={grid}
            items={data.totalDocuments}
            contenparam="document"
            content="Documents"
            search={search}
            searchText={searchText}
            sector={sector}
            variety={variety}
            flesh={flesh}
            selectedValue={selectedValue}
            token={token}
            documentSession={documentSession}
            setLoading={setLoading}
          />
        )}
        {tab === 1 && (
          <SearchItems
            grid={grid}
            content="Videos"
            videoSession={videoSession}
            items={data?.totalVideos}
            search={search}
            sector={sector}
            searchText={searchText}
            variety={variety}
            flesh={flesh}
            selectedValue={selectedValue}
            token={token}
          />
        )}
        {tab === 2 && (
          <SearchItems
            grid={grid}
            content="Links"
            contenparam="link"
            linkSession={linkSession}
            sector={sector}
            variety={variety}
            searchText={searchText}
            items={data?.totalLinks}
            flesh={flesh}
            selectedValue={selectedValue}
            token={token}
            search={search}
          />
        )}
        {tab === 3 &&
          (data?.totalFavourites === 0 ? (
            <Box
              sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
            >
              <p>No records found</p>
            </Box>
          ) : (
            <InternalCommonSection data={favorite || []} grid={grid} />
          ))}
      </Box>
      {viewSummaryDrawer && <ViewSummary />}
      {loading && <Loading />}
    </Box>
  );
}
