// import {
//   OKTA_EXTERNAL_CONFIG,
//   OKTA_INTERNAL_CONFIG,
// } from "./utils/appConstant";

// if (!window._env_) {
//   console.error("Environment variables not loaded!");
// }

// const externalConfig = OKTA_EXTERNAL_CONFIG;

// const internalConfig = OKTA_INTERNAL_CONFIG;

const externalConfig = {
  issuer: "https://pepsico.oktapreview.com/oauth2/default",
  clientId: "0oa294j7zlbSVJMYP0h8",
  redirectUri: `https://fritolayag.dev.pepsico.com/login/callback`,
  scopes: ["openid", "profile", "email", "offline_access"],
  pkce: true,
};

const internalConfig = {
  issuer: "https://secure.ite.pepsico.com/oauth2/default",
  clientId: "0oa28wlr5e2oMvIxC0h8",
  redirectUri: `https://fritolayag.dev.pepsico.com/login/callback`,
  scopes: ["openid", "profile", "email", "offline_access"],
  pkce: true,
};
console.log("Fixing the Env issue", {
  externalConfig: externalConfig,

  ENV: process.env,
});

export { externalConfig };
