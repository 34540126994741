import axios from "axios";
import { BASE_URL } from "../constant/apiUrl";
// Create an instance of Axios with default settings
const api = axios.create({
  // baseURL: process.env.REACT_APP_API_URL',
  baseURL: BASE_URL,
 // timeout: 15000, // 10 seconds timeout for requests
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    // Add authentication token or other headers if needed
    const token = localStorage.getItem("auth_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Ensure the error is wrapped in an Error object
    const errorDetails = {
      message: error.message || "Request configuration failed",
      config: error.config,
      code: error.code || "UNKNOWN_ERROR",
    };
    console.error("Request interceptor error:", errorDetails);

    return Promise.reject(new Error(JSON.stringify(errorDetails)));
  }
);
// Response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle unauthorized responses or any error logic here
    if (error.response && error.response.status === 401) {
      console.warn("Unauthorized access - Redirecting to login");
      // Example: Redirect to login or refresh token logic here
    }

    // Wrap error details in an Error object
    const errorDetails = {
      message: error.message || "Response error",
      status: error.response?.status,
      data: error.response?.data,
      config: error.config,
    };
    console.error("Response interceptor error:", errorDetails);

    return Promise.reject(new Error(JSON.stringify(errorDetails)));
  }
);

export default api;
