// InternalPageLayout.js
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import InternalHeader from "../components/InternalPages/InternalHeader";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BASE_URL } from "../constant/apiUrl";
import { getAPI } from "../API/ApiService";
import Loading from "../utils/Loader";
import TabContentView from "../components/LibraryPage/TabContentView";
import LibraryFilter from "../components/LibraryPage/LibraryFilter";
import { Link } from "react-router-dom";
import ViewSummary from "../components/ViewSummary/ViewSummary";

export default function LibraryDetails() {
  const selectedView = useSelector((state) => state.view);

  const [loading, setLoading] = useState(false);
  const [grid, setGrid] = useState(selectedView);
  const [pageData, setPageData] = useState(null);
  const [pageHeader, setPageHeader] = useState();
  const { favourite } = useSelector((store) => store.internalPage);
  const updateTheViewType = (viewType) => {
    setGrid(viewType);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getAPI(
          `${BASE_URL}/internal-pages/page-info/find-by-page-id?pageId=15`
        );
        setPageData(response.data);
        setPageHeader(response?.data?.pageName);
      } catch (err) {
        console.log("Library Details Error", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [favourite]);

  return loading ? (
    <Loading />
  ) : (
    <Box
      sx={{
        mt: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingX: { xs: "16px", md: "53px" },
      }}
    >
      <div
        style={{
          display: "flex",
          height: "40px",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Link
          to={"/library"}
          style={{
            fontWeight: 700,
            textAlign: "left",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
            color: "#005CBC",
          }}
        >
          <ArrowBackIcon
            sx={{
              width: "24px",
              height: "24px",
              top: "2px",
              left: "4px",
              paddingRight: "8px",
            }}
          />
          Back to library
        </Link>
      </div>
      <InternalHeader header={pageHeader} /> {/* Pass setSelection */}
      <Box sx={{ zIndex: 1000 }}>
        {/* Pass setSelection to InternalFilter */}
        <LibraryFilter
          setGrid={updateTheViewType}
          pageData={pageData}
          header={"LibraryDetails"}
          grid={grid}
          setSelection={null}
        />
      </Box>
      {pageData !== null && (
        <TabContentView
          tabData={pageData}
          pageName="LibraryDetails"
          selectedView={grid}
        />
      )}
      <ViewSummary />
    </Box>
  );
}
