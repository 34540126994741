import React, { useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { commonTextStyle } from "../InternalStyle";
import InternalCarousal from "../Carousal-ExpandedView/InternalCarousal";
import { useSelector } from "react-redux";
import ListViewDesign from "../ListView/ListViewDesign";
import useThemeRender from "../../../theme/useThemeRender";
import PropTypes from "prop-types";
export default function InternalCommonSection({
  data,
  grid,
  selectedSection,
  subPageId,
}) {
  const { documents, links, videos } = data;

  const { search, sector, variety, country, flashColor, ipOwnerShip } =
    useSelector((store) => store.internalPage);

  // Check if any content exists
  const filteredRows = useMemo(() => {
    const filterData = (items) =>
      items?.filter((item) => {
        const matchesSearch = Object?.values(item)?.some((value) =>
          String(value)
            ?.toLowerCase()
            ?.includes(search?.toLowerCase() || "")
        );
        const matchesSector =
          !sector ||
          item?.sector?.toLowerCase() === sector?.label.toLowerCase();
        const matchesCountry =
          !country ||
          item?.country?.toLowerCase() === country?.label.toLowerCase();
        const matchesVariety =
          !variety ||
          item?.variety?.toLowerCase() === variety?.label.toLowerCase();
        const matchesFleshColor =
          !flashColor ||
          item?.fleshColor?.toLowerCase() === flashColor?.label.toLowerCase();
        const matchesIpOwnerShip =
          !ipOwnerShip ||
          item?.ipOwnerShip?.toLowerCase() === ipOwnerShip?.label.toLowerCase();
        return (
          matchesSearch &&
          matchesSector &&
          matchesCountry &&
          matchesVariety &&
          matchesFleshColor &&
          matchesIpOwnerShip
        );
      });

    return {
      documents: filterData(documents),
      links: filterData(links),
      videos: filterData(videos),
    };
  }, [
    documents,
    links,
    videos,
    search,
    sector,
    country,
    variety,
    flashColor,
    ipOwnerShip,
  ]);
  useEffect(() => {
    // Scroll to the selected section
    if (selectedSection) {
      const sectionElement = document.getElementById(selectedSection);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [selectedSection]);

  return (
    <section
      id={data?.subPageName}
      style={{
        display: "flex",
        width: "100%",
        padding: "24px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        borderRadius: "8px",
        backgroundColor: useThemeRender("backgroundColorLayer2"),
        boxShadow: "0px 0px 24px rgba(0, 92, 188, 0.08)",
      }}
    >
      {/* Section Header */}
      <Box sx={{ display: "flex", alignItems: "center", alignSelf: "stretch" }}>
        <Typography
          sx={{
            ...commonTextStyle,
            fontSize: "24px",
            lineHeight: "32px",
            color: useThemeRender("textColorLayer2"),
          }}
        >
          {data?.subPageName}
        </Typography>
      </Box>

      {grid === "tile" && (
        <Box sx={{ width: "100%" }}>
          {/* Documents Carousal */}
          {filteredRows?.documents?.length > 0 && (
            <Box sx={{ marginBottom: "16px" }}>
              <InternalCarousal
                data={filteredRows?.documents}
                content={"Documents"}
                flexDirection={"column"}
                heading={"Documents"}
                isHeadingBig={false}
                subPageId={subPageId}
              />
            </Box>
          )}

          {filteredRows?.videos?.length > 0 && (
            <Box sx={{ marginBottom: "16px" }}>
              <InternalCarousal
                data={filteredRows?.videos}
                content={"Videos"}
                flexDirection={"column"}
                heading={"Videos"}
                isHeadingBig={false}
              />
            </Box>
          )}

          {/* Links Carousal */}
          {filteredRows?.links?.length > 0 && (
            <Box sx={{ marginBottom: "16px" }}>
              <InternalCarousal
                data={filteredRows?.links}
                content={"Links"}
                flexDirection={"column"}
                heading={"Links"}
                isHeadingBig={false}
                subPageId={subPageId}
              />
            </Box>
          )}

          {/* Videos Carousal */}
        </Box>
      )}
      {grid !== "tile" && (
        <Box sx={{ width: "100%" }}>
          {filteredRows?.documents?.length > 0 && (
            <Box sx={{ marginBottom: "16px" }}>
              <ListViewDesign
                data={filteredRows?.documents}
                content={"Documents"}
              />
            </Box>
          )}
          {filteredRows?.videos?.length > 0 && (
            <Box sx={{ marginBottom: "16px" }}>
              <ListViewDesign data={filteredRows?.videos} content={"Videos"} />
            </Box>
          )}

          {filteredRows?.links?.length > 0 && (
            <Box sx={{ marginBottom: "16px" }}>
              <ListViewDesign data={filteredRows?.links} content={"Links"} />
            </Box>
          )}
        </Box>
      )}
    </section>
  );
}
InternalCommonSection.propTypes = {
  data: PropTypes.shape({
    subPageName: PropTypes.string.isRequired,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired, // Assuming each document has an `id`
        sector: PropTypes.string,
        country: PropTypes.string,
        variety: PropTypes.string,
        fleshColor: PropTypes.string,
        ipOwnerShip: PropTypes.string,
      })
    ),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired, // Assuming each link has an `id`
        sector: PropTypes.string,
        country: PropTypes.string,
        variety: PropTypes.string,
        fleshColor: PropTypes.string,
        ipOwnerShip: PropTypes.string,
      })
    ),
    videos: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired, // Assuming each video has an `id`
        sector: PropTypes.string,
        country: PropTypes.string,
        variety: PropTypes.string,
        fleshColor: PropTypes.string,
        ipOwnerShip: PropTypes.string,
      })
    ),
  }).isRequired,
  grid: PropTypes.oneOf(["tile", "list"]).isRequired, // Restrict to either "tile" or "list"
  selectedSection: PropTypes.string, // Optional string for scrolling to a section
};
