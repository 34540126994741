import { Box, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { TableWrapper } from "./style";
import {formatDate} from "../../../utils/function";
import useThemeRender from "../../../theme/useThemeRender";
import { useSelector } from "react-redux";

const columns = [
  {
    field: "reviewedBY",
    headerName: "Review BY",
  },
  {
    field: "reviewedDate",
    headerName: "Reviewed Date",
  },
];


const ApproverHistoryTable = ({apprverHistory}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isDarkTheme = useSelector((state) => state.theme);
  const [approvalHistoryList, setApprovalHistory] = useState([]);

  useEffect(() => {
    let historyList = [];
    apprverHistory?.filter((item, index) => {
      historyList.push(
        { id: index, reviewedBY: item?.reviewedBy, reviewedDate: formatDate(item?.reviewedDate)}
      );
    });
    setApprovalHistory(historyList);
  }, [apprverHistory]);


  return (
    <Box
      sx={{
        padding: "16px",
        background: useThemeRender("cardBackgroundColorLayer2"),
      }}
    >
      <TableWrapper isMobile={isMobile} isDarkTheme={isDarkTheme}>
        <DataGrid
          rows={approvalHistoryList}
          columns={columns}
          disableRowSelectionOnClick
          hideFooterPagination
          disableColumnFilter
          disableColumnMenu
        />
      </TableWrapper>
    </Box>
  );
};

export default ApproverHistoryTable;
