import React from "react";
import "./LoginHeader.css";
import LoginPepsiSvg from "./LoginPepsiSvg";
import { Avatar, Font, Header } from "pepsico-ds";

export default function LoginHeader() {
  return (
    <Header
      leftIcon={<LoginPepsiSvg />}
      middleIcon={
        <Font size="body" variant="bold" isDark>
          Frito-Lay Agriculture
        </Font>
      }
      rightToolbar={[{ icon: <Avatar size="small" type="icon" /> }]}
      className="ag-website-header"
    />
  );
}
