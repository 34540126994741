import React, { useEffect, useState } from "react";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {BASE_URL, FILE_SERVICE_BASE_URL} from "../../constant/apiUrl.js";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import Loading from "../../utils/Loader.js";
import { postAPI } from "../../API/ApiService.js";
import { toast, ToastContainer } from "react-toastify";
import { showErrorToast, showSuccessToast } from "../../utils/Toast/toast";
import { setDisableNextButton } from "../../slices/adminPageSlice.js";
import {resetImage} from "../../slices/imageSlice.js";
import { useDispatch } from "react-redux";
import { contextType } from "react-quill";
import { current } from "@reduxjs/toolkit";

const NavigationButtons = ({
  onSaveDraft,
  activeStep,
  totalSteps,
  handleNext,
  handleBack,
  setSuccess,
  data,
  setData,
  fileType
}) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDarkTheme = useSelector((state) => state.theme);
  const { disableNextButton } = useSelector((store) => store.adminpage);
  const dispatch = useDispatch();
  const handleSendForApproval = async () => {
    setLoading(true);
    console.log("active",activeStep)
    if(data.disclaimerId === "" && activeStep === 3){
      showErrorToast("Please ensure all mandatory fields are filled out.");
      setLoading(false);
      return
    }
    if (
      (!data.contentTitle ||
      data.subjectIds.length ===0 || 
      data.sectorIds.length === 0|| 
      data.countryIds.length === 0 ) &&
      activeStep === 1
    ) {
      showErrorToast("Please ensure all mandatory fields are filled out.");
      setLoading(false);
      return;
    }
    const transformDataDynamic = (obj, fieldsToCheck) => {
      let updatedData = fileType === "VIDEO"?{ ...obj, contentTypeId:3 }:{...obj,contentTypeId:1 };
      console.log("send approval conetnt id",obj?.contentTypeId)
      if (obj?.contentTypeId === "LINK") {
        updatedData = { ...obj, contentTypeId: 2 };
        delete updatedData?.attachmentFileId;
      }
      console.log("update",updatedData)
      fieldsToCheck.forEach((field) => {
          if (Array.isArray(updatedData[field]) && updatedData[field].length === 0) {
              updatedData[field] = [0];
          }
      });
      return updatedData;
  };
    const fieldsToCheck = ["subjectIds", "sectorIds", "countryIds", "specailProjects","languageId"];
    const updatedData = transformDataDynamic(data, fieldsToCheck);
    const apiPayload = {
      ...updatedData,
      summary:updatedData?.summary?.replace(/(<([^>]+)>)/ig, ''),
      notes: updatedData?.notes?.replace(/(<([^>]+)>)/ig, ''),
      translatedText:updatedData?.translatedText?.replace(/(<([^>]+)>)/ig, ''),
    }
    console.log('apiPayload', apiPayload); 
    try {
      const response = await postAPI(
        // `${FILE_SERVICE_BASE_URL}/fritolay/content-management/send-approval`,
        `${BASE_URL}/content/saveapproval`,
        apiPayload
      );
      if (response.status === 200) {
        console.log("data on send approved ", data);
        console.log("Response:", response.data);
        if (activeStep === totalSteps - 1) {
          setSuccess({open:true, error:false});
          }
      } else {
        console.log("inside else",totalSteps - 1)
        if (activeStep === totalSteps - 1) {
          setSuccess({open:true, error:true});
          console.log("inside else if",totalSteps - 1)
          }
        showErrorToast("Failed to send approval");
        console.error("Error:", response.data);
      }
    } catch (error) {
      console.log("inside catch",totalSteps - 1)
        if (activeStep === totalSteps - 1) {
          console.log("inside catch if",totalSteps - 1)
        setSuccess({open:true, error:true});
        }
      console.error("Error saving draft:", error);
      showErrorToast("Failed to save draft");
    } finally {
      setLoading(false);
      setData({
        contentTitle: "",
        // visibility: "EXTERNAL",
        visibilityId:1,
        // subjects: [
        // ],
        // sectors: [
        // ],
        // countries: [
        // ],
        // contentLevel: "",
        contentLevelId:1,
        cropTypeId:0,
        keywords: "",
        notes: "",
        summary: "",
        // disclaimer: "",
        disclaimerId:0,
      //  languageTranslationEnabled: false,
        // translationLanguage: "",
       // translation: "",
        //attachmentLinkUrl: "",
        thumbnailFileId: 0,
        attachmentFileId: 0,
        // contentType: "",
        contentTypeId: "DOCUMENT",
        subjectIds: [],
        sectorIds: [],
        countryIds: [],
        specailProjects: [],
        languageId: 0,
      });
      dispatch(resetImage());
    }
    // if (activeStep === totalSteps - 1) {
    //   setSuccess(true);
    // }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: isMobile ? "center" : "space-between",
          alignItems: "center",
          mt: 2,
          boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
          py: "16px",
          px: "24px",
          gap: isMobile ? 2 : 0,
        }}
      >
        {isMobile && (
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                startIcon={<ArrowBackIcon />}
                disabled={activeStep === 0}
                onClick={handleBack}
                variant="text"
                sx={{
                  color: "#005CBC",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "170%",
                  textTransform: "none",
                }}
              >
                Previous
              </Button>
              <Button
                endIcon={<ArrowForwardIcon />}
                onClick={handleNext}
                variant="text"
                disabled={activeStep === totalSteps - 1}
                sx={{
                  color: "#005CBC",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "170%",
                  textTransform: "none",
                }}
              >
                Next
              </Button>
            </Box>

            <Box sx={{ width: "100%" }}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                sx={{
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "170%",
                  borderRadius: "24px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
                disabled={activeStep === 0}
                onClick={onSaveDraft}
              >
                Save draft
              </Button>
            </Box>

            <Box sx={{ width: "100%" }}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                sx={{
                  borderRadius: "24px",
                  textTransform: "none",
                  fontWeight: 700,
                  "&:hover": {
                    backgroundColor: "#B0B0B0",
                  },
                }}
                onClick={handleSendForApproval}
                disabled={activeStep < totalSteps - 1}
              >
                Send for approval
              </Button>
            </Box>
          </>
        )}

        {!isMobile && (
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: "24px",
                textTransform: "none",
                fontWeight: 700,
              }}
              disabled={activeStep === 0}
              onClick={onSaveDraft}
            >
              Save draft
            </Button>

            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                startIcon={<ArrowBackIcon />}
                disabled={activeStep === 0}
                onClick={handleBack}
                variant="text"
                sx={{
                  color: "#005CBC",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "170%",
                  textTransform: "none",
                  "&.Mui-disabled": {
                    color: isDarkTheme
                      ? darkTheme.disableButtonColor
                      : lightTheme.disableButtonColor,
                  },
                }}
              >
                Previous
              </Button>
              <Button
                endIcon={<ArrowForwardIcon />}
                onClick={handleNext}
                variant="text"
                disabled={disableNextButton}
                sx={{
                  color: "#005CBC",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "170%",
                  textTransform: "none",
                  "&.Mui-disabled": {
                    color: isDarkTheme
                      ? darkTheme.textColorLayer1
                      : lightTheme.disableButtonColor,
                  },
                }}
              >
                Next
              </Button>
            </Box>

            <Button
              variant="contained"
              color="primary"
              sx={{
                textTransform: "none",
                fontWeight: 700,

                borderRadius: "24px",
              }}
              onClick={handleSendForApproval}
              disabled={activeStep < totalSteps - 1}
            >
              Send for approval
            </Button>
          </>
        )}
        {loading && <Loading />}
      </Box>
    </>
  );
};

export default NavigationButtons;
