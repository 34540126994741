import React from "react";
import potato from "../../assets/potato.png";
import { Box, Typography, Card, Grid } from "@mui/material";
import useThemeRender from "../../theme/useThemeRender";
import { formatDate } from "../../utils/function";
function DocumentDetails({ documentData }) {
  const insideCardBackgroundColorLayer4 = useThemeRender(
    "insideCardBackgroundColorLayer4"
  );
  const textColorLayer1 = useThemeRender("textColorLayer1");

  return (
    <Card
      className="SummaryCard"
      sx={{
        background: useThemeRender("backgroundColorLayer3"),
      }}
    >
      <Typography
        className="SummaryTitle"
        sx={{
          color: useThemeRender("textColorLayer2"),
        }}
      >
        Details
      </Typography>
      <Box
        sx={{
          display: "flex",
          padding: "var(--Spacing-spacing-16, 16px)",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "var(--Spacing-spacing-12, 12px)",
          alignSelf: "stretch",
          background: useThemeRender("backgroundColorLayer1"),
          height: "auto", // Corrected height
        }}
      >
        {documentData?.thumbnailUrl ? (
          <img
            src={documentData?.thumbnailUrl}
            alt="Card-specific "
            className="w-[246px] h-[103px]"
          />
        ) : (
          <img src={potato} alt="Fallback " className="w-[246px] h-[103px]" />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                View count
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {documentData?.viewCount}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Likes count
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {documentData?.likes}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Content level
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {documentData?.contentLevel}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Document type
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {documentData?.documentType}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Language
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {documentData?.language}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Version
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {documentData?.version}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Edited by SME
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {documentData?.editedBy}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Upload date
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {formatDate(documentData?.createdOn)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Uploaded by
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {documentData?.createdBy}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Reviewed date
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {formatDate(documentData?.reviewDate)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Legal review
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {documentData?.isLegalReview ? "Yes" : "No"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Subjects
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {documentData?.subjects}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "auto",
                padding:
                  "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-16, 16px)",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "var(--Spacing-spacing-8, 8px)",
                borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Tags
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  flexWrap: "wrap",
                  display: "flex",
                  alignItems: "center",
                  gap: "var(--Spacing-spacing-12, 12px)",
                }}
              >
                {documentData?.keywords?.split(",")?.map((val) => (
                  <Typography
                    sx={{
                      background: insideCardBackgroundColorLayer4,
                      color: textColorLayer1,
                    }}
                    key={val}
                  >
                    {val}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export default DocumentDetails;
