import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useThemeRender from "../../../../theme/useThemeRender";
import {
  Typography,
  styled,
  Box,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  setAppoveModal,
  setFetchPending,
} from "../../../../slices/invitationSlice";
import PropTypes from "prop-types";
import { BASE_URL } from "../../../../constant/apiUrl";
import { patchAPI } from "../../../../API/ApiService";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../utils/Toast/toast";
import Loading from "../../../../utils/Loader";

const StyledBox = styled(Box)({
  display: "flex",
  width: "full",
  borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
  background: "var(--Tokens-Background-Layer-02, #EFF5FC)",
  padding: "8px 16px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
});

const StyledTypography = styled(Typography)({
  color: "var(--Tokens-Text-Secondary, #616161)",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-Small, 14px)",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "var(--Line-Height-Small, 20px)", // 142.857%
});

const StyledTypography2 = styled(Typography)({
  color: "var(--Tokens-Text-Secondary, #616161)",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-Small, 12px)",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "var(--Line-Height-Small, 18px)", // 142.857%
});

export default function ApproveModal({ actionData }) {
  const dispatch = useDispatch();
  const { fetch } = useSelector((state) => state.invitation);
  const [explanation, setExplanation] = useState("");
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    dispatch(setAppoveModal(false));
  };

  const { flagUser } = useSelector((store) => store.user);

  const isMobile = useMediaQuery("(max-width:600px)");
  const denyhandler = async (status) => {
    setLoading(true);
    let data;
    if (status === "deny") {
      data = {
        id: actionData.id,
        denyReason: explanation,
        status: "REJECTED",
        actionTakenBy: flagUser.name,
      };
    } else {
      data = {
        id: actionData.id,
        status: "APPROVED",
        actionTakenBy: flagUser.name,
      };
    }
    try {
      const response = await patchAPI(
        `${BASE_URL}/admin/access-request-form/update-pending-request`,
        data
      );
      if (response?.status === 200) {
        handleClose();
        if (status === "deny") {
          showSuccessToast("You have successfully denied the request");
        } else {
          showSuccessToast("You have successfully approved the request");
        }

        dispatch(setFetchPending(!fetch));
      } else {
        showErrorToast(
          response?.data?.message || "Failed to update the request."
        );
      }
    } catch (error) {
      console.log(error, "errorr----");
    } finally {
      setLoading(false);
    }
    console.log(explanation, "----------");
  };
  const { approveModal, isDeny } = useSelector((store) => store.invitation);
  return (
    <Dialog
      open={approveModal}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          border: `10px solid  ${useThemeRender("borderColorLayer3")}`,
          background: useThemeRender("cardBackgroundColorLayer1"),
          boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
          padding: isMobile ? "20px" : "40px",
          width: isMobile ? "100%" : "500px",
        },
      }}
    >
      <DialogContent
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {loading && <Loading />}
        <Typography
          sx={{
            color: "var(--Tokens-Text-Primary, #3A3A3A)",
            textAlign: "center",
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
            fontSize: "var(--Font-Size-H5, 24px)",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-H5, 32px)",
          }}
        >
          {isDeny ? "Deny" : "Approver details "}
        </Typography>
        {isDeny && (
          <Typography
            sx={{
              color: "var(--Tokens-Text-Primary, #3A3A3A)",
              textAlign: "center",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
              fontSize: "var(--Font-Size-H5, 14px)",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "var(--Line-Height-H5, 20px)",
            }}
          >
            Please explain the reason of the denial
          </Typography>
        )}
        <StyledBox>
          <StyledTypography>Name</StyledTypography>
          <StyledTypography2>{flagUser?.name}</StyledTypography2>
        </StyledBox>
        <StyledBox>
          <StyledTypography>Date</StyledTypography>
          <StyledTypography2>
            {new Date()
              .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
              .replace(/ /g, "-")}
          </StyledTypography2>
        </StyledBox>
        {isDeny && (
          <Box
            sx={{
              display: "flex",
              width: "full",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                //color: isDarkTheme && darkTheme.textColorLayer1,
              }}
            >
              Explanation
            </Typography>

            <TextField
              placeholder="Type here"
              multiline
              minRows={4} // Controls the initial height
              maxRows={8}
              value={explanation}
              onChange={(e) => setExplanation(e.target.value)}
              style={{
                width: "100%",

                border: "1px solid white",
              }}
              variant="outlined"
              size="small"
            />
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: "flex-end" }}>
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
            font: "bold",
            fontWeight: 700,
            borderRadius: "24px",
            "&:hover": {
              background: "#004B9A",
            },
            marginRight: "10px",
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        {!isDeny && (
          <Button
            onClick={() => denyhandler("approve")}
            variant="contained"
            sx={{
              font: "bold",
              fontWeight: 700,
              textTransform: "none",
              borderRadius: "24px",
              backgroundColor: "#005CBC",
            }}
          >
            Approve
          </Button>
        )}
        {isDeny && (
          <Button
            onClick={() => denyhandler("deny")}
            variant="contained"
            sx={{
              font: "bold",
              fontWeight: 700,
              textTransform: "none",
              borderRadius: "24px",
              backgroundColor: "#005CBC",
            }}
          >
            Deny
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
ApproveModal.propTypes = {
  actionData: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};
