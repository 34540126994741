import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../theme/Theme";
import useThemeRender from "../theme/useThemeRender";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
const ListComponent = ({ title }) => {
  const isDarkTheme = useSelector((state) => state.theme);
  const insideCardBackgroundColorLayer1 = useThemeRender(
    "insideCardBackgroundColorLayer1"
  );
  const { forumData } = useSelector((store) => store.fritoForumPage);

  const navigate = useNavigate();
  const handleNavigate = (item) => {
    if (title === "Forums") {
      navigate("/frito-forum-detail-page", { state: item });
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        padding: "var(--Spacing-spacing-24, 24px)",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "var(--Spacing-spacing-16, 16px)",
        flexShrink: 0,
        borderRadius: "var(--Corner-radius-effect-corner-2, 8px)",
        background: useThemeRender("backgroundColorLayer3"),
        boxShadow:
          "-10px 0px 24px 0px rgba(0, 92, 188, 0.08), var(--Elevation-X-Elevation-01, 0px) var(--Elevation-Y-Elevation-01, 0px) var(--Elevation-Blur-Elevation-01, 24px) var(--Elevation-Spread-Elevation-01, 0px) var(--Elevation-Colors-Elevation-01, rgba(0, 92, 188, 0.08))",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          paddingBottom: "8px",
        }}
      >
        <Typography
          component="span"
          variant="body2"
          sx={{
            color: isDarkTheme
              ? darkTheme.textColorLayer2
              : lightTheme.textColorLayer2,
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
            fontSize: "var(--Font-Size-H4, 28px)",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-H4, 40px)",
          }}
        >
          {title}
        </Typography>
      </Box>

      <List
        sx={{
          width: "100%",
          display: "flex",
          gap: "16px",
          flexDirection: "column",
          height: "auto",
          overflowY: "auto",
          scrollbarWidth: "thin",
        }}
      >
        {forumData?.length &&
          forumData?.map((item) => (
            <ListItem
              key={item.id}
              sx={{
                display: "flex",
                padding: "var(--Spacing-spacing-24, 24px)",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
                alignSelf: "stretch",
                borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
                backgroundColor: insideCardBackgroundColorLayer1,
                boxShadow:
                  "var(--Elevation-X-Elevation-01, 0px) var(--Elevation-Y-Elevation-01, 0px) var(--Elevation-Blur-Elevation-01, 24px) var(--Elevation-Spread-Elevation-01, 0px) var(--Elevation-Colors-Elevation-01, rgba(0, 92, 188, 0.08))",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingBottom: "8px",
                  gap: "16px",
                }}
              >
                <img
                  src={item?.thumbnailUrl}
                  style={{ width: "64px", height: "56px" }}
                  alt="forum Item "
                />
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontWeight: "700",
                        color: isDarkTheme
                          ? darkTheme.textColorLayer1
                          : lightTheme.textColorLayer1,
                      }}
                    >
                      {item?.title}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{
                        display: "inline",
                        color: isDarkTheme
                          ? darkTheme.textColorLayer2
                          : lightTheme.textColorLayer2,
                      }}
                    >
                      {item?.description}
                    </Typography>
                  }
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  width: "100%",
                }}
                onClick={() => handleNavigate(item)}
              >
                <Button
                  sx={{
                    display: "flex",
                    height: "var(--Spacing-spacing-32, 32px)",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px",
                    borderRadius:
                      "var(--Corner-radius-corner-radius-large, 24px)",
                    border:
                      "var(--Border-border-1, 1px) solid var(--Tokens-Button-Outlined, #005CBC)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--Tokens-Button-Borderless, #005CBC)",
                      fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                      fontSize: "var(--Font-Size-Small, 14px)",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "var(--Line-Height-Small, 20px)",
                      textTransform: "none",
                    }}
                  >
                    Learn More
                  </Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99967 3.33337L8.82467 4.50837L13.4747 9.16671H3.33301V10.8334H13.4747L8.82467 15.4917L9.99967 16.6667L16.6663 10L9.99967 3.33337Z"
                      fill="#005CBC"
                    />
                  </svg>
                </Button>
              </Box>
            </ListItem>
          ))}
      </List>
    </Box>
  );
};

ListComponent.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ListComponent;
