import { Box, Switch, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DrawerHeader from "../SpecialProject/DrawerHeader";
import { useSelector, useDispatch } from "react-redux";
import ListOfDropDown from "./ListOfDropDown";
import useThemeRender from "../../../../theme/useThemeRender";
import { darkTheme } from "../../../../theme/Theme";
import {
  setListOfFieldValueDescription,
  setListOfValueName,
  setListOfValueSelectedCountry,
  setlistOfValueSelectedSector,
  setListOfValueStatus,
} from "../../../../slices/adminPageSlice";
import "../../../DocumentScreen//MobileScreenDocument.css";
import { getAPI, patchAPI, postAPI } from "../../../../API/ApiService";
import { BASE_URL } from "../../../../constant/apiUrl";
import { setFavourite } from "../../../../slices/internalPageSlice";
import axios from "axios";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../utils/Toast/toast";

function ListOfValuesSideTray({ handleResetData }) {
  const {
    listOfValueSideTrayName,
    listOfValueName,
    listOfValueStatus,
    listOfFieldValueDescription,
    listOfValuesEndPoint,
    selectedListOfRowValue,
    listOfValueSelectedSector,
    listOfValueSelectedCountry,
  } = useSelector((store) => store.adminpage);
  const dispatch = useDispatch();
  const token = localStorage.getItem("auth_token");
  const [sectorOption, setSectorOption] = useState([]);
  const [originalSectorOption, setOriginalSectorOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const [originalCountryOption, setOriginalCountryOption] = useState([]);
  const [countryId, setCountryId] = useState([]);
  const isDarkTheme = useSelector((state) => state.theme);
  const handleChange = (e) => {
    let value = e.target.value;
    value = value.replace(/^\s+/, "");

    if (value.length > 0) {
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }
    dispatch(setListOfValueName(value));
  };
  const handleToggle = () => {
    dispatch(setListOfValueStatus(!listOfValueStatus));
  };
  const handleChangeDescription = (e) => {
    let value = e.target.value;
    value = value.replace(/^\s+/, "");

    if (value.length > 0) {
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }
    dispatch(setListOfFieldValueDescription(value));
  };
  const outlinedInputStyles = (isDarkTheme, darkTheme) => ({
    backgroundColor: isDarkTheme ? darkTheme.backgroundColorLayer1 : "white",
    height: "40px",
    "& fieldset": getFieldsetStyles(isDarkTheme),
    "&:hover fieldset": getHoverFieldsetStyles(isDarkTheme),
    "&.Mui-focused fieldset": getFocusedFieldsetStyles(isDarkTheme),
  });

  const getFieldsetStyles = (isDarkTheme) => ({
    borderColor: isDarkTheme ? "white" : " ",
    color: "white",
  });

  const getHoverFieldsetStyles = (isDarkTheme) => ({
    borderColor: isDarkTheme ? "white" : " ",
  });

  const getFocusedFieldsetStyles = (isDarkTheme) => ({
    borderColor: isDarkTheme ? "white" : " ",
  });
  const textColorLayer4 = useThemeRender("textColorLayer4");
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const textColorLayer1 = useThemeRender("textColorLayer1");
  const { favourite } = useSelector((store) => store.internalPage);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleAddCountrySector = async (newData) => {
    try {
      const response = await patchAPI(
        `${BASE_URL}/lookup/update-country-sector`,
        newData
      );
      if (response.status === 200) {
        handleResetData();
        dispatch(setFavourite(favourite + 1));
        showSuccessToast("You have successfully added a value");
      } else if (response?.status === 404) {
        showErrorToast("Already Exist");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const handleAddOtherLOV = async (newData) => {
    if (listOfValueName?.length === 0) {
      showErrorToast("Name field is required");
    } else if (listOfValueName?.length > 0) {
      try {
        const result = await postAPI(`${BASE_URL}/lookup/save-item`, newData);
        if (result?.status === 200) {
          handleResetData();
          dispatch(setFavourite(favourite + 1));

          showSuccessToast("You have successfully added a value");
        } else if (result?.status === 404) {
          showErrorToast("Already Exist");
        } else if (result?.status === 400) {
          const errors = result?.data;
          Object.keys(errors).forEach((key) => {
            showErrorToast(errors[key]);
          });
        }
      } catch (error) {
        console.log("Error submitting form:", error);
      }
    }
  };
  const addValue = () => {
    if (listOfValuesEndPoint === "country-sector") {
      const sectorName = originalSectorOption?.find(
        (item) => item?.name === listOfValueSelectedSector
      )?.id;
      const countryName = originalCountryOption?.find(
        (item) => item?.name === listOfValueSelectedCountry
      )?.id;
      if (sectorName && countryName) {
        const newData = {
          countryId: countryName,
          sectorId: sectorName,
          active: true,
        };
        handleAddCountrySector(newData);
      }
    } else {
      const newData = {
        tableName: listOfValuesEndPoint,
        name: listOfValueName,
        description: listOfFieldValueDescription,
        active: true,
      };
      if (newData) {
        handleAddOtherLOV(newData);
      }
    }
  };

  const handleEditInsideCountrySector = async (newData) => {
    try {
      const response = await patchAPI(
        `${BASE_URL}/lookup/update-country-sector`,
        newData
      );
      if (response.status === 200) {
        handleResetData();
        dispatch(setFavourite(favourite + 1));
        showSuccessToast("You have successfully updated the value");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const handleEditCountrySector = async () => {
    const sectorId = originalSectorOption?.find(
      (item) => item?.name === listOfValueSelectedSector
    )?.id;
    const countryId = originalCountryOption?.find(
      (item) => item?.name === listOfValueSelectedCountry
    )?.id;
    if (sectorId && countryId) {
      const newData = {
        countryId: countryId,
        sectorId: sectorId,
        active: listOfValueStatus,
      };
      handleEditInsideCountrySector(newData);
    }
  };
  const handleEditOtherLOV = async (newData) => {
    if (listOfValueName?.length === 0) {
      showErrorToast("Name field is required");
    } else if (listOfValueName?.length > 0) {
      try {
        const result = await axios.patch(
          `${BASE_URL}/lookup/update-item/${selectedListOfRowValue?.id}`,
          newData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        if (result?.status === 200) {
          showSuccessToast("You have successfully updated the value");
          handleResetData();
          dispatch(setFavourite(favourite + 1));
        } else if (result?.status === 404) {
          showErrorToast("Already Exist");
        } else if (result?.status === 400) {
          const errors = result?.data;
          Object.keys(errors).forEach((key) => {
            showErrorToast(errors[key]);
          });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };
  const editValue = () => {
    if (listOfValuesEndPoint === "country-sector") {
      handleEditCountrySector();
    } else {
      const newData = {
        tableName: listOfValuesEndPoint,
        name: listOfValueName,
        description: listOfFieldValueDescription,
        active: listOfValueStatus,
      };
      if (newData) {
        handleEditOtherLOV(newData);
      }
    }
  };

  const handleAddValue = () => {
    if (listOfValueSideTrayName === "Add value") {
      addValue();
    } else if (listOfValueSideTrayName === "Edit value") {
      editValue();
    }
  };

  useEffect(() => {
    if (selectedListOfRowValue !== "") {
      if (listOfValuesEndPoint === "country-sector") {
        dispatch(
          setlistOfValueSelectedSector(selectedListOfRowValue?.sector?.name)
        );
        dispatch(setListOfValueSelectedCountry(selectedListOfRowValue?.name));
        setCountryId(selectedListOfRowValue?.id);
      } else {
        dispatch(setListOfValueName(selectedListOfRowValue.name));
        dispatch(
          setListOfFieldValueDescription(selectedListOfRowValue.description)
        );
        dispatch(setListOfValueStatus(selectedListOfRowValue?.active));
      }
    }

    console.log(selectedListOfRowValue, "selectedListOfRowValue");
  }, [selectedListOfRowValue]);

  useEffect(() => {
    const fetchSectorData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=SECTOR`
        );
        if (response?.status === 200) {
          const namesArray = response?.data.map((item) => item.name);
          setSectorOption(namesArray);
          setOriginalSectorOption(response?.data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchCountryData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=COUNTRY`
        );
        if (response?.status == 200) {
          const namesArray = response?.data.map((item) => item.name);
          setCountryOption(namesArray);
          setOriginalCountryOption(response?.data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchSectorData();
    fetchCountryData();
  }, []);
  return (
    <>
      <Box sx={{ mt: "18px", height: "90%" }}>
        <DrawerHeader
          header={listOfValueSideTrayName}
          handleResetData={handleResetData}
        />
        {listOfValuesEndPoint === "country-sector" ? (
          <>
            <Box sx={{ padding: "10px" }}>
              <ListOfDropDown
                options={sectorOption}
                label={"sector"}
                value={"ListofValueSector"}
              />
            </Box>
            <Box sx={{ padding: "10px" }}>
              <ListOfDropDown
                options={countryOption}
                label={"Country"}
                value={"ListofValueCountry"}
              />
            </Box>
          </>
        ) : (
          <>
            <Typography
              sx={{
                color: textColorLayer2,
              }}
              className="listOfValue-custom-text-field-label"
              variant="h5"
            >
              Name
            </Typography>
            <TextField
              placeholder="Type here"
              size="small"
              fullWidth
              className="listOfValue-custom-text-input"
              sx={{
                "& .MuiInputBase-input::placeholder": {
                  color: textColorLayer4,
                },
                "& .MuiOutlinedInput-root": outlinedInputStyles(
                  isDarkTheme,
                  darkTheme
                ),
                "& .MuiInputBase-input": {
                  color: textColorLayer1,
                },
              }}
              value={listOfValueName}
              onChange={handleChange}
            />
            <Typography
              sx={{
                color: textColorLayer2,
                mt: "10px",
              }}
              className="listOfValue-custom-text-field-label"
              variant="h5"
            >
              Description
            </Typography>
            <TextField
              placeholder="Type here"
              size="small"
              fullWidth
              sx={{
                "& .MuiInputBase-input::placeholder": {
                  color: textColorLayer4,
                },
                "& .MuiOutlinedInput-root": outlinedInputStyles(
                  isDarkTheme,
                  darkTheme
                ),
                "& .MuiInputBase-input": {
                  color: textColorLayer1,
                },
              }}
              className="listOfValue-custom-text-input"
              value={listOfFieldValueDescription}
              onChange={handleChangeDescription}
            />
          </>
        )}
        {listOfValueSideTrayName === "Edit value" &&
          listOfValuesEndPoint !== "country-sector" && (
            <Box
              sx={{
                display: "flex",
                height: "32px",
                alignItems: "center",
                gap: "var(--Spacing-spacing-03, 8px)",
                mt: "5px",
                mb: "5px",
                paddingX: "10px",
              }}
            >
              <Box
                sx={{
                  color: textColorLayer4,
                  fontFeatureSettings: "'liga' off, 'clig' off",
                  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                  fontSize: "var(--Font-Size-XSmall, 12px)",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "var(--Line-Height-XSmall, 18px)",
                }}
              >
                Active value
              </Box>
              <Switch checked={listOfValueStatus} onChange={handleToggle} />
            </Box>
          )}
      </Box>
      <Box className="ButtonBox">
        <Box
          sx={{
            border:
              "var(--Border-border-1, 1px) solid var(--Tokens-Button-Outlined, #005CBC)",
          }}
          className="ButtonStyle"
          onClick={handleResetData}
        >
          <Typography
            sx={{
              color: "#005CBC",
              fontWeight: 700,
            }}
            className="textStyle"
          >
            Cancel
          </Typography>
        </Box>
        <Box
          sx={{
            background: "var(--Tokens-Button-Primary, #005CBC)",
          }}
          className="ButtonStyle"
          onClick={handleAddValue}
        >
          <Typography
            sx={{
              color: "#fff",
              fontWeight: 700,
              p: "5px 10px",
            }}
            className="textStyle"
          >
            {listOfValueSideTrayName === "Edit value"
              ? "Save value"
              : "Add value"}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default ListOfValuesSideTray;
