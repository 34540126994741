import React from "react";
import { Box, Typography } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useSelector } from "react-redux";
import { darkTheme } from "../../../../../theme/Theme";

export default function FilesList({ files = [] }) {
  const isDarkTheme = useSelector((state) => state.theme);
  console.log(files, "--fileeeeName-----------");

  const handleDownLoad = (id) => {
    const downloadFileId = files.filter((item) => item.id === id);
    window.open(downloadFileId[0]?.url, "_blank");
  };
  return (
    <>
      {files?.map((file) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
          key={file.id}
        >
          <Box
            sx={{
              display: "flex",
              padding: "8px 16px",
              flexDirection: "row",
              gap: "12px",
              alignItems: "center",
            }}
          >
            <InsertDriveFileOutlinedIcon sx={{ fill: "#005CBC" }} />
            <Typography
              sx={{
                color: isDarkTheme ? darkTheme.textColorLayer1 : "#3A3A3A",
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18px",
              }}
            >
              {file.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "4px",
              cursor: "pointer",
            }}
            onClick={() => handleDownLoad(file.id)}
          >
            <Typography
              sx={{
                color: "var(--Tokens-Link-Default, #005CBC)",
                fontFeatureSettings: "'liga' off, 'clig' off",
                fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                fontSize: "var(--Font-Size-XSmall, 12px)",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "var(--Line-Height-XSmall, 18px)", // 150%
              }}
            >
              View Document
            </Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                d="M8.00033 3.03357L7.06033 3.97357L10.7803 7.70024H2.66699V9.03357H10.7803L7.06033 12.7602L8.00033 13.7002L13.3337 8.3669L8.00033 3.03357Z"
                fill="#005CBC"
              />
            </svg>
          </Box>
        </Box>
      ))}
    </>
  );
}
