import React from "react";
import SustainabilitySubpage from "./SustainabilitySubpage";
import { setPivot } from "../slices/sustainabilityPageSlice";
import { useSelector } from "react-redux";
function PivotAuditTootkit() {
  const { pivotAudit } = useSelector((store) => store.sustainable);
  return (
    <SustainabilitySubpage
      urlName={"Pivot%20Audit%20Toolkit"}
      header={"Pivot audit toolkit"}
      action={setPivot}
      globalState={pivotAudit}
    />
  );
}

export default PivotAuditTootkit;
