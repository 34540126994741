import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../../theme/Theme";
import { setDisplay } from "../../../slices/adminPageSlice";
import { Typography } from "@mui/material";

export default function DefaultPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDisplay(false));
  }, []);
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <div
      style={{
        color: isDarkTheme
          ? darkTheme.textColorLayer1
          : lightTheme.textColorLayer1,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        padding: "4rem",
      }}
    >
      <Typography
        sx={{
          color: "var(--Grayscale-PepsiCo-Black, #27251F)",
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
          fontSize: "var(--Font-Size-H5, 24px)",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "var(--Line-Height-H5, 32px)",
        }}
      >
        This section does not have any items to display, yet!
      </Typography>
    </div>
  );
}
