import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import useThemeRender from "../../theme/useThemeRender";
import CustomSelect from "./CustomSelect";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import { useDispatch } from "react-redux";
import { setDisableNextButton } from "../../slices/adminPageSlice";
import { getActiveItems, getDropDownOptionValues } from "../../utils/function";
const StepTwoForm = ({ internalData, setInternalData, handleDropDown }) => {
  const isDarkTheme = useSelector((state) => state.theme);
  const [sectorData, setSectorData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [visibilityData, setvisibilityData] = useState([]);
  const [specialProjectData, setSpecialProjectData] = useState([]);
  const [contentLevels, setContentLevels] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {
    sectorFilter,
    countryFilter,
    subjectFilter,
    visibility,
    contentLevel,
    specialProjects,
  } = useSelector((store) => store.internalFilter);
  const sectionStyles = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: isDarkTheme
      ? darkTheme.cardBackgroundColorLayer1
      : lightTheme.cardBackgroundColorLayer1,
    boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
  };
  const dispatch = useDispatch();
  useEffect(() => {
    setSectorData(getDropDownOptionValues(sectorFilter));
    setSubjectData(getDropDownOptionValues(subjectFilter));
    setCountryData(getDropDownOptionValues(countryFilter));
    setvisibilityData(getDropDownOptionValues(visibility));
    setSpecialProjectData(getDropDownOptionValues(specialProjects));
    setContentLevels(getDropDownOptionValues(contentLevel));
  }, []);
  const handleSelect = (e, name) => {
    const ids = e.map((item) => item.id);
    handleDropDown(name, ids);
  };
  const [selectedSectorData, setSelectedSectorData] = useState([]);
  const [selectedCountryData, setSelectedCountryData] = useState([]);
  const [selectedSubjectData, setSelectedSubjectData] = useState([]);
  const [selectedSpecialProjectData, setSelectedSpecialProjectData] = useState(
    []
  );

  useEffect(() => {
    const selectedData = sectorData?.filter((item) =>
      internalData?.sectorIds?.includes(item?.id)
    );
    setSelectedSectorData(selectedData);
  }, [internalData?.sectorIds, sectorData]);
  useEffect(() => {
    const selectedData = specialProjectData?.filter((item) =>
      internalData.specailProjects?.includes(item?.id)
    );
    setSelectedSpecialProjectData(selectedData);
  }, [internalData.specailProjects, specialProjectData]);
  useEffect(() => {
    const selectedData = subjectData?.filter((item) =>
      internalData?.subjectIds?.includes(item?.id)
    );
    setSelectedSubjectData(selectedData);
  }, [internalData?.subjectIds, subjectData]);
  useEffect(() => {
    const selectedData = countryData?.filter((item) =>
      internalData?.countryIds?.includes(item?.id)
    );
    setSelectedCountryData(selectedData);
  }, [internalData?.countryIds, countryData]);

  const handleTitleChange = (e) => {
    const title = e.target.value;
    setInternalData((prevData) => ({
      ...prevData,
      contentTitle: title,
    }));
  };

  const handleVisibilityChange = (e) => {
    const newVisibility = e.target.value;
    setInternalData((prevData) => ({
      ...prevData,
      visibilityId: Number(newVisibility),
    }));
  };
  const handleContentLevelChange = (e) => {
    const newContentLevel = e.target.value;
    setInternalData((prevData) => ({
      ...prevData,
      contentLevelId: Number(newContentLevel),
    }));
  };

  useEffect(() => {
    if (
      (internalData?.subjectIds?.length ?? 0) > 0 &&
      (internalData?.countryIds?.length ?? 0) > 0 &&
      (internalData?.sectorIds?.length ?? 0) > 0 &&
      (internalData?.contentTitle?.length ?? 0) > 0 
    ) {
      dispatch(setDisableNextButton(false));
    } else {
      dispatch(setDisableNextButton(true));
    }
  }, [
    internalData?.subjectIds,
    internalData?.countryIds,
    internalData?.sectorIds,
    internalData.contentTitle,
    internalData?.visibilityId,
  ]);

  useEffect(() => {
    if (
      (internalData?.subjectIds?.length === 0 || !internalData?.subjectIds) &&
      (internalData?.countryIds?.length === 0 || !internalData?.countryIds) &&
      (internalData?.sectorIds?.length === 0 || !internalData?.sectorIds) &&
      (internalData?.visibilityId?.length === 0 || !internalData?.visibilityId) &&
      (internalData?.contentTitle?.length === 0 || !internalData?.contentTitle)
    ) {
      dispatch(setDisableNextButton(true));
    }
  }, []);

  const shouldShowSpecialProjects = () => {
    console.log("visibility", visibility, internalData);
    let seletedVisibility = [];
    seletedVisibility = visibility?.filter(
      (item) => item?.id === parseInt(internalData?.visibilityId)
    );
    console.log("seletedVisibility", seletedVisibility);
    return seletedVisibility?.[0]?.name === "Special projects" ? true : false;
  }
  
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        ...sectionStyles,
      }}
    >
      <Typography
        sx={{
          color: useThemeRender("textColorLayer1"),
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "32px",
          mb: 4,
        }}
      >
        Step 2: Metadata
      </Typography>
      <Box>
        <Typography
          sx={{
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : lightTheme.textColorLayer1,
          }}
        >
          Content title
        </Typography>
        <TextField
          placeholder="Add Title"
          value={internalData?.contentTitle}
          onChange={handleTitleChange}
          size="small"
          sx={{
            borderRadius: "4px",
            width: { xs: "300px", md: "400px" },
            "& .MuiInputBase-input::placeholder": {
              color: isDarkTheme
                ? darkTheme.textColorLayer4
                : lightTheme.textColorLayer4,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
              "&:hover fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
              "&.Mui-focused fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
            },
            "& .MuiInputBase-input": {
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            },
          }}
        />
      </Box>

      <Box>
        <FormControl component="fieldset" fullWidth>
          <FormLabel
            sx={{
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            }}
          >
            Visibility
          </FormLabel>
          <RadioGroup
          //  value={Number(internalData?.visibilityId)}
            value={parseInt(internalData?.visibilityId)}
            onChange={handleVisibilityChange}
            sx={{
              flexDirection: { xs: "column", md: "row", gap: 26 },
            }}
          >
            {visibilityData?.map((visibility) => (
              <FormControlLabel
                value={visibility?.id}
                control={
                  <Radio
                    sx={{
                      color: isDarkTheme
                        ? darkTheme.radioButtonLayer1
                        : lightTheme.radioButtonLayer1,
                    }}
                  />
                }
                label={visibility?.name}
                sx={{
                  color: isDarkTheme
                    ? darkTheme.textColorLayer1
                    : lightTheme.textColorLayer1,
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>

      {shouldShowSpecialProjects() && (
        <CustomSelect
          label="Special projects"
          value={selectedSpecialProjectData}
          onChange={(e) => handleSelect(e, "specailProjects")}
          options={specialProjectData}
          required={false}
        />
      )}

      <CustomSelect
        label="Subjects"
        value={selectedSubjectData}
        onChange={(e) => handleSelect(e, "subjectIds")}
        options={subjectData}
        required={true}
      />

      <CustomSelect
        label="Sectors"
        value={selectedSectorData}
        onChange={(e) => handleSelect(e, "sectorIds")}
        options={sectorData}
        required={true}
      />
      <CustomSelect
        label="Countries"
        value={selectedCountryData}
        onChange={(e) => handleSelect(e, "countryIds")}
        options={countryData}
        required={true}
      />

      <Box>
        <FormControl component="fieldset" fullWidth>
          <FormLabel
            sx={{
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            }}
          >
            Content Level
          </FormLabel>
          <RadioGroup
            name="content-level"
            value={Number(internalData?.contentLevelId)}
            onChange={handleContentLevelChange}
            sx={{ display: "flex", flexDirection: "row", gap: 2 }}
          >
            {contentLevels?.map((level) => (
              <FormControlLabel
                key={level.id}
                value={level.id}
                control={<Radio />}
                label={level.name}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default StepTwoForm;
