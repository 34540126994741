import {
  Box,
  Button,
  Card,
  Drawer,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useThemeRender from "../../theme/useThemeRender";
import "./ViewSummary.css";
import { increaseViewCount } from "../../utils/function";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSummaryButton,
  setViewSummaryDrawer,
} from "../../slices/viewSummarySlice";

import { getAPI, patchAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import ViewSummaryDetail from "./ViewSummaryDetail";
import Loading from "../../utils/Loader";
import { setAlert } from "../../slices/spotlightSlice";
import { darkTheme } from "../../theme/Theme";
function ViewSummary() {
  const [drawerHeight, setDrawerHeight] = useState("90%");
  const isMobile = useMediaQuery("(max-width:400px)");
  const isDarkTheme = useSelector((store) => store.theme);
  const { viewSummaryDrawer, viewPageName, summaryDetails, displayButton } =
    useSelector((store) => store.viewSummary);
  console.log(summaryDetails, "------summary---------");
  const { selectedGlobalState, selectedAction } = useSelector(
    (store) => store.internalPage
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageData, setPageData] = useState();
  const dispatch = useDispatch();
  const handleResizeStart = (e) => {
    e.preventDefault();

    const handleResize = (moveEvent) => {
      const currentY = moveEvent.clientY || moveEvent.touches[0].clientY;
      const newHeight = `calc(100% - ${currentY}px)`;
      console.log(newHeight, "newHeight");
      setDrawerHeight(newHeight);
    };

    const stopResize = () => {
      window.removeEventListener("mousemove", handleResize);
      window.removeEventListener("mouseup", stopResize);
      window.removeEventListener("touchmove", handleResize);
      window.removeEventListener("touchend", stopResize);
    };

    window.addEventListener("mousemove", handleResize);
    window.addEventListener("mouseup", stopResize);
    window.addEventListener("touchmove", handleResize);
    window.addEventListener("touchend", stopResize);
  };
  const userId = 1;
  const contentId = summaryDetails?.contentId
    ? summaryDetails?.contentId
    : summaryDetails?.id;
  const fetchData = async () => {
    setLoading(true);
    if (contentId !== undefined) {
      try {
        const response = await getAPI(
          `${BASE_URL}/content/${contentId}/user/${userId}`
        );
        if (response.status === 200) {
          console.log("adityaaaaaaaaaaaa", response?.data);

          setPageData({
            ...response?.data,
            thumbnailUrl: summaryDetails.thumbnailUrl,
          });
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [contentId]);
  const navigate = useNavigate();
  const addViewCount = async (contentId, goTo, pageName) => {
    setLoading(true);
    try {
      const response = await patchAPI(`${BASE_URL}/content/${contentId}`, {});
      if (response?.status === 200) {
        const data = { ...summaryDetails, content: viewPageName };
        navigate(goTo, { state: data });
        setLoading(false);
        // const updated = increaseViewCount(
        //   selectedGlobalState,
        //   contentId,
        //   pageName
        // );

        // dispatch(selectedAction(updated));
        dispatch(setViewSummaryDrawer(false));
      }
    } catch (err) {
      setError(err.message);
    } finally {
    }
  };
  const handleNavigate = () => {
    if (viewPageName == "Links") {
      const contentId = summaryDetails?.contentId
        ? summaryDetails?.contentId
        : summaryDetails?.id;
      addViewCount(contentId, "/link", "Links");
    } else if (viewPageName == "Documents") {
      const contentId = summaryDetails?.contentId
        ? summaryDetails?.contentId
        : summaryDetails?.id;
      addViewCount(contentId, "/document", "Documents");
    } else if (viewPageName == "Videos") {
      console.log(viewPageName);
    }
  };
  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={viewSummaryDrawer}
      onClose={() => dispatch(setViewSummaryDrawer(false))}
      PaperProps={{
        sx: {
          height: isMobile ? drawerHeight : "100%",
          overflowY: "auto",
          maxWidth: "400px",
          minWidth: "300px",
        },
      }}
      ModalProps={{
        BackdropProps: {
          sx: {
            backgroundColor: useThemeRender("drawerOverlayColorLayer1"),
          },
        },
      }}
    >
      {loading && <Loading />}
      {isMobile && (
        <Box
          sx={{
            width: "36px",
            height: "5px",
            background:
              "linear-gradient(0deg, rgba(61, 61, 61, 0.5) 0%, rgba(61, 61, 61, 0.5) 100%), rgba(127, 127, 127, 0.4)",
            backgroundBlendMode: "overlay, luminosity",
            borderRadius: "2.5px",
            position: "absolute",
            top: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            cursor: "ns-resize",
            zIndex: 1,
          }}
          onMouseDown={handleResizeStart}
          onTouchStart={handleResizeStart}
        />
      )}
      <Box
        sx={{
          display: "flex",
          padding: "16px",
          paddingTop: { xs: "16px" },
          paddingBottom: "24px",
          flexDirection: "column",
          gap: "var(--Spacing-spacing-24, 24px)",
          margin: 0,
          backgroundColor: useThemeRender("backgroundColorLayer2"),
        }}
      >
        <IconButton
          onClick={() => {
            dispatch(setSummaryButton(false));
            dispatch(setViewSummaryDrawer(false));
          }}
          sx={{
            position: "absolute",
            top: "16px",
            right: "16px",
            zIndex: 10,
          }}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
              fill={useThemeRender("iconColor1")}
            />
          </svg>
        </IconButton>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "flex-start", sm: "space-between" },
            alignItems: { xs: "flex-start", sm: "flex-start" },
            gap: "16px",
            alignSelf: { xs: "stretch", sm: "auto" },
            flexDirection: { xs: "column" },
            marginTop: "36px",
            width: "100%",
            padding: "0px 10px",
            paddingBottom: "10px",
          }}
        >
          <Typography
            sx={{
              color: useThemeRender("textColorLayer2"),
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
              fontSize: "var(--Font-Size-H4, 28px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-H4, 40px)",
            }}
          >
            {summaryDetails.title}
          </Typography>
          <Box onClick={() => handleNavigate()} style={{ width: "100%" }}>
            <Button
              sx={{
                display: "flex",
                width: "100%",
                height: "40px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "stretch",
                borderRadius: "var(--Corner-radius-effect-corner-3, 24px)",
                background: "var(--Tokens-Button-Primary, #005CBC)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "var(--Spacing-spacing-40, 40px)",
                  padding:
                    "var(--Spacing-spacing-03, 8px) var(--Spacing-spacing-05, 24px)",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "var(--Spacing-spacing-03, 8px)",
                }}
              >
                <Typography
                  sx={{
                    color: "var(--Colors-White, #FFF)",
                    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                    fontSize: "var(--Font-Size-Body, 16px)",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "var(--Line-Height-Body, 22px)",
                    textTransform: "none",
                  }}
                >
                  Go to details
                </Typography>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                    fill="white"
                  />
                </svg>
              </Box>
            </Button>
          </Box>
        </Box>
        <Box
          className="DetailCard"
          sx={{
            background: useThemeRender("backgroundColorLayer1"),
          }}
        >
          <Typography
            className="SummaryTitle"
            sx={{
              color: useThemeRender("textColorLayer2"),
            }}
          >
            Summary
          </Typography>
          <Typography
            className="DetailContents"
            sx={{
              color: useThemeRender("textColorLayer2"),
            }}
          >
            {pageData?.summary}
          </Typography>
        </Box>
        <ViewSummaryDetail pageData={pageData} viewPageName={viewPageName} />
        <Box
          className="DetailCard"
          sx={{
            background: useThemeRender("backgroundColorLayer1"),
          }}
        >
          <Typography
            className="SummaryTitle"
            sx={{
              color: useThemeRender("textColorLayer2"),
            }}
          >
            Disclaimer
          </Typography>
          <Box className="SummaryContentBox">
            <Typography
              className="DetailContents"
              sx={{
                color: useThemeRender("textColorLayer2"),
              }}
            >
              {pageData?.disclaimer}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "sticky",
          bottom: "0px",

          height: "var(--Spacing-spacing-64, 64px)",
          padding:
            "var(--Spacing-spacing-04, 16px) var(--Spacing-spacing-05, 24px)",
          alignSelf: "stretch",
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer2
            : "var(--Tokens-Background-Layer-01, #FBFCFE)",
          boxShadow: displayButton
            ? "0px 1px 0px 0px var(--Tokens-Border-Divider, #DFDFDF) inset"
            : "",
        }}
      >
        {displayButton && (
          <div className=" bg-white ">
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                flexDirection: "row",
                gap: "8px",
              }}
            >
              <Button
                sx={{
                  display: "flex",
                  height: "32px", // Using the fallback value
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "24px", // Using the fallback value
                  border: "1px solid #005CBC", // Using the fallback value
                  textTransform: "none",
                }}
                onClick={() => {
                  dispatch(setViewSummaryDrawer(false));
                  dispatch(setSummaryButton(false));
                }}
              >
                <Typography
                  sx={{
                    color: "#005CBC", // Fallback value
                    fontFamily: "Inter", // Fallback value
                    fontSize: "14px", // Fallback value
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "20px", // Fallback value
                  }}
                >
                  Cancel
                </Typography>
              </Button>
              <Button
                sx={{
                  display: "flex",
                  height: "32px", // Using the fallback value
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "24px", // Using the fallback value
                  border: "1px solid #005CBC", // Using the fallback value
                  textTransform: "none",
                  backgroundColor: "#005CBC",
                }}
                onClick={() => {
                  dispatch(setViewSummaryDrawer(false));
                  dispatch(setSummaryButton(false));
                  dispatch(setAlert(true));
                }}
              >
                <Typography
                  sx={{
                    color: "#fff", // Fallback value
                    fontFamily: "Inter", // Fallback value
                    fontSize: "14px", // Fallback value
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "20px", // Fallback value
                  }}
                >
                  Use this item
                </Typography>
              </Button>
            </Box>
          </div>
        )}
      </Box>
    </Drawer>
  );
}

export default ViewSummary;
