// components/AvatarComponent.js
import Box from "@mui/material/Box";
import { Icon, Avatar } from "pepsico-ds";
import AvatarImg from "./../../../assets/Avatar.png";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
const AvatarComponent = () => {
  const { flagUser } = useSelector((store) => store.user);
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Box>
      <Icon
        alt="User avatar"
        svg={
          <Avatar
            type="image"
            imageUrl={AvatarImg}
            name="Jon Do"
            size="small"
            onClick={() => {}}
            style={{pointerEvents: "none"}}
          />
        }
      />
    </Box>
  );
};

export default AvatarComponent;
