import React from "react";
import useFetchInternal from "../hooks/useFetchInternal";
import InternalPageLayout from "../components/InternalPages/InternalPageLayout";
import { BASE_URL } from "../constant/apiUrl";

import Loading from "../utils/Loader";
import { useSelector } from "react-redux";
import { setPotatoVariety } from "../slices/internalPageSlice";

export default function PotatoVariety() {
  const { favourite, potatoVaritey } = useSelector(
    (store) => store.internalPage
  );
  const userId = 1;

  const { loading, pageData } = useFetchInternal(
    `${BASE_URL}/internal-pages/page-info/find-by-page-name?pageName=Potato%20Variety%20Information&userId=${userId}`,
    setPotatoVariety,
    potatoVaritey
  );

  return loading ? (
    <Loading />
  ) : (
    <InternalPageLayout pageData={pageData} header={"Potato variety"} />
  );
}
