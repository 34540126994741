import { Box } from "@mui/material";
import { BASE_URL } from "../constant/apiUrl";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAPI } from "../API/ApiService";
import Loading from "../utils/Loader";
import InternalCarousal from "./InternalPages/Carousal-ExpandedView/InternalCarousal";
import useThemeRender from "../theme/useThemeRender";
import { showErrorToast } from "../utils/Toast/toast";
import { setSpotlight } from "../slices/spotlightSlice";
import { urlTagging } from "../utils/function";
const SpotLight = () => {
  const [loading, setLoading] = useState(false);
  const backgroundColorLayer3 = useThemeRender("backgroundColorLayer3");

  const { favourite } = useSelector((store) => store?.internalPage);
  const { spotlight } = useSelector((store) => store.spotlight);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const response = await getAPI(`${BASE_URL}/spotlights`);
        if (response?.status === 200) {
          const fetch = response?.data;
          const data = await urlTagging(fetch, "thumbnailId");
          dispatch(setSpotlight(data));
        }
      } catch (err) {
        showErrorToast(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [favourite, dispatch]);
  const sortedData = [...(spotlight || [])].sort(
    (a, b) => a.slotNumber - b.slotNumber
  );
  if (loading) return <Loading />;

  return (
    <Box
      sx={{
        backgroundColor: backgroundColorLayer3,
        marginBottom: "16px",
      }}
      className="curosalBackground"
    >
      <InternalCarousal
        data={sortedData}
        content={"Documents"}
        flexDirection={"column"}
        heading={"In the Spotlight"}
        isHeadingBig={true}
      />
    </Box>
  );
};

export default SpotLight;
