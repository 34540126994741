import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  search: "",
  sector: "",
  country: "",
  variety: "",
  flashColor: "",
  ipOwnerShip: "",
  favourite: 1,
  favourites: [],
  potatoVaritey: [],
  potatoQuality: [],
  potatoStorage: [],
  globalTrials: [],
  selectedGlobalState: [],
  selectedAction: null,
  internalPageHeader: null,
  fetchFavourite: false,
};

const internalPageSlice = createSlice({
  name: "internalPage",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setSector: (state, action) => {
      state.sector = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setVariety: (state, action) => {
      state.variety = action.payload;
    },
    setFavourite: (state, action) => {
      state.favourite = action.payload;
    },
    setFlashColor: (state, action) => {
      state.flashColor = action.payload;
    },
    setIpOwnerShip: (state, action) => {
      state.ipOwnerShip = action.payload;
    },
    setPotatoVariety: (state, action) => {
      state.potatoVaritey = action.payload;
    },
    setPotatoQuality: (state, action) => {
      state.potatoQuality = action.payload;
    },
    setPotatoStorage: (state, action) => {
      state.potatoStorage = action.payload;
    },
    setGlobalTrials: (state, action) => {
      state.globalTrials = action.payload;
    },
    setGlobalSelectedState: (state, action) => {
      state.selectedGlobalState = action.payload;
    },
    setSelectedAction: (state, action) => {
      state.selectedAction = action.payload;
    },
    setFavourites: (state, action) => {
      state.favourites = action.payload;
    },
    setInternalPageHeader: (state, action) => {
      state.internalPageHeader = action.payload;
    },
    setFetchFavourtie: (state, action) => {
      state.fetchFavourite = !state.fetchFavourite;
    },
  },
});
export const {
  setSearch,
  setSector,
  setCountry,
  setVariety,
  setFavourite,
  setFlashColor,
  setIpOwnerShip,
  setPotatoVariety,
  setPotatoQuality,
  setPotatoStorage,
  setGlobalTrials,
  setGlobalSelectedState,
  setSelectedAction,
  setFavourites,
  setInternalPageHeader,
  setFetchFavourtie,
} = internalPageSlice.actions;
export default internalPageSlice.reducer;
