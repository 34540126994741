import React from "react";
import { Box, Button, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { darkTheme, lightTheme } from "../../theme/Theme";
import useThemeRender from "../../theme/useThemeRender";
import {
  setExternalFileDocument,
  setFileUploaded,
  setFileUploadPageName,
  setInternalFileDocument,
} from "../../slices/adminPageSlice";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useDispatch } from "react-redux";
const SuccessBox = ({ setActiveStep, success, setSuccess, text }) => {
  const navigate = useNavigate();
  const isDarkTheme = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const baseBoxStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "24px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    background: isDarkTheme
      ? darkTheme.cardBackgroundColorLayer1
      : lightTheme.cardBackgroundColorLayer1,
    boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
    width: "80%",
    margin: "5% auto",
  };
  const handleGoBackHome = () => {
    setSuccess(false);
    navigate("/");
    dispatch(setFileUploaded(false));
    dispatch(setInternalFileDocument(null));
    dispatch(setExternalFileDocument(null));
    dispatch(setFileUploadPageName(""));
  };
  const handleUploadMore = () => {
    setSuccess(false);
    setActiveStep(0);
    dispatch(setFileUploaded(false));
    dispatch(setInternalFileDocument(null));
    dispatch(setExternalFileDocument(null));
    dispatch(setFileUploadPageName(""));
  };

  const BackHandler = () => {
    navigate("/");
  };
  const textColor = useThemeRender("textColorLayer1");
  return (
    <Box sx={baseBoxStyle}>
      {text ? (
        <>
          <CloseOutlinedIcon sx={{ fontSize: 80, color: "red" }} />

          <Typography sx={{ color: textColor, fontWeight: 700 }}>
            YOU ARE NOT AUTHORIZED TO VIEW THIS PAGE
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              gap: 4,
              mt: 3,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{
                fontWeight: 700,
                padding: "8px 16px",
                borderRadius: "24px",
                textTransform: "none",
              }}
              onClick={BackHandler}
            >
              Go back to home
            </Button>
          </Box>
        </>
      ) : (
        <>
          {success?.error ? (
            <>
              <CloseOutlinedIcon sx={{ fontSize: 80, color: "red" }} />

              <Typography sx={{ mt: 8, color: textColor }}>
              You were unable to submit the content successfully. please wait
              for sometime and try again.
              </Typography>
            </>
          ) : (
            <>
              <CheckCircleIcon sx={{ fontSize: 80, color: "#4caf50" }} />
              <Typography sx={{ mt: 8, color: textColor }}>
                You have successfully submitted the content. A content curator
                will review. You can check your status in upload content <br />
                pending approvals.
              </Typography>
            </>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              gap: 4,
              mt: 3,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{
                fontWeight: 700,
                padding: "8px 16px",
                borderRadius: "24px",
                textTransform: "none",
              }}
              onClick={handleGoBackHome}
            >
              Go back to home
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontWeight: 700,
                padding: "8px 16px",
                borderRadius: "24px",
                textTransform: "none",
              }}
              onClick={handleUploadMore}
            >
              Upload more content
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default SuccessBox;
