import React, { useEffect } from "react";
import { Button } from "pepsico-ds";
import { Box, useMediaQuery } from "@mui/material";
import DividerComponent from "../CommonComponent/DividerComponent";
import TextInputField from "../../../../utils/Fields/TextInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import formValidations from "../ExternalAdminInvitationForm/formValidation";
import * as yup from "yup";
import axios from "axios";
import { BASE_URL } from "../../../../constant/apiUrl";
import {
  showSuccessToast,
  showErrorToast,
} from "../../../../utils/Toast/toast";
import { postAPI } from "../../../../API/ApiService";
import { useDispatch } from "react-redux";
import { setDisplay } from "../../../../slices/adminPageSlice";
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email address is required"),
});
export default function InternalForm() {
  const {
    register,
    formState,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useDispatch();
  const { errors } = formState;
  const isMobile = useMediaQuery("(max-width:600px)");
  const isSmallOrMedium = useMediaQuery("(max-width: 800px)");
  useEffect(() => {
    dispatch(setDisplay(false));
  }, []);
  const onFieldChange = (field, value) => {
    setValue(field, value); // Update the field value
    const validationErrors = formValidations(getValues("email"));

    if (validationErrors[field]) {
      setError(field, { type: "manual", message: validationErrors[field] });
    } else {
      clearErrors(field); // Clear the error if validation passes
    }
  };
  const onSubmit = async (data) => {
    try {
      const response = await postAPI(
        `${BASE_URL}/invitations/send-internal-user/${data.email}`
      );
      console.log(response);

      if (response?.status === 200) {
        if (response?.data?.status === "FAILURE") {
          showErrorToast("Failed to send invitation.");
        } else {
          showSuccessToast("Invitation sent successfully!");
          reset();
        }
      } else if (response?.status === 409) {
        showErrorToast("User Already Exist");
      } else {
        showErrorToast("Failed to send the invitation. Please try again.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      console.log("hello");
    }
  };
  return (
    <Box
      sx={{
        position: "relative", // Relative positioning for the container
        minHeight: "60vh", // Full viewport height to allow the form to take maximum height
        display: "flex", // Flexbox container
        flexDirection: "column", // Stack items vertically
        flexGrow: 1,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4 ">
          <TextInputField
            label="Email"
            errors={errors}
            register={register}
            name="email"
            width={isSmallOrMedium ? "100%" : "400px"}
            required={true}
            type="email"
            isMobile={isMobile}
            placeholder="Type here"
            onChange={(e) => onFieldChange("email", e.target.value)}
          />
          <DividerComponent />
        </div>
        <div
          className="absolute bottom-0 flex flex-col gap-4 "
          style={{
            position: "absolute", // Absolutely positioned at the bottom
            bottom: 0, // Pin to the bottom of the container
            width: "100%", // Full width of the container
          }}
        >
          <DividerComponent />
          <Box
            sx={{
              display: "flex",
              justifyContent: isMobile ? "center" : "flex-end",
              alignItems: isMobile ? "" : "flex-end",
              flexDirection: isMobile ? "column-reverse" : "row",
              gap: "24px",
              alignSelf: "stretch",
            }}
          >
            <Button
              size="large"
              text="Cancel"
              variant="secondary"
              type="reset"
              style={{
                width: isMobile ? "100%" : "",
                justifyContent: "center",
              }}
            />
            <Button
              size="large"
              text="Send Invitation"
              variant="primary"
              type="sumbit"
              style={{
                width: isMobile ? "100%" : "",
                justifyContent: "center",
              }}
            />
          </Box>
        </div>
      </form>
    </Box>
  );
}
