import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import useThemeRender from "../../../theme/useThemeRender";
import { formatDate } from "../../../utils/function";
import noPreviewImage from "../../../assets/no-preview.png";
function DeniedDetailArtifactManagement({ detailData }) {
  const cardBackgroundColorLayer2 = useThemeRender("cardBackgroundColorLayer2");
  const insideCardBackgroundColorLayer3 = useThemeRender(
    "insideCardBackgroundColorLayer3"
  );
  const textColorLayer4 = useThemeRender("textColorLayer4");
  const textColorLayer2 = useThemeRender("textColorLayer2");

  return (
    <Box>
      {detailData?.artifactList?.map((item) => (
        <Box
          sx={{
            background: cardBackgroundColorLayer2,
          }}
          className="custom-container"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Box
                className="DetailCardBox"
                sx={{
                  background: insideCardBackgroundColorLayer3,
                }}
              >
                <Typography
                  className="DetailCardGridFirstContent"
                  sx={{
                    color: textColorLayer4,
                  }}
                >
                  Version number
                </Typography>
                <Typography
                  className="DetailCardGridSecondContent"
                  sx={{
                    color: textColorLayer4,
                  }}
                >
                  {item?.versionNumber}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                className="DetailCardBox"
                sx={{
                  background: insideCardBackgroundColorLayer3,
                }}
              >
                <Typography
                  className="DetailCardGridFirstContent"
                  sx={{
                    color: textColorLayer4,
                  }}
                >
                  Uploaded by
                </Typography>
                <Typography
                  className="DetailCardGridSecondContent"
                  sx={{
                    color: textColorLayer4,
                  }}
                >
                  {item?.uploadedBy}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                className="DetailCardBox"
                sx={{
                  background: insideCardBackgroundColorLayer3,
                }}
              >
                <Typography
                  className="DetailCardGridFirstContent"
                  sx={{
                    color: textColorLayer4,
                  }}
                >
                  Upload date
                </Typography>
                <Typography
                  className="DetailCardGridSecondContent"
                  sx={{
                    color: textColorLayer4,
                  }}
                >
                  {formatDate(item?.uploadedDate)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Typography
            sx={{
              color: textColorLayer4,
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-Small, 14px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-Small, 20px)",
            }}
          >
            Thumbnail
          </Typography>
          <img src={noPreviewImage} style={{
            width: '360px',
            height:'160px'
          }}/>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "var(--Spacing-spacing-03, 8px)",
              paddingTop:'12px'
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M9.33268 1.33398H3.99935C3.26602 1.33398 2.67268 1.93398 2.67268 2.66732L2.66602 13.334C2.66602 14.0673 3.25935 14.6673 3.99268 14.6673H11.9993C12.7327 14.6673 13.3327 14.0673 13.3327 13.334V5.33398L9.33268 1.33398ZM3.99935 13.334V2.66732H8.66602V6.00065H11.9993V13.334H3.99935Z"
                fill="#0078A7"
              />
            </svg>
            <Typography
              sx={{
                color: textColorLayer2,
                fontFeatureSettings: "'liga' off, 'clig' off",
                fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                fontSize: "var(--Font-Size-XSmall, 12px)",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "var(--Line-Height-XSmall, 18px)",
              }}
            >
              {item?.fileName}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default DeniedDetailArtifactManagement;
