import React from "react";
import potato from "../../assets/potato.png";
import { Box, Grid, Typography } from "@mui/material";
import useThemeRender from "../../theme/useThemeRender";
import { formatDate } from "../../utils/function";
function ViewSummaryDetail({ pageData, viewPageName }) {
  const backgroundColorLayer1 = useThemeRender("backgroundColorLayer1");
  const textColorLayer4 = useThemeRender("textColorLayer4");
  const insideCardBackgroundColorLayer4 = useThemeRender(
    "insideCardBackgroundColorLayer4"
  );
  const textColorLayer1 = useThemeRender("textColorLayer1");

  console.log(pageData, "----pageData---------");

  return (
    <Box className="DetailCard">
      <Typography
        className="SummaryTitle"
        sx={{
          color: useThemeRender("textColorLayer2"),
          marginBottom: "16px",
        }}
      >
        Detail
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "var(--Spacing-spacing-12, 12px)",
          alignSelf: "stretch",
        }}
      >
        {pageData?.thumbnailUrl ? (
          <img
            src={pageData?.thumbnailUrl}
            alt="Card-specific "
            className="w-[246px] h-[103px]"
          />
        ) : (
          <img src={potato} alt="Fallback " className="w-[246px] h-[103px]" />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("backgroundColorLayer1"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                View count
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {pageData?.viewCount}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("backgroundColorLayer1"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Likes count
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {pageData?.likes}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("backgroundColorLayer1"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Content level
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {pageData?.contentLevel}
              </Typography>
            </Box>
          </Grid>
          {viewPageName == "Documents" && (
            <Grid item xs={12}>
              <Box
                className="DetailCardBox"
                sx={{
                  background: backgroundColorLayer1,
                }}
              >
                <Typography
                  className="DetailCardGridFirstContent"
                  sx={{
                    color: textColorLayer4,
                  }}
                >
                  Document type
                </Typography>
                <Typography
                  className="DetailCardGridSecondContent"
                  sx={{
                    color: textColorLayer4,
                  }}
                >
                  {pageData?.documentType}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("backgroundColorLayer1"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Language
              </Typography>
              {pageData?.language.map((val) => (
                <Typography
                  className="TagsContent"
                  key={val}
                  sx={{
                    color: textColorLayer1,
                    height: "auto",
                  }}
                >
                  {val}
                </Typography>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("backgroundColorLayer1"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Version
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {pageData?.version}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("backgroundColorLayer1"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {viewPageName == "Links" ? "Edited by" : "Edited by SME"}
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {pageData?.editedBy}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("backgroundColorLayer1"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {viewPageName == "Links" ? "Added date" : "Upload date"}
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {formatDate(pageData?.createdOn)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("backgroundColorLayer1"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {viewPageName == "Links" ? "Added by" : "Uploaded by"}
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {pageData?.createdBy}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("backgroundColorLayer1"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Reviewed date
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {formatDate(pageData?.reviewDate)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("backgroundColorLayer1"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Legal review
              </Typography>
              <Typography
                className="DetailCardGridSecondContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {pageData?.isLegalReview ? "Yes" : "No"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              className="DetailCardBox"
              sx={{
                background: useThemeRender("backgroundColorLayer1"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Subjects
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  border: "2px soid black",
                  gap: "var(--Spacing-spacing-12, 12px)",
                }}
              >
                {pageData?.subjects.map((val) => (
                  <Typography
                    className="TagsContent"
                    key={val}
                    sx={{
                      background: insideCardBackgroundColorLayer4,
                      color: textColorLayer1,
                      height: "auto",
                    }}
                  >
                    {val}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="DetailCardBox">
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Keywords
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  border: "2px soid black",
                  gap: "var(--Spacing-spacing-12, 12px)",
                }}
              >
                {pageData?.keywords.split(",").map((val) => (
                  <Typography
                    className="TagsContent"
                    key={val}
                    sx={{
                      background: insideCardBackgroundColorLayer4,
                      color: textColorLayer1,
                      height: "auto",
                    }}
                  >
                    {val}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewSummaryDetail;
