import axios from "axios";
import { FILE_SERVICE_BASE_URL } from "../constant/apiUrl";
import { showErrorToast } from "./Toast/toast";

const fetchAndCacheImage = async (id, action, dispatch) => {
  const token = localStorage.getItem("auth_token");
  try {
    const response = await axios.get(
      `${FILE_SERVICE_BASE_URL}/fritolay/file-manager/download-by-id-stream/${id}?user=ADMIN`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/octet-stream",
        },
        responseType: "arraybuffer",
      }
    );

    if (response?.data) {
      const contentType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: contentType });
      const url = URL.createObjectURL(blob);
      if (action) {
        dispatch(action({ id, url }));
      }

      return url;
    }
  } catch (error) {
    // showErrorToast("Failed To Load Image");

    console.error(`Error fetching image for ID ${id}:`, error);
    return null;
  }
};
export default fetchAndCacheImage;
