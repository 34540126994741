import React from "react";
import { LoginCallback } from "@okta/okta-react";
import Loader from "../utils/Loader";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "../App.css";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import AdminstrationLayout from "../pages/AdminstrationLayout";
import Container from "../layout/Container";
import PrivateRoute from "../hoc/PrivateRoute";
import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
} from "@mui/material";
import InternalUpload from "../components/Upload/InternalUpload";
import ExternalUpload from "../components/Upload/ExternalUpload";
import PotatoProtectionLayout from "../pages/PotatoProtectionLayout";
import VarietyDetail from "../components/PotatoProtection/LargeScreen/VarietyDetails/VarietySection/VarietyDetail";
import Sustainable from "../pages/Sustainable";
import PotatoStorage from "../pages/PotatoStorage";
import PotatoVariety from "../pages/PotatoVariety";
import Favorites from "../pages/Favorites";
import Library from "../pages/Library";
import GlobalTrials from "../pages/GlobalTrials";
import SubjectMatterExperts from "../pages/SubjectMatterExperts";
import LinkScreen from "../components/LinkScreen/LinkScreen";
import Document from "../components/DocumentScreen/Document";
import LibraryDetails from "../pages/LibraryDetails";
import PotatoQuality from "../pages/PotatoQuality";
import AppDynamics from "../AppDynamics";
import RepalceCards from "../components/Administration/Screens/Spotlight/RepalceItems/RepalceCards";
import SearchPage from "../pages/SearchPage";
import FritoForumMainPage from "../components/FritoForumPage/FritoForumMainPage";
import FritoForumDetailPage from "../components/FritoForumPage/FritoForumDetailPage";
import Curator from "../components/Curator/Curator";
import DeniedArtifactHistoryDetails from "../components/Curator/DeniedHistory/DeniedArtifactHistoryDetails";
import UploadLayout from "../components/Upload-History-Approval/UploadLayout";
import Login from "../pages/Login";
import ModifyYearlyReview from "../components/Curator/YealryReview/ModifyYearlyReview";
import ModifyPendingApproval from "../components/Curator/PendingApproval/ModifyPendingApproval";
import SustainableFarmingProgram from "../pages/SustainableFarmingProgram";
import RegnerativeAgricultureProgram from "../pages/regenerativeAgriculture";
import FarmerIncentiveProgram from "../pages/FarmersIncentivePrograms";
import PivotAuditTootkit from "../pages/PivotAuditToolkit";
import GHCToolkit from "../pages/GHGToolkit";
import PepticidesAndOtherAgrochemicals from "../pages/PesticidesAndOtherAgrochemicals";
import Environmental from "../pages/Environmental";
import ClimateResientAgriculture from "../pages/ClimateReslientAgriculture";
import CoverCropDesignTool from "../pages/CoverCropDesignTool";
import LIFEMetrics from "../pages/LFEMetrics";
import VideoDetails from "../components/VideoScreen/VideoDetails";
import SpecialProjectEditScreen from "../components/Administration/Screens/SpecialProject/SpecialProjectEditScreen";
import RequestInformationDetails from "../components/RFI/RfiDetails/RequestInformationDetails";
import Logout from "../pages/Logout";
import ArtifactHistoryReview from "../components/Curator/ArtifactHistory/ArtifactHistoryReview";
import AddRequestInformation from "../components/RFI/AddRFI/AddRequestInformation";
import RequestForInformation from "../pages/RequestForInformation";
import { useSelector } from "react-redux";
import UserBasedRoute from "../hoc/UserBasedRoute";
import NotFound from "../pages/NotFound";
import SpeacialProject from "../pages/SpeacialProject";
import { ThemeProvider } from "pepsico-ds";
const theme = createTheme();
const AppRoutes = () => {
  const { flagUser } = useSelector((store) => store.user);

  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <MaterialThemeProvider theme={theme}>
        <ThemeProvider>
          <AppDynamics />
          <Routes>
            <Route
              path="/login/callback"
              element={<LoginCallback loadingElement={<Loader />} />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Container />}>
                <Route path="" element={<Home />} />
                <Route path="/document" element={<Document />} />
                <Route
                  path="/add-request-information"
                  element={<AddRequestInformation />}
                />
                <Route
                  path="/details-request-information/:id"
                  element={<RequestInformationDetails />}
                />
                <Route path="/search-results/:query" element={<SearchPage />} />
                <Route
                  path="/library-details/:id"
                  element={<LibraryDetails />}
                />
                <Route
                  path="/video-details/:contentId"
                  element={<VideoDetails />}
                />
                <Route path="/favorites" element={<Favorites />} />
                <Route
                  path="/frito-forum-detail-page"
                  element={<FritoForumDetailPage />}
                />
                <Route path="/link" element={<LinkScreen />} />
                <Route
                  path="/video-details/:contentId"
                  element={<VideoDetails />}
                />
                <Route element={<UserBasedRoute />}>
                  <Route path="/admin" element={<AdminstrationLayout />} />
                  {flagUser?.roleName?.toLowerCase() === "external" ? (
                    <Route path="/upload" element={<ExternalUpload />} />
                  ) : (
                    <Route path="/upload" element={<InternalUpload />} />
                  )}
                  <Route
                    path="special-projects"
                    element={<SpeacialProject />}
                  />

                  <Route
                    path="/potato-protection-status"
                    element={<PotatoProtectionLayout />}
                  />
                  <Route
                    path="/potato-protection-status/:id"
                    element={<VarietyDetail />}
                  />
                  <Route path="/sustainable" element={<Sustainable />} />
                  <Route path="/library" element={<Library />} />

                  <Route path="/potato-variety" element={<PotatoVariety />} />

                  <Route path="/potato-quality" element={<PotatoQuality />} />
                  <Route path="/potato-storage" element={<PotatoStorage />} />
                  <Route path="/global-trial" element={<GlobalTrials />} />
                  <Route
                    path="/subject-matter-experts"
                    element={<SubjectMatterExperts />}
                  />

                  <Route path="/admin/replace" element={<RepalceCards />} />

                  <Route
                    path="/frito-forum-main-page"
                    element={<FritoForumMainPage />}
                  />

                  <Route path="/curator" element={<Curator />} />
                  <Route
                    path="/curator/pending-approval/:id"
                    element={<ModifyPendingApproval />}
                  />
                  <Route
                    path="/curator/yearly-review/:id"
                    element={<ModifyYearlyReview />}
                  />
                  <Route
                    path="/curator/artifact-history/:id"
                    element={<ArtifactHistoryReview />}
                  />
                  <Route
                    path="/curator/denied-artificats-history/:id"
                    element={<DeniedArtifactHistoryDetails />}
                  />
                  <Route
                    path="/pending-approvals-history"
                    element={<UploadLayout />}
                  />
                  <Route path="/history" element={<UploadLayout />} />
                  <Route
                    path="/farming-program"
                    element={<SustainableFarmingProgram />}
                  />
                  <Route
                    path="/regenerative-agriculture"
                    element={<RegnerativeAgricultureProgram />}
                  />
                  <Route
                    path="/request-information"
                    element={<RequestForInformation />}
                  />
                  <Route
                    path="/farmers-incentive-programs"
                    element={<FarmerIncentiveProgram />}
                  />
                  <Route
                    path="/pivot-audit-urlolkit"
                    element={<PivotAuditTootkit />}
                  />

                  <Route path="/Ghg-urlolkit" element={<GHCToolkit />} />

                  <Route
                    path="/pepsico-statement"
                    element={<PepticidesAndOtherAgrochemicals />}
                  />
                  <Route path="/environmental" element={<Environmental />} />
                  <Route
                    path="/cover-crop-design"
                    element={<CoverCropDesignTool />}
                  />
                  <Route path="/life-metrics" element={<LIFEMetrics />} />
                  <Route
                    path="/climate-resilient-agriculture"
                    element={<ClimateResientAgriculture />}
                  />
                  <Route
                    path="/special-project-edit"
                    element={<SpecialProjectEditScreen />}
                  />

                  <Route
                    path="/request-information"
                    element={<RequestForInformation />}
                  />
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ThemeProvider>
      </MaterialThemeProvider>
    </QueryClientProvider>
  );
};

export default AppRoutes;
