import React, { useEffect, useState } from "react";

import PotatoProtection from "../components/PotatoProtection/LargeScreen/Landing/PotatoProtection";
import { BASE_URL } from "../constant/apiUrl";
import { useSelector } from "react-redux";
import { formatDate } from "../utils/function";
import { getAPI } from "../API/ApiService";
import { showErrorToast } from "../utils/Toast/toast";
export default function PotatoProtectionLayout() {
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState([]);
  const { fetch } = useSelector((store) => store.protectionPotato);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getAPI(`${BASE_URL}/variety`);
        if (response?.status === 200) {
          setPageData(response?.data);
        }
      } catch (error) {
        showErrorToast("Failed to load data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [fetch]);

  const data = pageData?.map((item) => {
    return {
      ...item,
      firstSaleDate:
        item.firstSaleDate === null ? null : formatDate(item?.firstSaleDate),
    };
  });

  return <PotatoProtection rows={data} loading={loading} />;
}
