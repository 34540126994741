import React, { useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Button } from "pepsico-ds";
import ActionButtons from "./ActionButtons";
import { useSelector } from "react-redux";
import { darkTheme } from "../../../theme/Theme";
import { postAPI } from "../../../API/ApiService";
import { BASE_URL } from "../../../constant/apiUrl";
import PropTypes from "prop-types";

export default function DescriptionCard({ rfiData, id, setRfiData }) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isDarkTheme = useSelector((state) => state.theme);
  const [isArchived, setIsArchived] = useState(rfiData?.archived || false);
  const { flagUser } = useSelector((state) => state.user);

  const archiveHandler = async () => {
    try {
      const response = await postAPI(
        `${BASE_URL}/rfi/${id}/${isArchived ? "unArchive" : "archive"}`
      );
      if (response?.status === 200) {
        setRfiData(response?.data);
        setIsArchived(response?.data.archived);
      }
    } catch (e) {}
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: isMobile ? "auto" : "423px",
        padding: "var(--Spacing-spacing-24, 24px)",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "var(--Spacing-spacing-16, 16px)",
        borderRadius: "var(--Corner-radius-effect-corner-2, 8px)",
        background: isDarkTheme
          ? darkTheme.backgroundColorLayer2
          : "var(--Tokens-Background-Layer-01, #FBFCFE)",
        boxShadow:
          "var(--Elevation-X-Elevation-01, 0px) var(--Elevation-Y-Elevation-01, 0px) var(--Elevation-Blur-Elevation-01, 24px) var(--Elevation-Spread-Elevation-01, 0px) var(--Elevation-Colors-Elevation-01, rgba(0, 92, 188, 0.08))",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignSelf: "stretch", // Ensures the component stretches to fill the parent's cross-axis
        }}
      >
        <Typography
          sx={{
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : "var(--Tokens-Text-Primary, #3A3A3A)",
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
            fontSize: "var(--Font-Size-H6, 18px)",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-H6, 28px)", // 155.556%
          }}
        >
          Description
        </Typography>

        <Button
          variant={isArchived ? "primary" : "secondary"}
          text="Archive"
          size="medium"
          onClick={archiveHandler}
          style={{
            visibility:
              flagUser.roleName.toLowerCase() === "external" ? "hidden" : "",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: "var(--Spacing-spacing-16, 16px) 0px",
          alignItems: "flex-start",
          gap: "8px",
          alignSelf: "stretch",
          borderRadius: "8px",
          background: isDarkTheme ? darkTheme.backgroundColorLayer3 : "#F3F7FD",
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "var(--Spacing-spacing-12, 12px)",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "var(--Spacing-spacing-16, 16px)",
            flex: "1 0 0",
            background: isDarkTheme
              ? darkTheme.backgroundColorLayer3
              : "var(--Tokens-Background-Default, #F3F7FD)",
          }}
        >
          <p
            style={{
              color: isDarkTheme ? darkTheme.textColorLayer1 : "black",
            }}
          >
            {rfiData?.description}
          </p>
        </Box>
      </Box>
      <ActionButtons card={rfiData} />
    </Box>
  );
}
DescriptionCard.propTypes = {
  rfiData: PropTypes.shape({
    archived: PropTypes.bool,
    description: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  setRfiData: PropTypes.func.isRequired,
};

DescriptionCard.defaultProps = {
  rfiData: {
    archived: false,
    description: "",
  },
};
