import React, { useEffect, useState } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  Typography,
  Popper,
  Button,
} from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeletePopUp from "./DeletePopUp";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useSelector } from "react-redux";
import useThemeRender from "../../../../theme/useThemeRender";
import { setDisplay } from "../../../../slices/adminPageSlice";
import { useDispatch } from "react-redux";
import { resetButtonState } from "../../../../slices/buttonSlice";
import MobileNotifications from "./../../../Notifications/MobileNotifications";
import axios from "axios";
import { BASE_URL } from "./../../../../constant/apiUrl";
import Loading from "../../../../utils/Loader";
import { getAPI, postAPI } from "../../../../API/ApiService";
import { showErrorToast } from "../../../../utils/Toast/toast";
const Subjects = () => {
  const [options, setOptions] = useState([]);
  const isDarkTheme = useSelector((state) => state.theme);
  const [selectedSubject, setSelectedSubject] = useState({});
  const [newSubject, setNewSubject] = useState("");
  const [isAddingSubject, setIsAddingSubject] = useState(false); // default set to false (edit mode)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationType, setNotificationType] = useState("success");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [artifact, setArtifact] = useState({});
  const dispatch = useDispatch();
  const buttonState = useSelector((state) => state.button.buttonState);
  console.log("button state ", buttonState);
  const token = localStorage.getItem("auth_token");

  useEffect(() => {
    dispatch(setDisplay(true));
    if (isAddingSubject) {
      if (buttonState === "saved") {
        handleAddSave();
      } else if (buttonState === "cancelled") {
        handleAddCancel();
      }
    }
    if (!isAddingSubject) {
      if (buttonState === "saved") {
        handleEditSave();
      } else if (buttonState === "cancelled") {
        handleEditCancel();
      }
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getAPI(
          `${BASE_URL}/administrative-subject/list`
        );
        console.log("response subject", response.data);
        const subjectData = response.data;
        setOptions(subjectData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, [buttonState, dispatch, isAddingSubject]);

  const handleChange = (event, newValue) => {
    setSelectedSubject(newValue || {});
    console.log("selected subject", newValue);
  };

  const handleOpenDeleteDialog = () => {
    if (!selectedSubject || selectedSubject === "" || !selectedSubject?.name) {
      showErrorToast("Please select the subject");
    } else if (selectedSubject?.name) {
      setIsDeleteDialogOpen(true);
    }
  };

  const handleDeleteSubject = async (selectedSubject) => {
    if (selectedSubject?.id === artifact?.id) {
      setNotificationType("error");
      setNotificationMessage("Invalid artifact selected");
    }
    setLoading(true);
    if (!artifact || artifact === "" || !artifact?.id) {
      setLoading(false);
      showErrorToast("Please select the subject");
    } else {
      try {
        const response = await axios.put(
          `${BASE_URL}/administrative-subject/deleteSubject/${selectedSubject?.id}/${artifact?.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          setNotificationType("success");
          setNotificationMessage("Subject successfully deleted");
          setOptions((prevOptions) =>
            prevOptions.filter((subject) => subject?.id !== selectedSubject?.id)
          );
          handleCloseDeleteDialog();
          setSelectedSubject({});
          setNewSubject("");
          setArtifact("");
        } else {
          setNotificationType("error");
          setNotificationMessage("Failed to delete subject");
        }
      } catch (error) {
        setLoading(false);
        console.error("Error deleting subject: ", error);
        setNotificationType("error");
        setNotificationMessage(
          error.response?.data?.message || "Failed to delete subject"
        );
      } finally {
        setLoading(false);
        setNotificationOpen(true);
      }
    }
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleAddSave = async () => {
    if (!newSubject?.trim()) {
      setNotificationOpen(true);
      setNotificationType("error");
      setNotificationMessage("Subject name cannot be empty");
      dispatch(resetButtonState());
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/administrative-subject/addSubject`,
        { name: newSubject.trim() },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("added subject", response.data);

      if (response.status >= 200 && response.status < 300) {
        setNotificationType("success");
        setNotificationMessage("Subject successfully created");
        setSelectedSubject({});
        setNewSubject("");
        setIsAddingSubject(false);
      } else {
        throw new Error("Failed to add subject");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error adding subject: ", error);
      setNotificationType("error");
      setNotificationMessage("Subject Name already exists");
    } finally {
      setLoading(false);
      setNotificationOpen(true);
      dispatch(resetButtonState());
    }
  };

  const handleAddCancel = () => {
    setNewSubject("");
    setIsAddingSubject(false);
    dispatch(resetButtonState());
    setSelectedSubject("");
  };

  const handleEditSave = async () => {
    if (!selectedSubject || !selectedSubject?.name.trim()) {
      setNotificationOpen(true);
      setNotificationType("error");
      setNotificationMessage("Please select a subject to edit");
      dispatch(resetButtonState());
      return;
    }
    setLoading(true);
    const name = newSubject.trim();
    try {
      const response = await postAPI(
        `${BASE_URL}/administrative-subject/editSubject/${selectedSubject?.id}`,
        { name: name }
      );

      if (response.status === 200 || response.status === 201) {
        setOptions((prevOptions) =>
          prevOptions?.map((item) =>
            item?.id === selectedSubject?.id
              ? { ...item, name: newSubject.trim() }
              : item
          )
        );
        setNotificationType("success");
        setNotificationMessage("Subject successfully updated");
        setSelectedSubject({});
        setNewSubject("");
      } else {
        throw new Error("Failed to update subject");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating subject: ", error);
      setNotificationType("error");
      setNotificationMessage(
        error.response?.data?.message || "Failed to update subject"
      );
    } finally {
      setLoading(false);
      setNotificationOpen(true);
      setNewSubject("");
      dispatch(resetButtonState());
    }
  };

  const handleEditCancel = () => {
    setNewSubject("");
    setSelectedSubject("");
    dispatch(resetButtonState());
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const baseBoxStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: "8px",
    background: useThemeRender("cardBackgroundColorLayer1"),
    boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
  };

  const popperStyles = {
    "& .MuiAutocomplete-listbox": {
      background: isDarkTheme
        ? darkTheme.backgroundColorLayer3
        : lightTheme.backgroundColorLayer3,
      color: isDarkTheme
        ? darkTheme.textColorLayer1
        : lightTheme.textColorLayer1,
      "& .MuiAutocomplete-option:hover": {
        backgroundColor: isDarkTheme
          ? darkTheme.dropdownHoverColorLayer3
          : lightTheme.dropdownHoverColorLayer3,
      },
    },
  };

  const backgroundColorLayer3 = useThemeRender("backgroundColorLayer3");
  const insideCardBackgroundColorLayer1 = useThemeRender(
    "insideCardBackgroundColorLayer1"
  );
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const iconColor1 = useThemeRender("iconColor1");
  const borderColorLayer2 = useThemeRender("borderColorLayer2");
  const insideCardBackgroundColorLayer3 = useThemeRender(
    "insideCardBackgroundColorLayer3"
  );
  useEffect(() => {
    setNewSubject(selectedSubject?.name);
  }, [selectedSubject?.name]);

  const handleAddSubject = () => {
    setNewSubject("");
    setIsAddingSubject(true);
  };
  return (
    <Box sx={baseBoxStyle}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          sx={{
            borderRadius: "24px",
            textTransform: "none",
            fontWeight: "700",
          }}
          endIcon={<ControlPointIcon />}
          onClick={handleAddSubject}
          disabled={isAddingSubject}
        >
          Add Subject
        </Button>
      </Box>

      {!isAddingSubject && ( // When isAddingSubject is false, we are in "edit" mode
        <Box
          sx={{
            borderRadius: "8px",
            background: insideCardBackgroundColorLayer3,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            width: "100%",
            padding: "16px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography sx={{ color: textColorLayer2, mb: "5px" }}>
              Subject
            </Typography>
            <Autocomplete
              onChange={handleChange}
              options={[...options]}
              getOptionLabel={(option) => option?.name || ""}
              value={selectedSubject ? { name: selectedSubject?.name } : null}
              PopperComponent={(props) => (
                <Popper {...props} sx={popperStyles} />
              )}
              sx={{
                width: "40%",
                background: backgroundColorLayer3,
                "& .MuiInputBase-root": {
                  height: "36px",
                  color: textColorLayer2,
                },
                "& .MuiAutocomplete-popupIndicator": { color: iconColor1 },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: borderColorLayer2 },
                  "&:hover fieldset": { borderColor: borderColorLayer2 },
                  "& .MuiAutocomplete-clearIndicator": { color: iconColor1 },
                },
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select a subject" />
              )}
            />
          </Box>
          <Box sx={{ width: "40%" }}>
            <Typography sx={{ color: textColorLayer2, mb: "5px" }}>
              Subject Name
            </Typography>
            <TextField
              fullWidth
              placeholder="Please Enter"
              sx={{
                background: insideCardBackgroundColorLayer1,
                "& .MuiInputBase-root": {
                  height: "36px",
                  color: textColorLayer2,
                },
                "& .MuiAutocomplete-popupIndicator": { color: iconColor1 },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: borderColorLayer2 },
                  "&:hover fieldset": { borderColor: borderColorLayer2 },
                  "& .MuiAutocomplete-clearIndicator": { color: iconColor1 },
                },
              }}
              // placeholder="edit subject name"
              value={newSubject}
              onChange={(e) => setNewSubject(e.target.value)}
            />
          </Box>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "24px",
              textTransform: "none",
              fontWeight: "700",
            }}
            endIcon={<DeleteOutlineIcon />}
            onClick={handleOpenDeleteDialog}
          >
            Remove subject
          </Button>
        </Box>
      )}

      {isAddingSubject && ( // When isAddingSubject is true, we are in "add" mode
        <Box
          sx={{
            borderRadius: "8px",
            background: insideCardBackgroundColorLayer3,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            width: "100%",
            padding: "16px",
          }}
        >
          <Typography sx={{ color: textColorLayer2 }}>
            Add New Subject
          </Typography>
          <TextField
            fullWidth
            sx={{
              width: "40%",
              background: insideCardBackgroundColorLayer1,
              "& .MuiInputBase-root": {
                height: "36px",
                color: textColorLayer2,
              },
              "& .MuiAutocomplete-popupIndicator": { color: iconColor1 },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: borderColorLayer2 },
                "&:hover fieldset": { borderColor: borderColorLayer2 },
                "& .MuiAutocomplete-clearIndicator": { color: iconColor1 },
              },
            }}
            placeholder="Enter new subject name"
            value={newSubject}
            onChange={(e) => setNewSubject(e.target.value)}
          />
        </Box>
      )}
      <DeletePopUp
        options={[...options]}
        selectedSubject={selectedSubject?.name}
        open={isDeleteDialogOpen}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmClick={() => handleDeleteSubject(selectedSubject)}
        artifact={artifact}
        setArtifact={setArtifact}
      />
      <MobileNotifications
        type={notificationType}
        message={notificationMessage}
        open={notificationOpen}
        handleClose={handleNotificationClose}
      />
      {loading && <Loading />}
    </Box>
  );
};

export default Subjects;
