import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Button } from "pepsico-ds";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import { BASE_URL, FILE_SERVICE_BASE_URL } from "../../../../constant/apiUrl";
import Loading from "../../../../utils/Loader";
import { showErrorToast } from "../../../../utils/Toast/toast";
import PropTypes from "prop-types"; // Import PropTypes

export default function PreviewModal({
  open,
  setPreviewModal,
  data,
  setFetchData,
  fetchData,
}) {
  const isSmall = useMediaQuery("(max-width:600px)");
  const [loading, setLoading] = React.useState(false); // Loading state
  const [error, setError] = React.useState(null); // Error state

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxHeight: "90vh", // Ensure it doesn't overflow the screen
    overflowY: "auto", // Allow scrolling if content is too long
    boxShadow: 24,
    p: 0.5,
    background: "gray",
  };

  const token = localStorage.getItem("auth_token");

  const handleClose = () => setPreviewModal(false);

  const handleSave = async () => {
    setLoading(true); // Start loading
    setError(null); // Reset error

    try {
      const { images, thumbnailFileId } = data; // Destructure from data
      let fileId; // Use existing thumbnailFileId by default
      console.log(thumbnailFileId, images, "---filed---");

      if (thumbnailFileId === undefined) {
        if (images || images[0]?.file) {
          const formData = new FormData();
          formData.append("file", images[0].file ? images[0].file : images[0]); // Append the actual file

          // Upload the new file and get the file ID
          const uploadResponse = await axios.post(
            `${FILE_SERVICE_BASE_URL}/fritolay/file-manager/upload?user=ADMIN`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (uploadResponse?.status === 200) {
            fileId = uploadResponse?.data?.fileId; // Update the file ID
          } else {
            showErrorToast("Failed to upload image");
          }
        }
      }
      // Check if a new file is being uploaded

      // Prepare the data for the second API call
      const newData = [
        {
          ...data,
          thumbnailFileId: thumbnailFileId ? thumbnailFileId : fileId,
        },
      ];

      // Send the update API request
      const updateResponse = await axios.post(
        `${BASE_URL}/administrative_landing/updateSlots`,
        newData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );

      if (updateResponse?.status === 200) {
        setPreviewModal(false);
        setFetchData(!fetchData); // Refresh the data
      } else {
        throw new Error("Failed to update slots");
      }
    } catch (error) {
      console.error("Error during save:", error);
      setError(error.message); // Set the error message
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
      aria-describedby="preview-modal-description"
    >
      <Box sx={style}>
        <Box>
          {data?.images?.length > 0 ? (
            data?.images.map((image, index) => (
              <Box
                key={index}
                sx={{
                  position: "relative",
                  textAlign: "center",
                  width: "100%",
                  height: "400px",
                  objectFit: "cover",
                  background: isSmall
                    ? `linear-gradient(179deg, rgba(0, 0, 0, 0.70) 47.25%, rgba(0, 0, 0, 0.00) 91.73%), url(${image.preview}) lightgray 50% / cover no-repeat`
                    : `linear-gradient(90deg, rgba(0, 0, 0, 0.60) 28.31%, rgba(0, 0, 0, 0.00) 58.8%), url(${image.preview}) lightgray 0px -268.885px / 121.759% 242.91% no-repeat`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                {/* Text overlay */}
                <Box
                  sx={{
                    position: "absolute",
                    left: isSmall ? "18.96px" : "73px",
                    top: "76px",
                    width: isSmall ? "98%" : "100%",
                    maxWidth: "493px ",
                    color: "#fff",
                    zIndex: 3,
                    display: "flex",
                  }}
                >
                  <div className="relative text-white rounded w-full md:h-[216px] flex flex-col gap-2 items-start justify-start">
                    <Typography
                      sx={{
                        color: "var(--PepsiCo-White, #FFF)",
                        fontFeatureSettings: "'liga' off, 'clig' off",
                        fontFamily:
                          "var(--Typography-Font-Family-Heading, Inter)",
                        fontSize: "var(--Font-Size-H3, 32px)",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "var(--Line-Height-H3, 44px)",
                        textAlign: "left",
                      }}
                    >
                      {data?.headline}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--PepsiCo-White, #FFF)",
                        fontFamily:
                          "var(--Typography-Font-Family-Heading, Inter)",
                        fontSize: "var(--Font-Size-H6, 18px)",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "var(--Line-Height-H6, 28px)",
                        textAlign: "left",
                      }}
                    >
                      {data.description}
                    </Typography>
                    <Box sx={{ mt: "auto" }}>
                      <Button
                        style={{ background: "white" }}
                        className="px-12"
                        size="large"
                        text={`${data.buttonText}`}
                        variant="secondary"
                        iconTrailing="arrow_right_alt"
                      />
                    </Box>
                  </div>
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              No images to preview.
            </Typography>
          )}
        </Box>

        {/* Show loading spinner when uploading */}
        {loading && <Loading />}

        {/* Show error message if any */}
        {error && <div>Something went wrong</div>}

        <div className="flex flex-row gap-2 mt-6 justify-end">
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} disabled={loading}>
            Save
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
PreviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setPreviewModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    thumbnailFileId: PropTypes.number,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        preview: PropTypes.string.isRequired,
        file: PropTypes.object,
      })
    ).isRequired,
    headline: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
  }).isRequired,
  setFetchData: PropTypes.func.isRequired,
  fetchData: PropTypes.bool.isRequired,
};
