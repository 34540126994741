import React from "react";
import InternalPageLayout from "../components/InternalPages/InternalPageLayout";
import { BASE_URL } from "../constant/apiUrl";
import { useSelector } from "react-redux";
import Loading from "../utils/Loader";
import useFetchInternal from "../hooks/useFetchInternal";
import { setSustainability } from "../slices/sustainabilityPageSlice";
export default function Sustainable() {
  const { sustainable } = useSelector((store) => store.sustainable);
  const { favourite } = useSelector((store) => store.internalPage);
  const userId = 1;

  const { loading, pageData } = useFetchInternal(
    `${BASE_URL}/internal-pages/page-info/find-by-page-name?pageName=Sustainability&userId=${userId}`,
    setSustainability,
    sustainable
  );
  if (loading) return <Loading />;
  return <InternalPageLayout pageData={pageData} header={"Sustainability"} />;
}
