import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";

import CardComponent from "./CardComponent";
export default function Expanded({
  data,
  content,
  flexDirection,
  heading,
  setLoading,
}) {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          overflow: "hidden",
          padding: "15px 0px",
        }}
      >
        {/* Slide Track */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            flexWrap: "wrap",
          }}
        >
          {/* Cards */}
          {data?.map((card) => (
            <CardComponent
              key={card?.id}
              card={card}
              isMobile={isMobile}
              content={content}
              flexDirection={flexDirection}
              heading={heading}
              setLoading={setLoading}
            />
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "16px",
        }}
      >
        <Typography
          sx={{
            color: "var(--button-color-button-primary, #005CBC)",
            textAlign: "center",
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: "var(--Typography-Font-Family-Body, Inter)",
            fontSize: "var(--Font-Size-Body, 16px)",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "var(--Line-Height-Body, 22px)",
          }}
        >
          {` ${Math.min(data?.length)} of ${data?.length}`}
        </Typography>
      </Box>
    </>
  );
}
