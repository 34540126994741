import React from "react";
import SustainabilitySubpage from "./SustainabilitySubpage";
import { setClimateResilient } from "../slices/sustainabilityPageSlice";
import { useSelector } from "react-redux";
function ClimateResientAgriculture() {
  const { climateResilient } = useSelector((store) => store.sustainable);
  return (
    <SustainabilitySubpage
      urlName={"Climate%20resilent%20agriculture"}
      header={"Climate reslient agriculture"}
      action={setClimateResilient}
      globalState={climateResilient}
    />
  );
}

export default ClimateResientAgriculture;
