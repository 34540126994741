import { useState, useRef, useEffect, useCallback } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import {
  useMediaQuery,
  Slide,
  Typography,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
  styled,
} from "@mui/material";
import { darkTheme, lightTheme } from "../../../theme/Theme";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import { getAPI } from "../../../API/ApiService";
import { BASE_URL } from "../../../constant/apiUrl";
import { showErrorToast } from "../../../utils/Toast/toast";
import { setSearchText } from "../../../slices/curatorPageLookupSlice";

const SearchComponent = () => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [isEnterButtonPressed, setIsEnterButtonPressed] = useState(false);
  const [globalSearch, setGlobalSearch] = useState("");

  const [suggestions, setSuggestions] = useState([]);

  const isMobile = useMediaQuery("(max-width:600px)");
  const searchRef = useRef(null);
  const searchInputField = useRef(null);
  const isDarkTheme = useSelector((state) => state.theme);
  const handleSearchIconClick = () => setSearchOpen(!searchOpen);
  const navigate = useNavigate();
  const CustomListItemText = styled(ListItemText)({
    color: isDarkTheme ? "white" : "var(--Tokens-Text-Secondary, #616161)",
    fontFamily: "var(--Typography-Font-Family-Body, Inter)", // Font family
    fontSize: "var(--Font-Size-Small, 14px)", // Font size
    fontWeight: 400, // Font weight
    lineHeight: "var(--Line-Height-Small, 20px)", // Line height
  });
  // Close the search if the user clicks outside of the search component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchOpen(false);
        setGlobalSearch("");
      }
    };

    // Add event listener to detect clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const SearchHandle = useCallback(() => {
    if (
      globalSearch !== null &&
      globalSearch !== "" &&
      globalSearch !== undefined
    ) {
      const encodedSearch = encodeURIComponent(globalSearch); // Encode the search term
      navigate(`/search-results/${encodedSearch}`);
      setSearchOpen(false);
      setGlobalSearch("");
    }
  }, [globalSearch, navigate]);
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        SearchHandle();
        // Your logic here
      }
    };

    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keypress", handleKeyPress); // Cleanup event listener
    };
  }, [SearchHandle]);
  useEffect(() => {
    const fetChSuggetions = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/keyword-search/all?userId=09447243`
        );
        if (response?.status === 200) {
          const fetch = response?.data;

          setSuggestions(fetch);
        }
      } catch (e) {
        showErrorToast("Failed to Load Suggesstions");
      }
    };
    fetChSuggetions();
  }, []);

  const handleClose = () => {
    setSearchOpen(false);
    setGlobalSearch("");
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    console.log(value);
    setGlobalSearch(value);
    enterKeyPressHandler();
  };

  const enterKeyPressHandler = () => {
    document.addEventListener("keypress", (event) => {
      if (event.key === "Enter") {
        console.log(
          "enterKeyPressHandler 111",
          event?.key,
          isEnterButtonPressed
        );
        setIsEnterButtonPressed(true);
      }
    });
  };

  // Handle selection from the dropdown
  const handleSelectOption = (option) => {
    setGlobalSearch(option);
  };

  // Handle search action

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="search"
        onClick={handleSearchIconClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
            fill={isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1}
          />
        </svg>
      </IconButton>

      {isMobile ? (
        <Slide direction="up" in={searchOpen} mountOnEnter unmountOnExit>
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              bgcolor: "white",
              zIndex: 10,
              padding: "10px",
              boxShadow: 3,
            }}
            ref={searchRef} // Attach ref to detect clicks outside
          >
            <TextField
              autoFocus={isMobile} // Focus automatically on mobile, triggering the keyboard
              fullWidth
              ref={searchInputField}
              variant="outlined"
              placeholder="Search..."
              onChange={handleInputChange}
              sx={{
                border: "1px solid #ddd",
                padding: "10px",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={SearchHandle}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
                          fill={
                            isDarkTheme
                              ? darkTheme.iconColor1
                              : lightTheme.iconColor1
                          }
                        />
                      </svg>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Slide>
      ) : (
        // For large screens, show the search input without slide
        searchOpen && (
          <Box
            sx={{
              position: "absolute",
              top: "70px", // Adjust based on header
              right: "20px",
              bgcolor: isDarkTheme
                ? darkTheme.backgroundColorLayer3
                : lightTheme.backgroundColorLayer3,
              zIndex: 10,
              width: "auto",
              height: "auto",
            }}
            ref={searchRef} // Attach ref to detect clicks outside
          >
            <Box
              sx={{
                height: "350px",
                display: "flex",
                width: "320px",
                padding: "16px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
                borderRadius: "8px",
                bgcolor: isDarkTheme
                  ? darkTheme.backgroundColorLayer3
                  : lightTheme.backgroundColorLayer3,
                boxShadow:
                  "var(--Elevation-X-Elevation-02, 0px) var(--Elevation-Y-Elevation-02, 4px) var(--Elevation-Blur-Elevation-02, 24px) var(--Elevation-Spread-Elevation-02, 0px) var(--Elevation-Colors-Elevation-02, rgba(58, 58, 58, 0.08))",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "var(--Spacing-spacing-16, 16px)",
                  alignSelf: "stretch",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: isDarkTheme
                      ? "white"
                      : "var(--Tokens-Text-Primary, #3A3A3A)",
                    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                    fontSize: "var(--Font-Size-Body, 16px)",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "var(--Line-Height-Body, 22px)", // 137.5%
                  }}
                >
                  Search
                </Typography>
                <CloseOutlinedIcon
                  sx={{ cursor: "pointer", fill: isDarkTheme ? "white" : "" }}
                  onClick={handleClose}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "var(--Spacing-spacing-12, 12px)",
                  alignSelf: "stretch",
                  width: "100%",
                }}
              >
                <Box sx={{ position: "relative", width: "300px" }}>
                  <TextField
                    fullWidth
                    variant="standard"
                    placeholder="Type or select an item"
                    value={globalSearch}
                    sx={{
                      color: isDarkTheme ? "white" : "black", // For text color
                      "& .MuiInputBase-input": {
                        color: isDarkTheme ? "white" : "black", // Ensures the input text color is white in dark theme
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: isDarkTheme ? "white" : "black", // Optional: for the underline color in dark theme
                      },
                    }}
                    onChange={handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={SearchHandle}>
                            <SearchIcon
                              sx={{ fill: isDarkTheme ? "white" : "" }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Box
                    sx={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      right: 0,
                      zIndex: 10,
                      maxHeight: "200px",
                      overflowY: "auto",
                      gap: "12px",
                      "&::-webkit-scrollbar": {
                        display: "none", // For WebKit-based browsers (Chrome, Safari, etc.)
                      },
                      scrollbarWidth: "none", // For Firefox
                    }}
                  >
                    <List>
                      {suggestions.map((option, index) => (
                        <ListItem key={index} disablePadding>
                          <ListItemButton
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "8px",
                            }}
                            onClick={() => handleSelectOption(option)}
                          >
                            <WatchLaterOutlinedIcon
                              sx={{ fill: isDarkTheme ? "white" : "" }}
                            />
                            <CustomListItemText primary={option} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};

export default SearchComponent;
