import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Tab, TabsNavigation } from "pepsico-ds";
import { useSelector, useDispatch } from "react-redux";
import CustomCuratorFilter from "../CustomCuratorFilter";
import YearlyReviewTabData from "./YearlyReviewTabData";
import { CURATOR_PAGE_API_CONSTANT } from "../../../constant/constant";
import { getCuratorApiListByPageName } from "../../../API/curatorApi";
import Loading from "../../../utils/Loader";
import { setAnnualPendingApprovalData } from "../../../slices/curatorPageSlice";

const YearlyReview = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  const [tab, setTab] = useState(0);
  const handleClick = (index) => setTab(index);
  const [tabLoading, setTabLoading] = useState(true);
  const [expiredContentReview, setExpiredContentReview] = useState();
  const [sixtyDaysContentReview, setSixtyDaysContentReview] = useState();
  const [thirtyDaysContentReview, setThirtyDaysContentReview] = useState();
  const [pageData, setPageData] = useState([]);
  const [filteredRow, setFilteredRows] = useState([]);
  const dispatch = useDispatch();
  const serachText = useSelector((state) => state.curatorPageLookUpData).search;
  const curatorPageLookUpData = useSelector(
    (state) => state.curatorPageLookUpData
  );
  const [filterValue, setFilterValue] = useState({
    sector: null,
    country: null,
    subject: null,
    period: null,
  });
  const annualApprovalData = useSelector(
    (state) => state.curatorpage
  ).annualApprovalData;

  useEffect(() => {
    getCuratorApiListByPageName(CURATOR_PAGE_API_CONSTANT?.yearly_review)
      .then((response) => {
        const annualApproval = prepareAnnulaApprovalData(response);
        dispatch(setAnnualPendingApprovalData(annualApproval));
        setPageData(annualApproval);
        setTabLoading(false);
      })
      .catch((error) => {
        setTabLoading(false);
      });
  }, []);

  useEffect(() => {
    const filteredArray = getTabData(tab);
    const filteredRows = {
      documents: [],
      videos: [],
      links: [],
      documentCount: 0,
      videoCount: 0,
      linkCount: 0,
      totalCount: 0,
    };

    if (filterValue?.subject !== null) {
      filteredArray?.filter((currentRow) => {
        if (currentRow?.subjects?.includes(filterValue?.subject)) {
          if (currentRow?.contentType === "DOCUMENT") {
            filteredRows.documents = [...filteredRows.documents, currentRow];
            filteredRows.documentCount = filteredRows.documentCount + 1;
          }
          if (currentRow?.contentType === "LINK") {
            filteredRows.links = [...filteredRows.links, currentRow];
            filteredRows.linkCount = filteredRows.linkCount + 1;
          }
          if (currentRow?.contentType === "VIDEO") {
            filteredRows.videos = [...filteredRows.videos, currentRow];
            filteredRows.videoCount = filteredRows.videoCount + 1;
          }
        }
      });
    }

    if (filterValue?.country !== null) {
      filteredArray?.filter((currentRow) => {
        if (currentRow?.country?.includes(filterValue?.country)) {
          if (currentRow?.contentType === "DOCUMENT") {
            filteredRows.documents = [...filteredRows.documents, currentRow];
            filteredRows.documentCount = filteredRows.documentCount + 1;
          }
          if (currentRow?.contentType === "LINK") {
            filteredRows.links = [...filteredRows.links, currentRow];
            filteredRows.linkCount = filteredRows.linkCount + 1;
          }
          if (currentRow?.contentType === "VIDEO") {
            filteredRows.videos = [...filteredRows.videos, currentRow];
            filteredRows.videoCount = filteredRows.videoCount + 1;
          }
        }
      });
    }
    if (filterValue?.sector !== null) {
      filteredArray?.filter((currentRow) => {
        if (currentRow?.sector?.includes(filterValue?.sector)) {
          if (currentRow?.contentType === "DOCUMENT") {
            filteredRows.documents = [...filteredRows.documents, currentRow];
            filteredRows.documentCount = filteredRows.documentCount + 1;
          }
          if (currentRow?.contentType === "LINK") {
            filteredRows.links = [...filteredRows.links, currentRow];
            filteredRows.linkCount = filteredRows.linkCount + 1;
          }
          if (currentRow?.contentType === "VIDEO") {
            filteredRows.videos = [...filteredRows.videos, currentRow];
            filteredRows.videoCount = filteredRows.videoCount + 1;
          }
        }
      });
    }

    if (
      filterValue?.subject === null &&
      filterValue?.country === null &&
      filterValue?.sector === null
    ) {
      setFilteredRows([]);
      dispatch(setAnnualPendingApprovalData(pageData));
    } else {
      dispatch(setAnnualPendingApprovalData({ ...filteredRows }));
      setFilteredRows(filteredRows);
    }
  }, [filterValue]);

  useEffect(() => {
    const filteredArray = getTabData(tab);
    const searchedRow = {
      documents: [],
      videos: [],
      links: [],
      documentCount: 0,
      videoCount: 0,
      linkCount: 0,
      totalCount: 0,
    };
    let timer = null;
    if (serachText !== "" && filteredArray?.length > 0) {
      timer = setTimeout(() => {
        filteredArray?.filter((item) => {
          const matchesSearch = Object?.values(item)?.some((value) =>
            String(value)
              ?.toLowerCase()
              ?.includes(serachText?.toLowerCase() || "")
          );
          if (matchesSearch) {
            if (item?.contentType === "DOCUMENT") {
              searchedRow.documents = [...searchedRow.documents, item];
              searchedRow.documentCount = searchedRow.documentCount + 1;
            }
            if (item?.contentType === "LINK") {
              searchedRow.links = [...searchedRow.links, item];
              searchedRow.linkCount = searchedRow.linkCount + 1;
            }
            if (item?.contentType === "VIDEO") {
              searchedRow.videos = [...searchedRow.videos, item];
              searchedRow.videoCount = searchedRow.videoCount + 1;
            }
          }
        });
        searchedRow.totalCount =
          searchedRow.videoCount +
          searchedRow.linkCount +
          searchedRow.documentCount;
        if (serachText === "") {
          dispatch(setAnnualPendingApprovalData(pageData));
          setFilteredRows(pageData);
        } else {
          dispatch(setAnnualPendingApprovalData(searchedRow));
          setFilteredRows(searchedRow);
        }
      }, 250);
    } else if(serachText === '') {
      dispatch(setAnnualPendingApprovalData(pageData));
      setFilteredRows(pageData);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [serachText]);

  const updatedFilterValue = (value, type) => {
    if (type === "subject") {
      setFilterValue((preState) => {
        return {
          ...preState,
          subject: value === undefined ? null : value,
        };
      });
    }

    if (type === "sector") {
      setFilterValue((preState) => {
        return {
          ...preState,
          sector: value === undefined ? null : value,
        };
      });
    }

    if (type === "country") {
      setFilterValue((preState) => {
        return {
          ...preState,
          country: value === undefined ? null : value,
        };
      });
    }

    if (type === "period") {
      setFilterValue((preState) => {
        return {
          ...preState,
          period: value === undefined ? null : value,
        };
      });
    }
  };

  const prepareAnnulaApprovalData = (annualApprovalData, currentTab) => {
    const annualPendingApprovalData = {
      documentCount: calculateTheCounts(annualApprovalData, "document"),
      documents: [
        ...annualApprovalData?.expiredContentReview?.documents,
        ...annualApprovalData?.sixtyDaysContentReview?.documents,
        ...annualApprovalData?.thirtyDaysContentReview?.documents,
      ],
      linkCount: calculateTheCounts(annualApprovalData, "link"),
      links: [
        ...annualApprovalData?.expiredContentReview?.links,
        ...annualApprovalData?.sixtyDaysContentReview?.links,
        ...annualApprovalData?.thirtyDaysContentReview?.links,
      ],
      totalCount: calculateTheCounts(annualApprovalData, "all"),
      videoCount: calculateTheCounts(annualApprovalData, "video"),
      videos: [
        ...annualApprovalData?.expiredContentReview?.videos,
        ...annualApprovalData?.sixtyDaysContentReview?.videos,
        ...annualApprovalData?.thirtyDaysContentReview?.videos,
      ],
    };

    return annualPendingApprovalData;
  };

  const calculateTheCounts = (annualApprovalData, fieldType) => {
    let counts = 0;
    if (fieldType === "document") {
      counts =
        annualApprovalData?.expiredContentReview?.documents?.length +
        annualApprovalData?.sixtyDaysContentReview?.documents?.length +
        annualApprovalData?.thirtyDaysContentReview?.documents?.length;
    }

    if (fieldType === "video") {
      counts =
        annualApprovalData?.expiredContentReview?.videos?.length +
        annualApprovalData?.sixtyDaysContentReview?.videos?.length +
        annualApprovalData?.thirtyDaysContentReview?.videos?.length;
    }

    if (fieldType === "link") {
      counts =
        annualApprovalData?.expiredContentReview?.links?.length +
        annualApprovalData?.sixtyDaysContentReview?.links?.length +
        annualApprovalData?.thirtyDaysContentReview?.links?.length;
    }

    if (fieldType === "all") {
      counts =
        annualApprovalData?.expiredContentReview?.links?.length +
        annualApprovalData?.sixtyDaysContentReview?.links?.length +
        annualApprovalData?.thirtyDaysContentReview?.links?.length +
        annualApprovalData?.expiredContentReview?.documents?.length +
        annualApprovalData?.sixtyDaysContentReview?.documents?.length +
        annualApprovalData?.thirtyDaysContentReview?.documents?.length +
        annualApprovalData?.expiredContentReview?.videos?.length +
        annualApprovalData?.sixtyDaysContentReview?.videos?.length +
        annualApprovalData?.thirtyDaysContentReview?.videos?.length;
    }

    return counts;
  };

  const getTabData = (currentTab) => {
    switch (currentTab) {
      case 0:
        return [
          annualApprovalData.documents,
          annualApprovalData.videos,
          annualApprovalData.links,
        ].flat();
      case 1:
        return annualApprovalData.documents;
      case 2:
        return annualApprovalData.videos;
      case 3:
        return annualApprovalData.links;
      default:
        return [];
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingBottom: "16px",
      }}
    >
      <CustomCuratorFilter
        pageName={CURATOR_PAGE_API_CONSTANT.yearly_review}
        filters={curatorPageLookUpData}
        updatedFilterValue={updatedFilterValue}
        filterValue={filterValue}
      />
      {tabLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col gap-6">
          <TabsNavigation selectedIndex={tab} isDark={isDarkTheme}>
            <Tab
              iconTrailing={false}
              label={`All (${
                annualApprovalData?.totalCount === undefined
                  ? 0
                  : annualApprovalData?.totalCount
              }) `}
              onClick={() => handleClick(0)}
              orientation="horizontal"
              isDark={isDarkTheme}
            />
            <Tab
              iconTrailing={false}
              label={`Documents (${
                annualApprovalData?.documentCount === undefined
                  ? 0
                  : annualApprovalData?.documentCount
              }) `}
              onClick={() => handleClick(1)}
              orientation="horizontal"
              isDark={isDarkTheme}
            />
            <Tab
              label={`Videos (${
                annualApprovalData?.videoCount === undefined
                  ? 0
                  : annualApprovalData?.videoCount
              })`}
              iconTrailing={false}
              onClick={() => handleClick(2)}
              isDark={isDarkTheme}
            />
            <Tab
              label={`Links (${
                annualApprovalData?.linkCount === undefined
                  ? 0
                  : annualApprovalData?.linkCount
              })`}
              iconTrailing={false}
              onClick={() => handleClick(3)}
              isDark={isDarkTheme}
            />
          </TabsNavigation>

          {Object.keys(annualApprovalData).length > 0 && (
            <YearlyReviewTabData currentTabData={getTabData(tab)} />
          )}
        </div>
      )}
    </Box>
  );
};

export default YearlyReview;
