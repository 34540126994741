import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAPI } from "../API/ApiService";
import { showErrorToast } from "../utils/Toast/toast";
import fetchAndCacheImage from "../utils/fetchandCatchImages";
import {
  setGlobalSelectedState,
  setSelectedAction,
} from "../slices/internalPageSlice";

export default function useFetchInternal(
  path,
  action,
  globalState, // Redux global state (sustainableFarming)
  dependency
) {
  console.log(globalState, "----dependency & globalState--------");
  const { fetchFavourite } = useSelector((store) => store.internalPage);
  const dispatch = useDispatch();
  const token = localStorage.getItem("auth_token");
  console.log(fetchFavourite, globalState, "------fetchFavouriteees-----");

  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState(globalState || []); // Use Redux state if available

  const tagThumbnailUrlsForSubPages = async (subPages) => {
    return Promise.all(
      subPages.map(async (subPage) => {
        const updatedDocuments = await Promise.all(
          subPage.documents.map(async (doc) => ({
            ...doc,
            thumbnailUrl: await fetchAndCacheImage(doc.thumbnailFileId),
          }))
        );
        const updatedVideos = await Promise.all(
          subPage.videos.map(async (video) => ({
            ...video,
            thumbnailUrl: await fetchAndCacheImage(video.thumbnailFileId),
          }))
        );
        const updatedLinks = await Promise.all(
          subPage.links.map(async (link) => ({
            ...link,
            thumbnailUrl: await fetchAndCacheImage(link.thumbnailFileId),
          }))
        );

        return {
          ...subPage,
          documents: updatedDocuments,
          videos: updatedVideos,
          links: updatedLinks,
        };
      })
    );
  };

  const fetchData = async () => {
    if (globalState?.length > 0) {
      setPageData(globalState);
      return;
    }

    console.log("Fetching Data from API...");
    setLoading(true);

    try {
      const response = await getAPI(path);
      if (response?.status === 200) {
        const taggedSubPages = await tagThumbnailUrlsForSubPages(
          response?.data?.internalSubPages
        );

        const newData = { internalSubPages: taggedSubPages };
        setPageData(newData);
        dispatch(action(newData)); // Save to Redux
        dispatch(setGlobalSelectedState(newData));
        dispatch(setSelectedAction(action));
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      showErrorToast("Failed to Load Data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (globalState?.length === 0 || fetchFavourite) {
      fetchData();
    } else {
      dispatch(setGlobalSelectedState(globalState));
      dispatch(setSelectedAction(action));
    }
  }, [token, fetchFavourite]); // Add `fetchFavourite` here

  return { loading, pageData };
}
