import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

export default function DetailsTextArea({
  editorRef,
  isDarkTheme,
  setDescription,
  populateData,
}) {
  const [localContent, setLocalContent] = useState("");

  useEffect(() => {
    if (populateData && populateData?.description) {
      setLocalContent(populateData?.description);
      if (editorRef.current) {
        editorRef.current.innerHTML = populateData?.description; // Set the contentEditable field's initial value
      }
    }
  }, [populateData, editorRef]);

  const handleInput = (e) => {
    setLocalContent(e.target.innerText); // Update local state as user types
  };

  const handleBlur = () => {
    setDescription(localContent); // Update the parent component with the description
  };

  return (
    <Box
      sx={{
        p: 2,
        border: isDarkTheme ? "1px solid #4E4E4E" : "1px solid lightgray",
        borderRadius: 2,
        backgroundColor: isDarkTheme ? "#333" : "#fff",
      }}
    >
      <Box
        ref={editorRef}
        contentEditable
        onInput={(e) => {
          const maxChars = 200;
          const textContent = e.currentTarget.textContent;
          if (textContent.length > maxChars) {
            e.currentTarget.textContent = textContent.substring(0, maxChars);
            // Place the caret at the end after truncation
            const range = document.createRange();
            range.selectNodeContents(e.currentTarget);
            range.collapse(false);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
          }
          handleInput(e); // Call your existing input handler
        }}
        onBlur={handleBlur}
        sx={{
          minHeight: 150,
          color: isDarkTheme ? "white" : "black",
          borderRadius: 2,
          padding: 2,
          outline: "none",
          overflowY: "auto",
        }}
      />

      {/* Toolbar */}
    </Box>
  );
}
DetailsTextArea.propTypes = {
  editorRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired, // Ensures `editorRef` is a valid React reference
  isDarkTheme: PropTypes.bool.isRequired, // A required boolean for theme mode
  setDescription: PropTypes.func.isRequired, // A required function to update description
  populateData: PropTypes.shape({
    description: PropTypes.string, // Optional description string in `populateData`
  }), // Ensures `populateData` is an object with an optional `description` property
};
