// export const BASE_URL = window._env_.REACT_APP_API_BASE_URL
//   ? window._env_.REACT_APP_API_BASE_URL
//   : "https://apim-na.dev.mypepsico.com/cgf/agroflagservice/v1";

// export const FILE_SERVICE_BASE_URL = window._env_
//   .REACT_APP_FILE_SERVICE_BASE_URL
//   ? window._env_.REACT_APP_FILE_SERVICE_BASE_URL
//   : "https://apim-na.qa.mypepsico.com/cgf/agroflagservice/v1";

export const BASE_URL =
  "https://apim-na.dev.mypepsico.com/cgf/agroflagservice/v1";
export const FILE_SERVICE_BASE_URL =
  "https://flag-dev.pepsico.com";
export const APPD_KEY = "AD-AAB-ADX-RZX";
