import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  specialProjects: [],
  subjects: [],
  sectors: [],
  countries: [],
  language: [],
  subjectMatterExpert: [],
  contentType: [],
  periods: [],
  disclaimers: [],
  visibility: [],
  contentLevel: [],
  cropType: [],
  search: "",
};

const curatorPageLookUpSlice = createSlice({
  name: "curatorPageLookUpSlice",
  initialState,
  reducers: {
    setSpecialProject: (state, action) => {
      state.specialProjects = action.payload;
    },
    setSubjects: (state, action) => {
      state.subjects = action.payload;
    },
    setSectors: (state, action) => {
      state.sectors = action.payload;
    },
    setCoutries: (state, action) => {
      state.countries = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setSubjectMatterExpert: (state, action) => {
      state.subjectMatterExpert = action.payload;
    },
    setContentType: (state, action) => {
      state.contentType = action.payload;
    },
    setPeriods: (state, action) => {
      state.periods = action.payload;
    },
    setDisclaimers: (state, action) => {
      state.disclaimers = action.payload;
    },
    setVisibility: (state, action) => {
      state.visibility = action.payload;
    },
    setCropType: (state, action) => {
      state.cropType = action.payload;
    },
    setContentLevel: (state, action) => {
      state.contentLevel = action.payload;
    },
    setSearchText: (state, action) => {
      state.search = action.payload;
    },
  },
});

export const {
  setSpecialProject,
  setSubjects,
  setSectors,
  setCoutries,
  setLanguage,
  setSubjectMatterExpert,
  setPeriods,
  setDisclaimers,
  setVisibility,
  setContentLevel,
  setCropType,
  setSearchText,
} = curatorPageLookUpSlice.actions;

export default curatorPageLookUpSlice.reducer;
