import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TextArea } from "pepsico-ds";
import useThemeRender from "../../../theme/useThemeRender";
import "./FormatableTextArea.css";

const FormatableTextArea = ({
  label,
  editorRef,
  isDarkTheme,
  setDescription,
  isDisabled = false,
  changeFormatableText,
  showToolbar,
}) => {
  const [localContent, setLocalContent] = useState("");
  const textColorLayer1 = useThemeRender("textColorLayer1");

  useEffect(() => {
    setLocalContent(setDescription);
  }, [setDescription]);


  const handleBlur = (e) => {
    changeFormatableText(localContent);
  };

  const updateLocalText = (e) => {
    setLocalContent(e);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: { sm: "100%", md: "360px" },
      }}
    >
      <TextArea
        className="curator-text-area-summary"
        label={label}
        value={localContent}
        showCharacterCount
        showLabelIcon
        onUpdate={(e) => updateLocalText(e)}
        onBlur={handleBlur}
        placeholder="Type here"
        showToolbar={showToolbar}
        style={{
          display: "flex",
          height: "122px",
          maxHeight: "122px",
          maxWidth: "460px",
          padding: "var(--Spacing-spacing-8, 8px);",
        }}
      />
    </Box>
  );
};

export default FormatableTextArea;
