import React from "react";
import InternalPageLayout from "../components/InternalPages/InternalPageLayout";
import Loading from "../utils/Loader";
import { useSelector, useDispatch } from "react-redux";

import { BASE_URL } from "../constant/apiUrl";

import useFetchInternal from "../hooks/useFetchInternal";
import PropTypes from "prop-types";
function SustainabilitySubpage({ urlName, header, action, globalState }) {
  const { favourite } = useSelector((store) => store.internalPage);
  const userId = 1;

  const { loading, pageData } = useFetchInternal(
    `${BASE_URL}/internal-pages/page-info/find-by-page-name?pageName=${urlName}&userId=${userId}`,
    action,
    globalState
  );
  if (loading) return <Loading />;
  return <InternalPageLayout pageData={pageData} header={header} />;
}

export default SustainabilitySubpage;

SustainabilitySubpage.propTypes = {
  urlName: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
};
