// src/features/counter/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  pesticides: [],
  regenerative: [],
  farmerIncentive: [],
  pivotAudit: [],
  ghgToolkit: [],
  cropDesignTools: [],
  lifeMetrice: [],
  climateResilient: [],
  farmingprogram: [],
  environmental: [],
  sustainable: [],
};
export const sustainabilityPageSlice = createSlice({
  name: "sustainable",
  initialState,
  reducers: {
    setPesticides: (state, action) => {
      state.pesticides = action.payload;
    },
    setRegenerative: (state, action) => {
      state.regenerative = action.payload;
    },
    setFarmerIncentive: (state, action) => {
      state.farmerIncentive = action.payload;
    },
    setPivot: (state, action) => {
      state.pivotAudit = action.payload;
    },
    setGghToolKit: (state, action) => {
      state.ghgToolkit = action.payload;
    },
    setCropDesignTools: (state, action) => {
      state.cropDesignTools = action.payload;
    },
    setLifeMetrice: (state, action) => {
      state.lifeMetrice = action.payload;
    },
    setClimateResilient: (state, action) => {
      state.climateResilient = action.payload;
    },
    setFarmingProgram: (state, action) => {
      state.farmingprogram = action.payload;
    },
    setEnvironmental: (state, action) => {
      state.environmental = action.payload;
    },
    setSustainability: (state, action) => {
      state.sustainable = action.payload;
    },
  },
});

export const {
  setPesticides,
  setRegenerative,
  setFarmerIncentive,
  setPivot,
  setGghToolKit,
  setCropDesignTools,
  setLifeMetrice,
  setClimateResilient,
  setFarmingProgram,
  setEnvironmental,
  setSustainability,
} = sustainabilityPageSlice.actions;

export default sustainabilityPageSlice.reducer;
